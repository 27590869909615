import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 戒指装备数据: 装备属性信息模型[] = [
  {
    id: 42729,
    uid: 42729,
    装备名称: '休倚戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.加速等级, 值: 8725 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41432,
    uid: 41432,
    装备名称: '客行江湖·春帆戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41431,
    uid: 41431,
    装备名称: '客行江湖·凶炽戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41374,
    uid: 41374,
    装备名称: '未解戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.外功基础攻击, 值: 2108 },
      { 属性: 属性类型.外功破防等级, 值: 7433 },
      { 属性: 属性类型.无双等级, 值: 3232 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41373,
    uid: 41373,
    装备名称: '昼眠戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.外功基础攻击, 值: 2108 },
      { 属性: 属性类型.外功会心等级, 值: 7433 },
      { 属性: 属性类型.无双等级, 值: 3232 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41372,
    uid: 41372,
    装备名称: '御朽戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.外功基础攻击, 值: 2108 },
      { 属性: 属性类型.外功破防等级, 值: 7433 },
      { 属性: 属性类型.破招值, 值: 3232 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41371,
    uid: 41371,
    装备名称: '休倚戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.外功基础攻击, 值: 2986 },
      { 属性: 属性类型.无双等级, 值: 7110 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41358,
    uid: 41358,
    装备名称: '寒江戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41357,
    uid: 41357,
    装备名称: '烟梦戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41346,
    uid: 41346,
    装备名称: '寒江戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41345,
    uid: 41345,
    装备名称: '烟梦戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41328,
    uid: 41328,
    装备名称: '迟夜指环',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41327,
    uid: 41327,
    装备名称: '旋山指环',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41310,
    uid: 41310,
    装备名称: '湘浮戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41309,
    uid: 41309,
    装备名称: '湘灿戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41426,
    uid: 41426,
    装备名称: '客行江湖·凋阳戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.外功破防等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41425,
    uid: 41425,
    装备名称: '客行江湖·近楼戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.外功破防等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41292,
    uid: 41292,
    装备名称: '云屿戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41291,
    uid: 41291,
    装备名称: '闲月戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41280,
    uid: 41280,
    装备名称: '云屿戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.外功会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41279,
    uid: 41279,
    装备名称: '闲月戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.外功会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41262,
    uid: 41262,
    装备名称: '宛江戒指',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.破招值, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41261,
    uid: 41261,
    装备名称: '玄卢戒指',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.破招值, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41244,
    uid: 41244,
    装备名称: '女君戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5765 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 1068 },
      { 属性: 属性类型.外功破防等级, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3144 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41243,
    uid: 41243,
    装备名称: '女义戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5765 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 1068 },
      { 属性: 属性类型.外功破防等级, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3144 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41178,
    uid: 41178,
    装备名称: '临仙戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.身法, 值: 464 },
      { 属性: 属性类型.外功基础攻击, 值: 1053 },
      { 属性: 属性类型.外功会心等级, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41177,
    uid: 41177,
    装备名称: '临尚戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.力道, 值: 464 },
      { 属性: 属性类型.外功基础攻击, 值: 1053 },
      { 属性: 属性类型.外功会心等级, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42635,
    uid: 42635,
    装备名称: '风岱戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42634,
    uid: 42634,
    装备名称: '项昌戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41232,
    uid: 41232,
    装备名称: '风岱戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41231,
    uid: 41231,
    装备名称: '项昌戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41214,
    uid: 41214,
    装备名称: '北邱戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41213,
    uid: 41213,
    装备名称: '曲郦戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41196,
    uid: 41196,
    装备名称: '渊忱戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.破招值, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41195,
    uid: 41195,
    装备名称: '羡双戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.破招值, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41106,
    uid: 41106,
    装备名称: '塞尘戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 834 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41105,
    uid: 41105,
    装备名称: '峭月戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 834 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41088,
    uid: 41088,
    装备名称: '奔枝戒',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 834 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41087,
    uid: 41087,
    装备名称: '环旌戒',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 834 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42441,
    uid: 42441,
    装备名称: '落琼霄·芥',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 19800,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6348 },
      { 属性: 属性类型.身法, 值: 704 },
      { 属性: 属性类型.外功基础攻击, 值: 1212 },
      { 属性: 属性类型.外功会心等级, 值: 4804 },
      { 属性: 属性类型.外功破防等级, 值: 5147 },
      { 属性: 属性类型.加速等级, 值: 1716 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42440,
    uid: 42440,
    装备名称: '落琼霄·舟',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 19800,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6348 },
      { 属性: 属性类型.力道, 值: 704 },
      { 属性: 属性类型.外功基础攻击, 值: 1212 },
      { 属性: 属性类型.外功会心等级, 值: 4804 },
      { 属性: 属性类型.外功破防等级, 值: 5147 },
      { 属性: 属性类型.加速等级, 值: 1716 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42436,
    uid: 42436,
    装备名称: '落琼霄·芥',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 17250,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5530 },
      { 属性: 属性类型.身法, 值: 613 },
      { 属性: 属性类型.外功基础攻击, 值: 1056 },
      { 属性: 属性类型.外功会心等级, 值: 4185 },
      { 属性: 属性类型.外功破防等级, 值: 4484 },
      { 属性: 属性类型.加速等级, 值: 1495 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42435,
    uid: 42435,
    装备名称: '落琼霄·舟',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 17250,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5530 },
      { 属性: 属性类型.力道, 值: 613 },
      { 属性: 属性类型.外功基础攻击, 值: 1056 },
      { 属性: 属性类型.外功会心等级, 值: 4185 },
      { 属性: 属性类型.外功破防等级, 值: 4484 },
      { 属性: 属性类型.加速等级, 值: 1495 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42431,
    uid: 42431,
    装备名称: '落琼霄·芥',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 15500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4969 },
      { 属性: 属性类型.身法, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 949 },
      { 属性: 属性类型.外功会心等级, 值: 3761 },
      { 属性: 属性类型.外功破防等级, 值: 4029 },
      { 属性: 属性类型.加速等级, 值: 1343 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42430,
    uid: 42430,
    装备名称: '落琼霄·舟',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 15500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4969 },
      { 属性: 属性类型.力道, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 949 },
      { 属性: 属性类型.外功会心等级, 值: 3761 },
      { 属性: 属性类型.外功破防等级, 值: 4029 },
      { 属性: 属性类型.加速等级, 值: 1343 },
    ],
    镶嵌孔数组: [],
  },
]

export default 戒指装备数据
