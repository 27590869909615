import { 奇穴列表数据类型 } from '@/@types/奇穴'

const 奇穴数据: 奇穴列表数据类型[] = [
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '川谷',
        奇穴图片: 'https://icon.jx3box.com/icon/15719.png',
        id: '27530',
        奇穴描述: '“商陆缀寒”伤害提高10%。',
      },
      {
        奇穴名称: '怯邪',
        奇穴图片: 'https://icon.jx3box.com/icon/15720.png',
        id: '28338',
        奇穴描述:
          '“川乌射罔”命中“逆乱”效果，对目标周围6尺范围额外3个目标造成毒性内功伤害，目标“逆乱”效果层数越高，溅射的伤害越高。',
      },
      {
        奇穴名称: '淮茵',
        奇穴图片: 'https://icon.jx3box.com/icon/15721.png',
        id: '28343',
        奇穴描述: '“逆乱”效果每层使得自身每跳回复0.1%内力值。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '鸩羽',
        奇穴图片: 'https://icon.jx3box.com/icon/15722.png',
        id: '28344',
        奇穴描述: '“川乌射罔”会心几率提高10%，变为2层透支招式。',
      },
      {
        奇穴名称: '月见',
        奇穴图片: 'https://icon.jx3box.com/icon/15723.png',
        id: '28349',
        奇穴描述:
          '“银光照雪”伤害和治疗效果提高20%，对自身6尺270度扇形范围最多5个友方目标生效，自身气血值每降低25%，伤害和治疗效果提高5%。',
      },
      {
        奇穴名称: '息缠',
        奇穴图片: 'https://icon.jx3box.com/icon/15724.png',
        id: '28356',
        奇穴描述:
          '“川乌射罔”调息时间提高8秒，第一跳、第二跳伤害提高15%且也为目标附带一层“逆乱”效果。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '往馥',
        奇穴图片: 'https://icon.jx3box.com/icon/15726.png',
        id: '28364',
        奇穴描述:
          '“川乌射罔”伤害提高10%，运功不会被打退，完整命中目标后使得其无法施展轻功和跳跃，持续2秒。',
      },
      {
        奇穴名称: '结草',
        奇穴图片: 'https://icon.jx3box.com/icon/15725.png',
        id: '28361',
        奇穴描述:
          '“苍棘缚地”长成后，自身每发生一次中和，使其持续时间增加2秒，并使其回复20%气血值，至多触发4次。自身施展“含锋破月”、“飞叶满襟”、“银光照雪”时，“苍棘缚地”也会在自身周围360°对应范围造成伤害，该次伤害不会与自身“含锋破月”、“飞叶满襟”、“银光照雪”命中同一目标。',
      },
      {
        奇穴名称: '岚因',
        奇穴图片: 'https://icon.jx3box.com/icon/15727.png',
        id: '28365',
        奇穴描述:
          '“沾衣未妨”伤害提高10%，命中人数提高2个，每跳使得目标有50%几率获得“逆乱”效果。若目标身上属于自身的“逆乱”层数低于4层则几率提高到100%。',
      },
      {
        奇穴名称: '令芷',
        奇穴图片: 'https://icon.jx3box.com/icon/15728.png',
        id: '28367',
        奇穴描述: '“沾衣未妨”命中附带自身的4层及以上“逆乱”效果的目标将立即造成定身效果。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '灵荆',
        奇穴图片: 'https://icon.jx3box.com/icon/15767.png',
        id: '29498',
        奇穴描述: '“苍棘缚地”受到的伤害降低70%，造成的伤害提高70%。',
      },
      {
        奇穴名称: '逐岚',
        奇穴图片: 'https://icon.jx3box.com/icon/15761.png',
        id: '37354',
        奇穴描述:
          '在自身“沾衣未妨”中施展“银光照雪”时会以扇形扩散一片毒雾，造成一次“沾衣未妨”伤害，使目标获得3层“逆乱”。',
      },
      {
        奇穴名称: '藏丛',
        奇穴图片: 'https://icon.jx3box.com/icon/15731.png',
        id: '28375',
        奇穴描述:
          '施展“且待时休”自身获得最大气血值10%的护盾，护盾存在期间自身免疫无法运功、缴械、打断效果，免疫击退、被拉效果，持续4秒。',
      },
      {
        奇穴名称: '断泽',
        奇穴图片: 'https://icon.jx3box.com/icon/15732.png',
        id: '28376',
        奇穴描述:
          '“惊鸿掠水”调息时间降低3秒，且不再占用基础调息时间，若命中运功目标，则打断目标运功，并回复自身15%内力值。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '划云',
        奇穴图片: 'https://icon.jx3box.com/icon/15766.png',
        id: '28485',
        奇穴描述:
          '“含锋破月”“飞叶满襟”选中目标才可以施展，施展距离提高至12尺并附带冲刺效果，释放期间免疫控制（击退和拉除外）。',
      },
      {
        奇穴名称: '苦苛',
        奇穴图片: 'https://icon.jx3box.com/icon/16024.png',
        id: '29499',
        奇穴描述: '“紫叶沉疴”调息时间降低10秒，附带1点温性，造成毒性内功伤害。',
      },
      {
        奇穴名称: '遍休',
        奇穴图片: 'https://icon.jx3box.com/icon/15739.png',
        id: '28406',
        奇穴描述:
          '施展“且待时休”，自身“苍棘缚地”也会在原地施展“且待时休”，对周围15尺的4层以上“逆乱”的目标造成沉默和伤害效果。自身停止运功时，“苍棘缚地”也会停止释放“且待时休”。',
      },
      {
        奇穴名称: '寒香',
        奇穴图片: 'https://icon.jx3box.com/icon/15736.png',
        id: '28400',
        奇穴描述: '“逐云寒蕊”气血值提高100%，随后该效果在10秒内逐渐衰减至0%。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '百叶',
        奇穴图片: 'https://icon.jx3box.com/icon/15751.png',
        id: '28427',
        奇穴描述:
          '“千枝伏藏”效果持续期间内力回复速度提高50%，若此时自身处于脱离战斗状态则再提高50%，并使得自身移动速度提高25%。',
      },
      {
        奇穴名称: '蔓蕊',
        奇穴图片: 'https://icon.jx3box.com/icon/15738.png',
        id: '28405',
        奇穴描述:
          '“千枝绽蕊”期间每累积受到相当于自身最大气血值20%的伤害，降低自身“绿野蔓生”“逐云寒蕊”1秒调息时间，该效果在切换至“千枝伏藏”后即清空计数。',
      },
      {
        奇穴名称: '渌波',
        奇穴图片: 'https://icon.jx3box.com/icon/17217.png',
        id: '30507',
        奇穴描述:
          '“紫叶沉疴”不占用基础调息时间，“凌波飞叶令”套路伤害招式命中目标时都会为其添加“紫叶沉疴”的种子，最多吸收3%的最大气血值的治疗量。',
      },
      {
        奇穴名称: '坚阴',
        奇穴图片: 'https://icon.jx3box.com/icon/15740.png',
        id: '28410',
        奇穴描述:
          '“商陆缀寒”命中属于自身“逆乱”层数低于4层的目标将为其添加一层“逆乱”效果。若当前自身状态无药性，则施展“商陆缀寒”额外回复2点寒性。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '相使',
        奇穴图片: 'https://icon.jx3box.com/icon/15742.png',
        id: '28413',
        奇穴描述: '“钩吻断肠”使得自身基础攻击力提高15%，期间自身“商陆缀寒”可以移动施展，持续16秒。',
      },
      {
        奇穴名称: '谢枝',
        奇穴图片: 'https://icon.jx3box.com/icon/15741.png',
        id: '28411',
        奇穴描述:
          '“逆乱”效果持续期间每层使得目标降低0.5%的御劲和1%的化劲等级，相同效果不可共存，仅作用于侠士目标。',
      },
      {
        奇穴名称: '茎蹊',
        奇穴图片: 'https://icon.jx3box.com/icon/16023.png',
        id: '29503',
        奇穴描述:
          '“含锋破月”、“飞叶满襟”造成伤害提高40%，命中“逆乱”效果的目标，每层再提高6%的伤害。',
      },
      {
        奇穴名称: '渐苞',
        奇穴图片: 'https://icon.jx3box.com/icon/15759.png',
        id: '28439',
        奇穴描述:
          '“紫叶沉疴”种子成长时，会将目标身上的“逆乱”一半的层数扩散给10尺范围的其他最多5个目标，并对目标及扩散波及的目标附带额外点毒性内功伤害。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '凄骨',
        奇穴图片: 'https://icon.jx3box.com/icon/15745.png',
        id: '28419',
        奇穴描述:
          '“川乌射罔”每跳降低1秒“银光照雪”调息时间，若完整运功使自身无视目标50%防御等级，持续16秒。',
      },
      {
        奇穴名称: '熟地',
        奇穴图片: 'https://icon.jx3box.com/icon/15746.png',
        id: '28420',
        奇穴描述: '“商陆缀寒”运功时间降低0.5秒，成功施展后使得自身基础调息时间降低0.5秒。',
      },
      {
        奇穴名称: '逐鸿',
        奇穴图片: 'https://icon.jx3box.com/icon/15729.png',
        id: '28370',
        奇穴描述:
          '施展“惊鸿掠水”自身免疫控制效果，持续3秒，并使得下一个“含锋破月”“银光照雪”“飞叶满襟”会心几率提高10%，会心效果提高10%。施展“惊鸿掠水”命中15尺以外目标时，免疫控制效果提高至4秒。',
      },
      {
        奇穴名称: '芳时',
        奇穴图片: 'https://icon.jx3box.com/icon/15748.png',
        id: '28423',
        奇穴描述: '“且待时休”调息时间降低8秒，期间使得自身“苍棘缚地”会心几率提高20%。',
      },
      {
        奇穴名称: '肃羽',
        奇穴图片: 'https://icon.jx3box.com/icon/18270.png',
        id: '32882',
        奇穴描述:
          '“惊鸿掠水”变为目标点范围技，若踩到自身“苍棘缚地”则使“苍棘缚地”再次蛰伏重新生长。“惊鸿掠水”非战斗状态下施展，调息时间减半。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '浸霜',
        奇穴图片: 'https://icon.jx3box.com/icon/17192.png',
        id: '30510',
        奇穴描述:
          '“回风微澜”使得自身6秒内招式施展距离提高20%，且下一个“商陆缀寒”造成伤害提高100%，并额外附带3点寒性。',
      },
      {
        奇穴名称: '疾根',
        奇穴图片: 'https://icon.jx3box.com/icon/15754.png',
        id: '28432',
        奇穴描述: '“逆乱”增加1跳，“无方中和”作用在自身4层“逆乱”的目标上则额外附带点毒性内功伤害。',
      },
      {
        奇穴名称: '双生',
        奇穴图片: 'https://icon.jx3box.com/icon/15752.png',
        id: '28428',
        奇穴描述: '“绿野蔓生”可以对友方目标施展，使其和自身共同获得“绿野蔓生”效果。',
      },
      {
        奇穴名称: '蓬茸',
        奇穴图片: 'https://icon.jx3box.com/icon/20093.png',
        id: '34796',
        奇穴描述:
          '“且待时休”运功期间每跳为目标添加“紫叶沉疴”的种子，最多吸收3%的最大气血值的治疗量。',
      },
      {
        奇穴名称: '燕徊',
        奇穴图片: 'https://icon.jx3box.com/icon/15706.png',
        id: '28731',
        奇穴描述: '“凌然天风”期间自身免疫无法运功、缴械、打断效果，每跳跃一次回复1%内力值。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '紫伏',
        奇穴图片: 'https://icon.jx3box.com/icon/15755.png',
        id: '28433',
        奇穴描述:
          '“紫叶沉疴”不再使目标获得治疗吸收盾，而是直接在目标点位置生成“紫叶花”，持续15秒，自身每次触发中和效果会使得“紫叶花朵”对目标8尺区域造成点毒性内功伤害。',
      },
      {
        奇穴名称: '避奚',
        奇穴图片: 'https://icon.jx3box.com/icon/15753.png',
        id: '28431',
        奇穴描述:
          '“凌然天风”重置自身“川乌射罔”调息时间，期间每施展一个“行忌制方”套路招式，自身加速率提高10%，最多提高至40%。',
      },
      {
        奇穴名称: '滞眠',
        奇穴图片: 'https://icon.jx3box.com/icon/15757.png',
        id: '28436',
        奇穴描述: '“且待时休”还会使作用目标无法施展轻功，运功每过1秒，“且待时休”伤害增加15%。',
      },
      {
        奇穴名称: '折枝拂露',
        奇穴图片: 'https://icon.jx3box.com/icon/17193.png',
        id: '30734',
        奇穴描述:
          '对周身8尺范围最多5个目标造成点毒性内功伤害和僵直效果，使目标获得一层“逆乱”效果，并使其下两跳立即生效，使“含锋破月”调息时间降低6秒。寒性状态下施展“折枝拂露”，在自身周围生成一个“沾衣未妨”，温性状态下施展“折枝拂露”，可以额外施展二段招式“折枝留春”，向后撤步并对周身8尺范围最多5个目标造成大量毒性内功伤害和僵直效果。',
      },
      {
        奇穴名称: '南草',
        奇穴图片: 'https://icon.jx3box.com/icon/15749.png',
        id: '30016',
        奇穴描述:
          '使得自身在空中向后飘荡一段距离，飘荡期间无法施展招式但每0.25秒回复1.5%最大气血值。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '甘遂',
        奇穴图片: 'https://icon.jx3box.com/icon/15760.png',
        id: '28443',
        奇穴描述: '施展“商陆缀寒”“惊鸿掠水”降低自身“紫叶沉疴”“苍棘缚地”1秒调息时间。',
      },
      {
        奇穴名称: '荆障',
        奇穴图片: 'https://icon.jx3box.com/icon/15744.png',
        id: '28415',
        奇穴描述:
          '“沾衣未妨”命中自身“苍棘缚地”后，使其攻击次数不变但攻击频率提高37.5%，造成伤害提高15%，期间每次“无方中和”会使“苍棘缚地”额外吐出一阵毒雾对周围8尺范围造成一次伤害和0.5秒的定身效果。',
      },
      {
        奇穴名称: '炮阳',
        奇穴图片: 'https://icon.jx3box.com/icon/15762.png',
        id: '28458',
        奇穴描述:
          '自身在寒性状态下触发“无方中和”效果，“无方中和”效果伤害提高35%，使得目标移动速度降低20%，持续5秒，最多叠加4层。并会为其添加“紫叶沉疴”的种子，最多吸收3%的最大气血值的治疗量。',
      },
      {
        奇穴名称: '菵露',
        奇穴图片: 'https://icon.jx3box.com/icon/19200.png',
        id: '34690',
        奇穴描述:
          '“沾衣未妨”第2、4跳时会生成5个“菵露”，敌对目标踩到花后花朵消失造成伤害，使目标获得3层逆乱，并使自身获得一点温性。',
      },
      {
        奇穴名称: '荡襟',
        奇穴图片: 'https://icon.jx3box.com/icon/15764.png',
        id: '28461',
        奇穴描述:
          '“飞叶满襟”命中8层“逆乱”效果的侠士目标，将使得目标获得“荡襟”效果，持续4秒。“荡襟”期间目标受到的治疗量将不会直接治疗目标，而是转化为目标每秒回复4%最大气血值的形式，至回复完相同总量的气血值为止，最多通过这个方式转换目标100%最大气血值的所受治疗量。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '应理与药',
        奇穴图片: 'https://icon.jx3box.com/icon/18267.png',
        id: '32896',
        奇穴描述:
          '被动效果：“千枝绽蕊”状态下生效五次破招伤害后，回复20%内力值，对目标造成额外伤害并且中和当前全部药性，该次中和造成的伤害提升320%。&lt;br/&gt;主动效果：施展后进入15秒“和宜”状态：3点及3点以上药性施展“银光照雪”，施展后使得当前全部药性转化为5点另一种药性，转化过程不发生中和。',
      },
      {
        奇穴名称: '养荣',
        奇穴图片: 'https://icon.jx3box.com/icon/15750.png',
        id: '28426',
        奇穴描述:
          '“千枝绽蕊”效果持续期间自身破防等级提高25%，无双等级提高30%，期间川乌射罔命中非侠士目标额外造成一次毒性内功伤害并回复5%最大内力值。',
      },
      {
        奇穴名称: '香繁饮露',
        奇穴图片: 'https://icon.jx3box.com/icon/19201.png',
        id: '36067',
        奇穴描述:
          '两层充能招式，充能时间32秒。施展后，使自身获得10秒“香繁饮露”效果：移动速度提高25%，并使下次施展“且待时休”运功时间和伤害次数减半，“沾衣未妨”持续2.25秒造成3跳伤害，“苍棘缚地”持续时间减半。以此法施展的招式，会获得原招式两倍的药性，并且施展后返还70%调息时间，并消耗“香繁饮露”效果。',
      },
      {
        奇穴名称: '青圃着尘',
        奇穴图片: 'https://icon.jx3box.com/icon/15768.png',
        id: '28533',
        奇穴描述:
          '被动效果：“凌波飞叶令”套路招式调息时间受到加速影响，每次施展获得2片“飞叶”效果。&lt;br/&gt;主动效果：拥有12片“飞叶”可以施展，引导“飞叶”对目标及其周围10尺范围最多4个目标进行持续攻击。每片“飞叶”对范围内随机一个目标造成毒性内功伤害，当“飞叶”命中10尺外的目标时造成伤害提高至。“飞叶”连续命中同一目标时其后续伤害效果会衰减50%。',
      },
      {
        奇穴名称: '并蒂夺株',
        奇穴图片: 'https://icon.jx3box.com/icon/16021.png',
        id: '28583',
        奇穴描述:
          '使得侠士目标获得不可卸除的“并蒂夺株·寒”，技能调息速度降低50%，持续15秒。同时在自身脚下生成一朵毒花，每秒对8尺区域内的敌对侠士目标添加“并蒂夺株·温”，使其造成伤害的30%的效果转化为对其自己的伤害。携带“并蒂夺株·寒”的目标获得“并蒂夺株·温”时两种毒会发生抵消，不受到“并蒂夺株·温”效果并使得“并蒂夺株·寒”持续时间降低4秒。',
      },
    ],
  },
]

export default 奇穴数据
