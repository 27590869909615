import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备类型枚举 } from '@/@types/装备'

const 项链装备数据: 装备属性信息模型[] = [
  {
    id: 41412,
    uid: 41412,
    装备名称: '乘烟链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 26600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7302 },
      { 属性: 属性类型.元气, 值: 596 },
      { 属性: 属性类型.内功基础攻击, 值: 1504 },
      { 属性: 属性类型.破招值, 值: 4978 },
      { 属性: 属性类型.无双等级, 值: 3983 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 41411,
    uid: 41411,
    装备名称: '燕浮链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 26600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7302 },
      { 属性: 属性类型.根骨, 值: 596 },
      { 属性: 属性类型.内功基础攻击, 值: 1504 },
      { 属性: 属性类型.破招值, 值: 4978 },
      { 属性: 属性类型.无双等级, 值: 3983 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 41350,
    uid: 41350,
    装备名称: '白萱项饰',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41349,
    uid: 41349,
    装备名称: '思菱项饰',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41332,
    uid: 41332,
    装备名称: '白萱项饰',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.全会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41331,
    uid: 41331,
    装备名称: '思菱项饰',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41314,
    uid: 41314,
    装备名称: '凝青链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41313,
    uid: 41313,
    装备名称: '晓菡链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41296,
    uid: 41296,
    装备名称: '湘崖链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.全会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41295,
    uid: 41295,
    装备名称: '湘沅链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41400,
    uid: 41400,
    装备名称: '乘烟链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 24600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.元气, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 41399,
    uid: 41399,
    装备名称: '燕浮链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 24600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.根骨, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 41388,
    uid: 41388,
    装备名称: '乘烟链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.元气, 值: 506 },
      { 属性: 属性类型.内功基础攻击, 值: 1278 },
      { 属性: 属性类型.破招值, 值: 4230 },
      { 属性: 属性类型.无双等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 41387,
    uid: 41387,
    装备名称: '燕浮链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.根骨, 值: 506 },
      { 属性: 属性类型.内功基础攻击, 值: 1278 },
      { 属性: 属性类型.破招值, 值: 4230 },
      { 属性: 属性类型.无双等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 41284,
    uid: 41284,
    装备名称: '啸影项饰',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.元气, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41283,
    uid: 41283,
    装备名称: '末闻项饰',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.根骨, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41266,
    uid: 41266,
    装备名称: '啸影项饰',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.元气, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.全会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41265,
    uid: 41265,
    装备名称: '末闻项饰',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.根骨, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.内功会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41248,
    uid: 41248,
    装备名称: '青霍链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.元气, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.内功破防等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41247,
    uid: 41247,
    装备名称: '持溯链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.根骨, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.内功破防等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41236,
    uid: 41236,
    装备名称: '女曜链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5765 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 1188 },
      { 属性: 属性类型.内功破防等级, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3144 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41235,
    uid: 41235,
    装备名称: '女希链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5765 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 1188 },
      { 属性: 属性类型.内功破防等级, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3144 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41164,
    uid: 41164,
    装备名称: '临溪链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.元气, 值: 464 },
      { 属性: 属性类型.内功基础攻击, 值: 1171 },
      { 属性: 属性类型.内功破防等级, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41163,
    uid: 41163,
    装备名称: '临黎链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.根骨, 值: 464 },
      { 属性: 属性类型.内功基础攻击, 值: 1171 },
      { 属性: 属性类型.内功破防等级, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41376,
    uid: 41376,
    装备名称: '乘烟链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5655 },
      { 属性: 属性类型.元气, 值: 461 },
      { 属性: 属性类型.内功基础攻击, 值: 1165 },
      { 属性: 属性类型.破招值, 值: 3855 },
      { 属性: 属性类型.无双等级, 值: 3084 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 41375,
    uid: 41375,
    装备名称: '燕浮链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5655 },
      { 属性: 属性类型.根骨, 值: 461 },
      { 属性: 属性类型.内功基础攻击, 值: 1165 },
      { 属性: 属性类型.破招值, 值: 3855 },
      { 属性: 属性类型.无双等级, 值: 3084 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 42621,
    uid: 42621,
    装备名称: '故芳链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.全会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 42620,
    uid: 42620,
    装备名称: '剪桐链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41218,
    uid: 41218,
    装备名称: '故芳链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.全会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41217,
    uid: 41217,
    装备名称: '剪桐链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 41200,
    uid: 41200,
    装备名称: '花霭链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41199,
    uid: 41199,
    装备名称: '途南链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41182,
    uid: 41182,
    装备名称: '庭澜链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41181,
    uid: 41181,
    装备名称: '故云链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41092,
    uid: 41092,
    装备名称: '若寓链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 927 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41091,
    uid: 41091,
    装备名称: '越风链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 927 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41074,
    uid: 41074,
    装备名称: '踏江链',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 927 },
      { 属性: 属性类型.全会心等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 41073,
    uid: 41073,
    装备名称: '承康链',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 927 },
      { 属性: 属性类型.内功会心等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
]

export default 项链装备数据
