import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 腰带装备数据: 装备属性信息模型[] = [
  {
    id: 101597,
    uid: 101597,
    装备名称: '暮盈腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 26600,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10222 },
      { 属性: 属性类型.元气, 值: 834 },
      { 属性: 属性类型.内功基础攻击, 值: 2106 },
      { 属性: 属性类型.全会心等级, 值: 6970 },
      { 属性: 属性类型.无双等级, 值: 5576 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101596,
    uid: 101596,
    装备名称: '暮漠腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 26600,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10222 },
      { 属性: 属性类型.根骨, 值: 834 },
      { 属性: 属性类型.内功基础攻击, 值: 2106 },
      { 属性: 属性类型.内功会心等级, 值: 6970 },
      { 属性: 属性类型.无双等级, 值: 5576 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104333,
    uid: 104333,
    装备名称: '平陵腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [],
    镶嵌孔数组: [],
  },
  {
    id: 101569,
    uid: 101569,
    装备名称: '平陵腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.内功基础攻击, 值: 3281 },
      { 属性: 属性类型.内功破防等级, 值: 4977 },
      { 属性: 属性类型.无双等级, 值: 9953 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101568,
    uid: 101568,
    装备名称: '六叶腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.内功基础攻击, 值: 3281 },
      { 属性: 属性类型.全会心等级, 值: 4977 },
      { 属性: 属性类型.无双等级, 值: 9953 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101567,
    uid: 101567,
    装备名称: '重桦腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.内功基础攻击, 值: 3965 },
      { 属性: 属性类型.内功破防等级, 值: 5429 },
      { 属性: 属性类型.破招值, 值: 3619 },
      { 属性: 属性类型.无双等级, 值: 3619 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101566,
    uid: 101566,
    装备名称: '锦字腰带',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.内功基础攻击, 值: 4648 },
      { 属性: 属性类型.无双等级, 值: 9953 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101531,
    uid: 101531,
    装备名称: '白萱腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.加速等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 101530,
    uid: 101530,
    装备名称: '思菱腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.加速等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 101495,
    uid: 101495,
    装备名称: '凝青带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101494,
    uid: 101494,
    装备名称: '晓菡带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101394,
    uid: 101394,
    装备名称: '揽江·玉玑腰带',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101390,
    uid: 101390,
    装备名称: '揽江·柳颦腰带',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101389,
    uid: 101389,
    装备名称: '揽江·玉玑腰带',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101384,
    uid: 101384,
    装备名称: '揽江·觅韵腰带',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101380,
    uid: 101380,
    装备名称: '揽江·挽沙腰带',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101376,
    uid: 101376,
    装备名称: '揽江·悠清腰带',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.外功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101374,
    uid: 101374,
    装备名称: '揽江·凝眸腰带',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101372,
    uid: 101372,
    装备名称: '揽江·霜枝腰带',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101370,
    uid: 101370,
    装备名称: '揽江·温羽腰带',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101366,
    uid: 101366,
    装备名称: '揽江·落华腰带',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101364,
    uid: 101364,
    装备名称: '揽江·忘语腰带',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101244,
    uid: 101244,
    装备名称: '湘崖腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101243,
    uid: 101243,
    装备名称: '湘沅腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 103493,
    uid: 103493,
    装备名称: '沉龙荡波·沧远腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 103492,
    uid: 103492,
    装备名称: '沉龙荡波·漠天腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101202,
    uid: 101202,
    装备名称: '啸影腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 101201,
    uid: 101201,
    装备名称: '末闻腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 101166,
    uid: 101166,
    装备名称: '青霍带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101165,
    uid: 101165,
    装备名称: '持溯带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101065,
    uid: 101065,
    装备名称: '展锋·晓河护腰',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101061,
    uid: 101061,
    装备名称: '展锋·待芳腰带',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101060,
    uid: 101060,
    装备名称: '展锋·晓河护腰',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101055,
    uid: 101055,
    装备名称: '展锋·松吟护腰',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101051,
    uid: 101051,
    装备名称: '展锋·夜云护腰',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101047,
    uid: 101047,
    装备名称: '展锋·寒泉护腰',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.外功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101045,
    uid: 101045,
    装备名称: '展锋·松寒腰带',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101043,
    uid: 101043,
    装备名称: '展锋·春断腰带',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101041,
    uid: 101041,
    装备名称: '展锋·圣化腰带',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101037,
    uid: 101037,
    装备名称: '展锋·看妆腰带',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101035,
    uid: 101035,
    装备名称: '展锋·玄解腰带',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100981,
    uid: 100981,
    装备名称: '寻踪觅宝·莫南腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 100980,
    uid: 100980,
    装备名称: '寻踪觅宝·君溪腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 100951,
    uid: 100951,
    装备名称: '寻踪觅宝·晚崖腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1663 },
      { 属性: 属性类型.加速等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 100950,
    uid: 100950,
    装备名称: '寻踪觅宝·沉济腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1663 },
      { 属性: 属性类型.加速等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 100789,
    uid: 100789,
    装备名称: '临初腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.元气, 值: 649 },
      { 属性: 属性类型.内功基础攻击, 值: 1639 },
      { 属性: 属性类型.内功破防等级, 值: 5424 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100788,
    uid: 100788,
    装备名称: '临罡腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.根骨, 值: 649 },
      { 属性: 属性类型.内功基础攻击, 值: 1639 },
      { 属性: 属性类型.内功破防等级, 值: 5424 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104118,
    uid: 104118,
    装备名称: '故芳腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 104117,
    uid: 104117,
    装备名称: '剪桐腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100885,
    uid: 100885,
    装备名称: '故芳腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.内功破防等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100884,
    uid: 100884,
    装备名称: '剪桐腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.内功破防等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100849,
    uid: 100849,
    装备名称: '花霭腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100848,
    uid: 100848,
    装备名称: '途南腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100813,
    uid: 100813,
    装备名称: '庭澜腰带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.全会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100812,
    uid: 100812,
    装备名称: '故云腰带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.内功会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100681,
    uid: 100681,
    装备名称: '若寓带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1298 },
      { 属性: 属性类型.内功破防等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100680,
    uid: 100680,
    装备名称: '越风带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1298 },
      { 属性: 属性类型.内功破防等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100645,
    uid: 100645,
    装备名称: '踏江带',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1298 },
      { 属性: 属性类型.全会心等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100644,
    uid: 100644,
    装备名称: '承康带',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1298 },
      { 属性: 属性类型.内功会心等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
]

export default 腰带装备数据
