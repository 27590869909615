import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 武器装备数据: 装备属性信息模型[] = [
  {
    id: 40320,
    uid: 40320,
    装备名称: '庄生晓梦',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 7108,
    武器伤害_最大值: 11847,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.身法, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 8214 },
      { 属性: 属性类型.外功会心等级, 值: 11895 },
      { 属性: 属性类型.外功破防等级, 值: 10980 },
      { 属性: 属性类型.加速等级, 值: 1830 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40319,
    uid: 40319,
    装备名称: '绝地天通刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 7108,
    武器伤害_最大值: 11847,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.力道, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 8089 },
      { 属性: 属性类型.外功会心等级, 值: 11895 },
      { 属性: 属性类型.外功破防等级, 值: 11438 },
      { 属性: 属性类型.加速等级, 值: 1830 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40315,
    uid: 40315,
    装备名称: '山河同渡',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 7108,
    武器伤害_最大值: 11847,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.身法, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 8214 },
      { 属性: 属性类型.外功会心等级, 值: 11895 },
      { 属性: 属性类型.外功破防等级, 值: 10980 },
      { 属性: 属性类型.加速等级, 值: 1830 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40314,
    uid: 40314,
    装备名称: '山海云涯',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 7108,
    武器伤害_最大值: 11847,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.身法, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 7965 },
      { 属性: 属性类型.外功会心等级, 值: 12353 },
      { 属性: 属性类型.外功破防等级, 值: 11438 },
      { 属性: 属性类型.加速等级, 值: 1830 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40313,
    uid: 40313,
    装备名称: '沉夜重雪',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 7108,
    武器伤害_最大值: 11847,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.力道, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 7965 },
      { 属性: 属性类型.外功会心等级, 值: 11438 },
      { 属性: 属性类型.外功破防等级, 值: 12353 },
      { 属性: 属性类型.加速等级, 值: 1830 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40309,
    uid: 40309,
    装备名称: '孤焰',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 7108,
    武器伤害_最大值: 11847,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.身法, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 8711 },
      { 属性: 属性类型.外功会心等级, 值: 10980 },
      { 属性: 属性类型.外功破防等级, 值: 10065 },
      { 属性: 属性类型.加速等级, 值: 1830 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40306,
    uid: 40306,
    装备名称: '拾牙',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 8056,
    武器伤害_最大值: 13426,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.力道, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 8214 },
      { 属性: 属性类型.外功会心等级, 值: 11895 },
      { 属性: 属性类型.外功破防等级, 值: 12810 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40304,
    uid: 40304,
    装备名称: '寄骨',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 6160,
    武器伤害_最大值: 10267,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.身法, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 7965 },
      { 属性: 属性类型.外功会心等级, 值: 13268 },
      { 属性: 属性类型.外功破防等级, 值: 10523 },
      { 属性: 属性类型.加速等级, 值: 1830 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40303,
    uid: 40303,
    装备名称: '摧霜',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 4739,
    武器伤害_最大值: 7898,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.力道, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 7716 },
      { 属性: 属性类型.外功会心等级, 值: 11895 },
      { 属性: 属性类型.外功破防等级, 值: 12810 },
      { 属性: 属性类型.加速等级, 值: 1830 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40297,
    uid: 40297,
    装备名称: '风霆肃',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 6160,
    武器伤害_最大值: 10267,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.身法, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 7965 },
      { 属性: 属性类型.外功会心等级, 值: 12810 },
      { 属性: 属性类型.外功破防等级, 值: 10980 },
      { 属性: 属性类型.加速等级, 值: 1830 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40294,
    uid: 40294,
    装备名称: '旷野孤疆',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 8056,
    武器伤害_最大值: 13426,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.力道, 值: 1642 },
      { 属性: 属性类型.外功基础攻击, 值: 7965 },
      { 属性: 属性类型.外功会心等级, 值: 12353 },
      { 属性: 属性类型.外功破防等级, 值: 11438 },
      { 属性: 属性类型.加速等级, 值: 1830 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40288,
    uid: 40288,
    装备名称: '庄生晓梦',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 6704,
    武器伤害_最大值: 11173,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.身法, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7747 },
      { 属性: 属性类型.外功会心等级, 值: 11219 },
      { 属性: 属性类型.外功破防等级, 值: 10356 },
      { 属性: 属性类型.加速等级, 值: 1726 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40287,
    uid: 40287,
    装备名称: '绝地天通刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 6704,
    武器伤害_最大值: 11173,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.力道, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7630 },
      { 属性: 属性类型.外功会心等级, 值: 11219 },
      { 属性: 属性类型.外功破防等级, 值: 10788 },
      { 属性: 属性类型.加速等级, 值: 1726 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40283,
    uid: 40283,
    装备名称: '山河同渡',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 6704,
    武器伤害_最大值: 11173,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.身法, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7747 },
      { 属性: 属性类型.外功会心等级, 值: 11219 },
      { 属性: 属性类型.外功破防等级, 值: 10356 },
      { 属性: 属性类型.加速等级, 值: 1726 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40282,
    uid: 40282,
    装备名称: '山海云涯',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 6704,
    武器伤害_最大值: 11173,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.身法, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7513 },
      { 属性: 属性类型.外功会心等级, 值: 11651 },
      { 属性: 属性类型.外功破防等级, 值: 10788 },
      { 属性: 属性类型.加速等级, 值: 1726 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40281,
    uid: 40281,
    装备名称: '沉夜重雪',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 6704,
    武器伤害_最大值: 11173,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.力道, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7513 },
      { 属性: 属性类型.外功会心等级, 值: 10788 },
      { 属性: 属性类型.外功破防等级, 值: 11651 },
      { 属性: 属性类型.加速等级, 值: 1726 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40277,
    uid: 40277,
    装备名称: '孤焰',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 6704,
    武器伤害_最大值: 11173,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.身法, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 8216 },
      { 属性: 属性类型.外功会心等级, 值: 10356 },
      { 属性: 属性类型.外功破防等级, 值: 9493 },
      { 属性: 属性类型.加速等级, 值: 1726 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40274,
    uid: 40274,
    装备名称: '拾牙',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 7598,
    武器伤害_最大值: 12663,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.力道, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7747 },
      { 属性: 属性类型.外功会心等级, 值: 11219 },
      { 属性: 属性类型.外功破防等级, 值: 12082 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40272,
    uid: 40272,
    装备名称: '寄骨',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 5810,
    武器伤害_最大值: 9683,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.身法, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7513 },
      { 属性: 属性类型.外功会心等级, 值: 12514 },
      { 属性: 属性类型.外功破防等级, 值: 9925 },
      { 属性: 属性类型.加速等级, 值: 1726 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40271,
    uid: 40271,
    装备名称: '摧霜',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 4469,
    武器伤害_最大值: 7449,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.力道, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7278 },
      { 属性: 属性类型.外功会心等级, 值: 11219 },
      { 属性: 属性类型.外功破防等级, 值: 12082 },
      { 属性: 属性类型.加速等级, 值: 1726 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40265,
    uid: 40265,
    装备名称: '风霆肃',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 5810,
    武器伤害_最大值: 9683,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.身法, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7513 },
      { 属性: 属性类型.外功会心等级, 值: 12082 },
      { 属性: 属性类型.外功破防等级, 值: 10356 },
      { 属性: 属性类型.加速等级, 值: 1726 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40262,
    uid: 40262,
    装备名称: '旷野孤疆',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 7598,
    武器伤害_最大值: 12663,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.力道, 值: 1549 },
      { 属性: 属性类型.外功基础攻击, 值: 7513 },
      { 属性: 属性类型.外功会心等级, 值: 11651 },
      { 属性: 属性类型.外功破防等级, 值: 10788 },
      { 属性: 属性类型.加速等级, 值: 1726 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40256,
    uid: 40256,
    装备名称: '庄生晓梦',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 6300,
    武器伤害_最大值: 10500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.身法, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7280 },
      { 属性: 属性类型.外功会心等级, 值: 10543 },
      { 属性: 属性类型.外功破防等级, 值: 9732 },
      { 属性: 属性类型.加速等级, 值: 1622 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40255,
    uid: 40255,
    装备名称: '绝地天通刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 6300,
    武器伤害_最大值: 10500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.力道, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7170 },
      { 属性: 属性类型.外功会心等级, 值: 10543 },
      { 属性: 属性类型.外功破防等级, 值: 10138 },
      { 属性: 属性类型.加速等级, 值: 1622 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40251,
    uid: 40251,
    装备名称: '山河同渡',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 6300,
    武器伤害_最大值: 10500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.身法, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7280 },
      { 属性: 属性类型.外功会心等级, 值: 10543 },
      { 属性: 属性类型.外功破防等级, 值: 9732 },
      { 属性: 属性类型.加速等级, 值: 1622 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40250,
    uid: 40250,
    装备名称: '山海云涯',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 6300,
    武器伤害_最大值: 10500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.身法, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7060 },
      { 属性: 属性类型.外功会心等级, 值: 10949 },
      { 属性: 属性类型.外功破防等级, 值: 10138 },
      { 属性: 属性类型.加速等级, 值: 1622 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40249,
    uid: 40249,
    装备名称: '沉夜重雪',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 6300,
    武器伤害_最大值: 10500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.力道, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7060 },
      { 属性: 属性类型.外功会心等级, 值: 10138 },
      { 属性: 属性类型.外功破防等级, 值: 10949 },
      { 属性: 属性类型.加速等级, 值: 1622 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40245,
    uid: 40245,
    装备名称: '孤焰',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 6300,
    武器伤害_最大值: 10500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.身法, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7721 },
      { 属性: 属性类型.外功会心等级, 值: 9732 },
      { 属性: 属性类型.外功破防等级, 值: 8921 },
      { 属性: 属性类型.加速等级, 值: 1622 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40242,
    uid: 40242,
    装备名称: '拾牙',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 7140,
    武器伤害_最大值: 11900,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.力道, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7280 },
      { 属性: 属性类型.外功会心等级, 值: 10543 },
      { 属性: 属性类型.外功破防等级, 值: 11354 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40240,
    uid: 40240,
    装备名称: '寄骨',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 5460,
    武器伤害_最大值: 9100,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.身法, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7060 },
      { 属性: 属性类型.外功会心等级, 值: 11760 },
      { 属性: 属性类型.外功破防等级, 值: 9327 },
      { 属性: 属性类型.加速等级, 值: 1622 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40239,
    uid: 40239,
    装备名称: '摧霜',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 4200,
    武器伤害_最大值: 7000,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.力道, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 6840 },
      { 属性: 属性类型.外功会心等级, 值: 10543 },
      { 属性: 属性类型.外功破防等级, 值: 11354 },
      { 属性: 属性类型.加速等级, 值: 1622 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40233,
    uid: 40233,
    装备名称: '风霆肃',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 5460,
    武器伤害_最大值: 9100,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.身法, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7060 },
      { 属性: 属性类型.外功会心等级, 值: 11354 },
      { 属性: 属性类型.外功破防等级, 值: 9732 },
      { 属性: 属性类型.加速等级, 值: 1622 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40230,
    uid: 40230,
    装备名称: '旷野孤疆',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 7140,
    武器伤害_最大值: 11900,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.力道, 值: 1456 },
      { 属性: 属性类型.外功基础攻击, 值: 7060 },
      { 属性: 属性类型.外功会心等级, 值: 10949 },
      { 属性: 属性类型.外功破防等级, 值: 10138 },
      { 属性: 属性类型.加速等级, 值: 1622 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40224,
    uid: 40224,
    装备名称: '庄生晓梦',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 5912,
    武器伤害_最大值: 9854,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.身法, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6832 },
      { 属性: 属性类型.外功会心等级, 值: 9894 },
      { 属性: 属性类型.外功破防等级, 值: 9133 },
      { 属性: 属性类型.加速等级, 值: 1522 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40223,
    uid: 40223,
    装备名称: '绝地天通刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 5912,
    武器伤害_最大值: 9854,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.力道, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6729 },
      { 属性: 属性类型.外功会心等级, 值: 9894 },
      { 属性: 属性类型.外功破防等级, 值: 9514 },
      { 属性: 属性类型.加速等级, 值: 1522 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40219,
    uid: 40219,
    装备名称: '山河同渡',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 5912,
    武器伤害_最大值: 9854,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.身法, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6832 },
      { 属性: 属性类型.外功会心等级, 值: 9894 },
      { 属性: 属性类型.外功破防等级, 值: 9133 },
      { 属性: 属性类型.加速等级, 值: 1522 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40218,
    uid: 40218,
    装备名称: '山海云涯',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 5912,
    武器伤害_最大值: 9854,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.身法, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6626 },
      { 属性: 属性类型.外功会心等级, 值: 10275 },
      { 属性: 属性类型.外功破防等级, 值: 9514 },
      { 属性: 属性类型.加速等级, 值: 1522 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40217,
    uid: 40217,
    装备名称: '沉夜重雪',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 5912,
    武器伤害_最大值: 9854,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.力道, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6626 },
      { 属性: 属性类型.外功会心等级, 值: 9514 },
      { 属性: 属性类型.外功破防等级, 值: 10275 },
      { 属性: 属性类型.加速等级, 值: 1522 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40213,
    uid: 40213,
    装备名称: '孤焰',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 5912,
    武器伤害_最大值: 9854,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.身法, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 7246 },
      { 属性: 属性类型.外功会心等级, 值: 9133 },
      { 属性: 属性类型.外功破防等级, 值: 8372 },
      { 属性: 属性类型.加速等级, 值: 1522 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40210,
    uid: 40210,
    装备名称: '拾牙',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 6701,
    武器伤害_最大值: 11168,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.力道, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6832 },
      { 属性: 属性类型.外功会心等级, 值: 9894 },
      { 属性: 属性类型.外功破防等级, 值: 10656 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40208,
    uid: 40208,
    装备名称: '寄骨',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 5124,
    武器伤害_最大值: 8540,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.身法, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6626 },
      { 属性: 属性类型.外功会心等级, 值: 11036 },
      { 属性: 属性类型.外功破防等级, 值: 8753 },
      { 属性: 属性类型.加速等级, 值: 1522 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40207,
    uid: 40207,
    装备名称: '摧霜',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 3942,
    武器伤害_最大值: 6570,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.力道, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6419 },
      { 属性: 属性类型.外功会心等级, 值: 9894 },
      { 属性: 属性类型.外功破防等级, 值: 10656 },
      { 属性: 属性类型.加速等级, 值: 1522 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40201,
    uid: 40201,
    装备名称: '风霆肃',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 5124,
    武器伤害_最大值: 8540,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.身法, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6626 },
      { 属性: 属性类型.外功会心等级, 值: 10656 },
      { 属性: 属性类型.外功破防等级, 值: 9133 },
      { 属性: 属性类型.加速等级, 值: 1522 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40198,
    uid: 40198,
    装备名称: '旷野孤疆',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 6701,
    武器伤害_最大值: 11168,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.力道, 值: 1366 },
      { 属性: 属性类型.外功基础攻击, 值: 6626 },
      { 属性: 属性类型.外功会心等级, 值: 10275 },
      { 属性: 属性类型.外功破防等级, 值: 9514 },
      { 属性: 属性类型.加速等级, 值: 1522 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40192,
    uid: 40192,
    装备名称: '庄生晓梦',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 5525,
    武器伤害_最大值: 9208,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.身法, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6384 },
      { 属性: 属性类型.外功会心等级, 值: 9246 },
      { 属性: 属性类型.外功破防等级, 值: 8534 },
      { 属性: 属性类型.加速等级, 值: 1422 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40191,
    uid: 40191,
    装备名称: '绝地天通刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 5525,
    武器伤害_最大值: 9208,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.力道, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6288 },
      { 属性: 属性类型.外功会心等级, 值: 9246 },
      { 属性: 属性类型.外功破防等级, 值: 8890 },
      { 属性: 属性类型.加速等级, 值: 1422 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40187,
    uid: 40187,
    装备名称: '山河同渡',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 5525,
    武器伤害_最大值: 9208,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.身法, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6384 },
      { 属性: 属性类型.外功会心等级, 值: 9246 },
      { 属性: 属性类型.外功破防等级, 值: 8534 },
      { 属性: 属性类型.加速等级, 值: 1422 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40186,
    uid: 40186,
    装备名称: '山海云涯',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 5525,
    武器伤害_最大值: 9208,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.身法, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6191 },
      { 属性: 属性类型.外功会心等级, 值: 9601 },
      { 属性: 属性类型.外功破防等级, 值: 8890 },
      { 属性: 属性类型.加速等级, 值: 1422 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40185,
    uid: 40185,
    装备名称: '沉夜重雪',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 5525,
    武器伤害_最大值: 9208,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.力道, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6191 },
      { 属性: 属性类型.外功会心等级, 值: 8890 },
      { 属性: 属性类型.外功破防等级, 值: 9601 },
      { 属性: 属性类型.加速等级, 值: 1422 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40181,
    uid: 40181,
    装备名称: '孤焰',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 5525,
    武器伤害_最大值: 9208,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.身法, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6771 },
      { 属性: 属性类型.外功会心等级, 值: 8534 },
      { 属性: 属性类型.外功破防等级, 值: 7823 },
      { 属性: 属性类型.加速等级, 值: 1422 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40178,
    uid: 40178,
    装备名称: '拾牙',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 6261,
    武器伤害_最大值: 10435,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.力道, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6384 },
      { 属性: 属性类型.外功会心等级, 值: 9246 },
      { 属性: 属性类型.外功破防等级, 值: 9957 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40176,
    uid: 40176,
    装备名称: '寄骨',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 4788,
    武器伤害_最大值: 7980,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.身法, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6191 },
      { 属性: 属性类型.外功会心等级, 值: 10312 },
      { 属性: 属性类型.外功破防等级, 值: 8179 },
      { 属性: 属性类型.加速等级, 值: 1422 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40175,
    uid: 40175,
    装备名称: '摧霜',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 3683,
    武器伤害_最大值: 6138,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.力道, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 5998 },
      { 属性: 属性类型.外功会心等级, 值: 9246 },
      { 属性: 属性类型.外功破防等级, 值: 9957 },
      { 属性: 属性类型.加速等级, 值: 1422 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40169,
    uid: 40169,
    装备名称: '风霆肃',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 4788,
    武器伤害_最大值: 7980,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.身法, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6191 },
      { 属性: 属性类型.外功会心等级, 值: 9957 },
      { 属性: 属性类型.外功破防等级, 值: 8534 },
      { 属性: 属性类型.加速等级, 值: 1422 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40166,
    uid: 40166,
    装备名称: '旷野孤疆',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 6261,
    武器伤害_最大值: 10435,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.力道, 值: 1277 },
      { 属性: 属性类型.外功基础攻击, 值: 6191 },
      { 属性: 属性类型.外功会心等级, 值: 9601 },
      { 属性: 属性类型.外功破防等级, 值: 8890 },
      { 属性: 属性类型.加速等级, 值: 1422 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38587,
    uid: 38587,
    装备名称: '煞·庄生晓梦',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.身法, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5633 },
      { 属性: 属性类型.外功会心等级, 值: 5880 },
      { 属性: 属性类型.外功破防等级, 值: 5880 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38586,
    uid: 38586,
    装备名称: '煞·绝地天通刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.力道, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5543 },
      { 属性: 属性类型.外功会心等级, 值: 5880 },
      { 属性: 属性类型.外功破防等级, 值: 6083 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38582,
    uid: 38582,
    装备名称: '煞·山河同渡',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.身法, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5633 },
      { 属性: 属性类型.外功会心等级, 值: 5880 },
      { 属性: 属性类型.外功破防等级, 值: 5880 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38581,
    uid: 38581,
    装备名称: '煞·山海云涯',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.身法, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5452 },
      { 属性: 属性类型.外功会心等级, 值: 6083 },
      { 属性: 属性类型.外功破防等级, 值: 6083 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38580,
    uid: 38580,
    装备名称: '煞·沉夜重雪',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.力道, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5452 },
      { 属性: 属性类型.外功会心等级, 值: 5677 },
      { 属性: 属性类型.外功破防等级, 值: 6488 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38576,
    uid: 38576,
    装备名称: '煞·孤焰',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.身法, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5815 },
      { 属性: 属性类型.外功会心等级, 值: 5474 },
      { 属性: 属性类型.外功破防等级, 值: 5880 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38573,
    uid: 38573,
    装备名称: '煞·拾牙',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4576,
    武器伤害_最大值: 7627,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.力道, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5633 },
      { 属性: 属性类型.外功会心等级, 值: 6488 },
      { 属性: 属性类型.外功破防等级, 值: 7299 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38571,
    uid: 38571,
    装备名称: '煞·寄骨',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 3500,
    武器伤害_最大值: 5833,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.身法, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5452 },
      { 属性: 属性类型.外功会心等级, 值: 6488 },
      { 属性: 属性类型.外功破防等级, 值: 5677 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38570,
    uid: 38570,
    装备名称: '煞·摧霜',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.力道, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5270 },
      { 属性: 属性类型.外功会心等级, 值: 5880 },
      { 属性: 属性类型.外功破防等级, 值: 6691 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38564,
    uid: 38564,
    装备名称: '煞·风霆肃',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 3500,
    武器伤害_最大值: 5833,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.身法, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5361 },
      { 属性: 属性类型.外功会心等级, 值: 6285 },
      { 属性: 属性类型.外功破防等级, 值: 6083 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38561,
    uid: 38561,
    装备名称: '煞·旷野孤疆',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4576,
    武器伤害_最大值: 7627,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.力道, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5452 },
      { 属性: 属性类型.外功会心等级, 值: 5880 },
      { 属性: 属性类型.外功破防等级, 值: 6285 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38077,
    uid: 38077,
    装备名称: '庄生晓梦',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.身法, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5633 },
      { 属性: 属性类型.外功会心等级, 值: 5880 },
      { 属性: 属性类型.外功破防等级, 值: 5880 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38076,
    uid: 38076,
    装备名称: '绝地天通刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.力道, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5543 },
      { 属性: 属性类型.外功会心等级, 值: 5880 },
      { 属性: 属性类型.外功破防等级, 值: 6083 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38072,
    uid: 38072,
    装备名称: '山河同渡',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.身法, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5633 },
      { 属性: 属性类型.外功会心等级, 值: 5880 },
      { 属性: 属性类型.外功破防等级, 值: 5880 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38071,
    uid: 38071,
    装备名称: '山海云涯',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.身法, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5452 },
      { 属性: 属性类型.外功会心等级, 值: 6083 },
      { 属性: 属性类型.外功破防等级, 值: 6083 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38070,
    uid: 38070,
    装备名称: '沉夜重雪',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.力道, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5452 },
      { 属性: 属性类型.外功会心等级, 值: 5677 },
      { 属性: 属性类型.外功破防等级, 值: 6488 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38066,
    uid: 38066,
    装备名称: '孤焰',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4038,
    武器伤害_最大值: 6730,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.身法, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5815 },
      { 属性: 属性类型.外功会心等级, 值: 5474 },
      { 属性: 属性类型.外功破防等级, 值: 5880 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38063,
    uid: 38063,
    装备名称: '拾牙',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4576,
    武器伤害_最大值: 7627,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.力道, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5633 },
      { 属性: 属性类型.外功会心等级, 值: 6488 },
      { 属性: 属性类型.外功破防等级, 值: 7299 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38061,
    uid: 38061,
    装备名称: '寄骨',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 3500,
    武器伤害_最大值: 5833,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.身法, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5452 },
      { 属性: 属性类型.外功会心等级, 值: 6488 },
      { 属性: 属性类型.外功破防等级, 值: 5677 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38060,
    uid: 38060,
    装备名称: '摧霜',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.力道, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5270 },
      { 属性: 属性类型.外功会心等级, 值: 5880 },
      { 属性: 属性类型.外功破防等级, 值: 6691 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38054,
    uid: 38054,
    装备名称: '风霆肃',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 3500,
    武器伤害_最大值: 5833,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.身法, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5361 },
      { 属性: 属性类型.外功会心等级, 值: 6285 },
      { 属性: 属性类型.外功破防等级, 值: 6083 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38051,
    uid: 38051,
    装备名称: '旷野孤疆',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4576,
    武器伤害_最大值: 7627,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.力道, 值: 1455 },
      { 属性: 属性类型.外功基础攻击, 值: 5452 },
      { 属性: 属性类型.外功会心等级, 值: 5880 },
      { 属性: 属性类型.外功破防等级, 值: 6285 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39394,
    uid: 39394,
    装备名称: '落星辉',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 6188,
    武器伤害_最大值: 10313,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.身法, 值: 1430 },
      { 属性: 属性类型.外功基础攻击, 值: 7150 },
      { 属性: 属性类型.外功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39393,
    uid: 39393,
    装备名称: '万里飞鸿',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 6188,
    武器伤害_最大值: 10313,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.力道, 值: 1430 },
      { 属性: 属性类型.外功基础攻击, 值: 7150 },
      { 属性: 属性类型.外功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39389,
    uid: 39389,
    装备名称: '作别',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 6188,
    武器伤害_最大值: 10313,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.身法, 值: 1430 },
      { 属性: 属性类型.外功基础攻击, 值: 7150 },
      { 属性: 属性类型.外功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39388,
    uid: 39388,
    装备名称: '帆红',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 6188,
    武器伤害_最大值: 10313,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.身法, 值: 1430 },
      { 属性: 属性类型.外功基础攻击, 值: 7150 },
      { 属性: 属性类型.外功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39387,
    uid: 39387,
    装备名称: '苍烟',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 6188,
    武器伤害_最大值: 10313,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.力道, 值: 1430 },
      { 属性: 属性类型.外功基础攻击, 值: 7150 },
      { 属性: 属性类型.外功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39383,
    uid: 39383,
    装备名称: '不计',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 6188,
    武器伤害_最大值: 10313,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.身法, 值: 1430 },
      { 属性: 属性类型.外功基础攻击, 值: 7150 },
      { 属性: 属性类型.外功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39380,
    uid: 39380,
    装备名称: '醉梦',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 7013,
    武器伤害_最大值: 11688,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.力道, 值: 1430 },
      { 属性: 属性类型.外功基础攻击, 值: 7150 },
      { 属性: 属性类型.外功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39378,
    uid: 39378,
    装备名称: '秋曦浮',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 5363,
    武器伤害_最大值: 8938,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.身法, 值: 1430 },
      { 属性: 属性类型.外功基础攻击, 值: 7150 },
      { 属性: 属性类型.外功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39377,
    uid: 39377,
    装备名称: '万壑千峰',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 4125,
    武器伤害_最大值: 6875,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.力道, 值: 1430 },
      { 属性: 属性类型.外功基础攻击, 值: 7150 },
      { 属性: 属性类型.外功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39371,
    uid: 39371,
    装备名称: '孤云伴',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 5363,
    武器伤害_最大值: 8938,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.身法, 值: 1430 },
      { 属性: 属性类型.外功基础攻击, 值: 7150 },
      { 属性: 属性类型.外功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39368,
    uid: 39368,
    装备名称: '争衡',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 7013,
    武器伤害_最大值: 11688,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.力道, 值: 1430 },
      { 属性: 属性类型.外功基础攻击, 值: 7150 },
      { 属性: 属性类型.外功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39356,
    uid: 39356,
    装备名称: '飞仙引',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备特效: 装备特效枚举.外功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39355,
    uid: 39355,
    装备名称: '风波还',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备特效: 装备特效枚举.外功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39351,
    uid: 39351,
    装备名称: '落苍茫',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备特效: 装备特效枚举.外功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39350,
    uid: 39350,
    装备名称: '尘中罗',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备特效: 装备特效枚举.外功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39349,
    uid: 39349,
    装备名称: '临沧怒',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备特效: 装备特效枚举.外功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39345,
    uid: 39345,
    装备名称: '烽烟黯',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备特效: 装备特效枚举.外功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39342,
    uid: 39342,
    装备名称: '啸天涯',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备特效: 装备特效枚举.外功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39340,
    uid: 39340,
    装备名称: '逐霞轻剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备特效: 装备特效枚举.外功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39339,
    uid: 39339,
    装备名称: '遗玄思',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备特效: 装备特效枚举.外功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39333,
    uid: 39333,
    装备名称: '风乘唳',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备特效: 装备特效枚举.外功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39330,
    uid: 39330,
    装备名称: '照故陲',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备特效: 装备特效枚举.外功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39324,
    uid: 39324,
    装备名称: '霜满楼',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39322,
    uid: 39322,
    装备名称: '万虑休',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39321,
    uid: 39321,
    装备名称: '照刃新',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39317,
    uid: 39317,
    装备名称: '归路遥',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39316,
    uid: 39316,
    装备名称: '列归潮',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39315,
    uid: 39315,
    装备名称: '江霆风',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39311,
    uid: 39311,
    装备名称: '边月近',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39308,
    uid: 39308,
    装备名称: '樽流霞',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39306,
    uid: 39306,
    装备名称: '霜满楼',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39305,
    uid: 39305,
    装备名称: '希乘槎',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39299,
    uid: 39299,
    装备名称: '缀北辰',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39296,
    uid: 39296,
    装备名称: '度旌节',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39284,
    uid: 39284,
    装备名称: '霜满楼',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39282,
    uid: 39282,
    装备名称: '万虑休',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39281,
    uid: 39281,
    装备名称: '照刃新',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39277,
    uid: 39277,
    装备名称: '归路遥',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39276,
    uid: 39276,
    装备名称: '列归潮',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39275,
    uid: 39275,
    装备名称: '江霆风',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39271,
    uid: 39271,
    装备名称: '边月近',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39268,
    uid: 39268,
    装备名称: '樽流霞',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39266,
    uid: 39266,
    装备名称: '霜满楼',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39265,
    uid: 39265,
    装备名称: '希乘槎',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39259,
    uid: 39259,
    装备名称: '缀北辰',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39256,
    uid: 39256,
    装备名称: '度旌节',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39178,
    uid: 39178,
    装备名称: '镞上尘',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39177,
    uid: 39177,
    装备名称: '夕云',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39173,
    uid: 39173,
    装备名称: '百战功',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39172,
    uid: 39172,
    装备名称: '险明涛',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39171,
    uid: 39171,
    装备名称: '月泓',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39167,
    uid: 39167,
    装备名称: '峰虹',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6025,
    武器伤害_最大值: 10042,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39164,
    uid: 39164,
    装备名称: '花摧',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39162,
    uid: 39162,
    装备名称: '楚江晖',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39161,
    uid: 39161,
    装备名称: '翠清',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39155,
    uid: 39155,
    装备名称: '云晨',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 5222,
    武器伤害_最大值: 8703,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.身法, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39152,
    uid: 39152,
    装备名称: '破弦',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.力道, 值: 1392 },
      { 属性: 属性类型.外功基础攻击, 值: 6962 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40094,
    uid: 40094,
    装备名称: '烟云漠霭·如许',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40093,
    uid: 40093,
    装备名称: '沉龙荡波·耀月',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40089,
    uid: 40089,
    装备名称: '沉龙荡波·岚明',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40088,
    uid: 40088,
    装备名称: '沉龙荡波·落寒沙',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40087,
    uid: 40087,
    装备名称: '沉龙荡波·寥松',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40083,
    uid: 40083,
    装备名称: '沉龙荡波·寒彻天',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40080,
    uid: 40080,
    装备名称: '沉龙荡波·风淡云',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40078,
    uid: 40078,
    装备名称: '沉龙荡波·云埋月',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40077,
    uid: 40077,
    装备名称: '沉龙荡波·空翠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40071,
    uid: 40071,
    装备名称: '沉龙荡波·舞浮风',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 40068,
    uid: 40068,
    装备名称: '沉龙荡波·催沙',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39140,
    uid: 39140,
    装备名称: '映池',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39138,
    uid: 39138,
    装备名称: '镞上尘',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39137,
    uid: 39137,
    装备名称: '肃风',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39133,
    uid: 39133,
    装备名称: '寒江水',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39132,
    uid: 39132,
    装备名称: '追鸥',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39131,
    uid: 39131,
    装备名称: '袭月',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39127,
    uid: 39127,
    装备名称: '埋春',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39124,
    uid: 39124,
    装备名称: '歌晚风',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39122,
    uid: 39122,
    装备名称: '映池',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39121,
    uid: 39121,
    装备名称: '藏帆',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39115,
    uid: 39115,
    装备名称: '登霄剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39112,
    uid: 39112,
    装备名称: '渺野',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39100,
    uid: 39100,
    装备名称: '映池',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39098,
    uid: 39098,
    装备名称: '镞上尘',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39097,
    uid: 39097,
    装备名称: '肃风',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39093,
    uid: 39093,
    装备名称: '寒江水',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39092,
    uid: 39092,
    装备名称: '追鸥',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39091,
    uid: 39091,
    装备名称: '袭月',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39087,
    uid: 39087,
    装备名称: '埋春',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39084,
    uid: 39084,
    装备名称: '歌晚风',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39082,
    uid: 39082,
    装备名称: '映池',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39081,
    uid: 39081,
    装备名称: '藏帆',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39075,
    uid: 39075,
    装备名称: '登霄剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 39072,
    uid: 39072,
    装备名称: '渺野',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 39060,
    uid: 39060,
    装备名称: '寻踪觅宝·姮娥弓箭',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39059,
    uid: 39059,
    装备名称: '寻踪觅宝·乘光刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39055,
    uid: 39055,
    装备名称: '寻踪觅宝·仲逢链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39054,
    uid: 39054,
    装备名称: '寻踪觅宝·承雾伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39053,
    uid: 39053,
    装备名称: '寻踪觅宝·阑观刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39049,
    uid: 39049,
    装备名称: '寻踪觅宝·扬砂刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5234,
    武器伤害_最大值: 8723,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39046,
    uid: 39046,
    装备名称: '寻踪觅宝·微夜棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39044,
    uid: 39044,
    装备名称: '寻踪觅宝·青尧剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39043,
    uid: 39043,
    装备名称: '寻踪觅宝·沐影弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39037,
    uid: 39037,
    装备名称: '寻踪觅宝·花觅剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 4536,
    武器伤害_最大值: 7560,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.身法, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39034,
    uid: 39034,
    装备名称: '寻踪觅宝·凌遥枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.力道, 值: 1209 },
      { 属性: 属性类型.外功基础攻击, 值: 6048 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39028,
    uid: 39028,
    装备名称: '寻踪觅宝·姮娥弓箭',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39027,
    uid: 39027,
    装备名称: '寻踪觅宝·百捷刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39023,
    uid: 39023,
    装备名称: '寻踪觅宝·简石链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39022,
    uid: 39022,
    装备名称: '寻踪觅宝·未行伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39021,
    uid: 39021,
    装备名称: '寻踪觅宝·炉峰刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39017,
    uid: 39017,
    装备名称: '寻踪觅宝·神前刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39014,
    uid: 39014,
    装备名称: '寻踪觅宝·河房棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 5536,
    武器伤害_最大值: 9227,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39012,
    uid: 39012,
    装备名称: '寻踪觅宝·散星剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4234,
    武器伤害_最大值: 7056,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39011,
    uid: 39011,
    装备名称: '寻踪觅宝·曲倦弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39005,
    uid: 39005,
    装备名称: '寻踪觅宝·飞峰剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4234,
    武器伤害_最大值: 7056,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 39002,
    uid: 39002,
    装备名称: '寻踪觅宝·天镜枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 5536,
    武器伤害_最大值: 9227,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38996,
    uid: 38996,
    装备名称: '衔虚弓箭',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38995,
    uid: 38995,
    装备名称: '忘女刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38991,
    uid: 38991,
    装备名称: '冥女链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38990,
    uid: 38990,
    装备名称: '尔容女伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38989,
    uid: 38989,
    装备名称: '稷山女刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38985,
    uid: 38985,
    装备名称: '震女刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4885,
    武器伤害_最大值: 8142,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38982,
    uid: 38982,
    装备名称: '阳钧女棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 5536,
    武器伤害_最大值: 9227,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38980,
    uid: 38980,
    装备名称: '铭女轻剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4234,
    武器伤害_最大值: 7056,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38979,
    uid: 38979,
    装备名称: '湘女弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38973,
    uid: 38973,
    装备名称: '岚女剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 4234,
    武器伤害_最大值: 7056,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.身法, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38970,
    uid: 38970,
    装备名称: '瀚女枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 5536,
    武器伤害_最大值: 9227,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.力道, 值: 1129 },
      { 属性: 属性类型.外功基础攻击, 值: 5645 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 40612,
    uid: 40612,
    装备名称: '独雁弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 40611,
    uid: 40611,
    装备名称: '戏涛刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 40607,
    uid: 40607,
    装备名称: '莫辞链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 40606,
    uid: 40606,
    装备名称: '觅时伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 40605,
    uid: 40605,
    装备名称: '知溪刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 40601,
    uid: 40601,
    装备名称: '引昕刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 40598,
    uid: 40598,
    装备名称: '苍浪短棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 40596,
    uid: 40596,
    装备名称: '嘉易剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 40595,
    uid: 40595,
    装备名称: '栖芷弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 40589,
    uid: 40589,
    装备名称: '淮意剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 40586,
    uid: 40586,
    装备名称: '应雷枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38958,
    uid: 38958,
    装备名称: '独雁弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38957,
    uid: 38957,
    装备名称: '戏涛刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38953,
    uid: 38953,
    装备名称: '莫辞链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38952,
    uid: 38952,
    装备名称: '觅时伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38951,
    uid: 38951,
    装备名称: '知溪刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38947,
    uid: 38947,
    装备名称: '引昕刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38944,
    uid: 38944,
    装备名称: '苍浪短棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38942,
    uid: 38942,
    装备名称: '嘉易剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38941,
    uid: 38941,
    装备名称: '栖芷弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38935,
    uid: 38935,
    装备名称: '淮意剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38932,
    uid: 38932,
    装备名称: '应雷枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38920,
    uid: 38920,
    装备名称: '宿秋弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38919,
    uid: 38919,
    装备名称: '重行刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38915,
    uid: 38915,
    装备名称: '竹霞链刃',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38914,
    uid: 38914,
    装备名称: '虞风伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38913,
    uid: 38913,
    装备名称: '叙砚刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38909,
    uid: 38909,
    装备名称: '宇渊刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38906,
    uid: 38906,
    装备名称: '渝峰棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38904,
    uid: 38904,
    装备名称: '执惘剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38903,
    uid: 38903,
    装备名称: '夜劫弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38897,
    uid: 38897,
    装备名称: '清丛剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.身法 },
    ],
  },
  {
    id: 38894,
    uid: 38894,
    装备名称: '麟月枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.力道 },
    ],
  },
  {
    id: 38882,
    uid: 38882,
    装备名称: '独雁弓',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38881,
    uid: 38881,
    装备名称: '双横刀',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38877,
    uid: 38877,
    装备名称: '夺音链刀',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38876,
    uid: 38876,
    装备名称: '洛语伞',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38875,
    uid: 38875,
    装备名称: '雪陵刀',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38871,
    uid: 38871,
    装备名称: '黎沉刀盾',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38868,
    uid: 38868,
    装备名称: '执风短棒',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38866,
    uid: 38866,
    装备名称: '楚渊剑',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38865,
    uid: 38865,
    装备名称: '疏叶弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38859,
    uid: 38859,
    装备名称: '盈雪剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38856,
    uid: 38856,
    装备名称: '断北枪',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1102 },
      { 属性: 属性类型.外功基础攻击, 值: 5511 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 38820,
    uid: 38820,
    装备名称: '流光射影',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38819,
    uid: 38819,
    装备名称: '顾雪',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38815,
    uid: 38815,
    装备名称: '雁啼在天',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38814,
    uid: 38814,
    装备名称: '游阊阖',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38813,
    uid: 38813,
    装备名称: '海阔孤帆',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38809,
    uid: 38809,
    装备名称: '日夕沉',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38806,
    uid: 38806,
    装备名称: '四时行',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38804,
    uid: 38804,
    装备名称: '山川异',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38803,
    uid: 38803,
    装备名称: '溪雨池',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38797,
    uid: 38797,
    装备名称: '奉金仙',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38794,
    uid: 38794,
    装备名称: '映霜枫',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38782,
    uid: 38782,
    装备名称: '长弓白羽',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38781,
    uid: 38781,
    装备名称: '急雨风',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38777,
    uid: 38777,
    装备名称: '身莫负',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38776,
    uid: 38776,
    装备名称: '飞鸟与还',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38775,
    uid: 38775,
    装备名称: '寒暑为期',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38771,
    uid: 38771,
    装备名称: '共清明',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4769,
    武器伤害_最大值: 7948,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38768,
    uid: 38768,
    装备名称: '持竿叟',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38766,
    uid: 38766,
    装备名称: '倚遍阑干',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38765,
    uid: 38765,
    装备名称: '玄潭清',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38759,
    uid: 38759,
    装备名称: '行道处',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 4133,
    武器伤害_最大值: 6888,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.身法, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38756,
    uid: 38756,
    装备名称: '北上云岭',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.力道, 值: 1023 },
      { 属性: 属性类型.外功基础攻击, 值: 5010 },
      { 属性: 属性类型.外功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 37104,
    uid: 37104,
    装备名称: '未央之夏',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 3763,
    武器伤害_最大值: 6272,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.身法, 值: 1356 },
      { 属性: 属性类型.外功基础攻击, 值: 5250 },
      { 属性: 属性类型.外功会心等级, 值: 5480 },
      { 属性: 属性类型.外功破防等级, 值: 5480 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37103,
    uid: 37103,
    装备名称: '渊鸣',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 3763,
    武器伤害_最大值: 6272,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.力道, 值: 1356 },
      { 属性: 属性类型.外功基础攻击, 值: 5166 },
      { 属性: 属性类型.外功会心等级, 值: 5480 },
      { 属性: 属性类型.外功破防等级, 值: 5669 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37099,
    uid: 37099,
    装备名称: '鬓间黄泉',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 3763,
    武器伤害_最大值: 6272,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.身法, 值: 1356 },
      { 属性: 属性类型.外功基础攻击, 值: 5250 },
      { 属性: 属性类型.外功会心等级, 值: 5480 },
      { 属性: 属性类型.外功破防等级, 值: 5480 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37098,
    uid: 37098,
    装备名称: '槎舟渡星',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 3763,
    武器伤害_最大值: 6272,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.身法, 值: 1356 },
      { 属性: 属性类型.外功基础攻击, 值: 5081 },
      { 属性: 属性类型.外功会心等级, 值: 5669 },
      { 属性: 属性类型.外功破防等级, 值: 5669 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37097,
    uid: 37097,
    装备名称: '风雪寒鹊',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 3763,
    武器伤害_最大值: 6272,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.力道, 值: 1356 },
      { 属性: 属性类型.外功基础攻击, 值: 5081 },
      { 属性: 属性类型.外功会心等级, 值: 5291 },
      { 属性: 属性类型.外功破防等级, 值: 6047 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37093,
    uid: 37093,
    装备名称: '凤鸣岐山·衔书',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 3763,
    武器伤害_最大值: 6272,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.身法, 值: 1356 },
      { 属性: 属性类型.外功基础攻击, 值: 5420 },
      { 属性: 属性类型.外功会心等级, 值: 5102 },
      { 属性: 属性类型.外功破防等级, 值: 5480 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37090,
    uid: 37090,
    装备名称: '雾卷澜阖',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 4265,
    武器伤害_最大值: 7108,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.力道, 值: 1356 },
      { 属性: 属性类型.外功基础攻击, 值: 5250 },
      { 属性: 属性类型.外功会心等级, 值: 6047 },
      { 属性: 属性类型.外功破防等级, 值: 6803 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37088,
    uid: 37088,
    装备名称: '金山月·寒晖',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 3262,
    武器伤害_最大值: 5436,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.身法, 值: 1356 },
      { 属性: 属性类型.外功基础攻击, 值: 5081 },
      { 属性: 属性类型.外功会心等级, 值: 6047 },
      { 属性: 属性类型.外功破防等级, 值: 5291 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37087,
    uid: 37087,
    装备名称: '清渊玉骨·寒弦',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 2509,
    武器伤害_最大值: 4182,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.力道, 值: 1356 },
      { 属性: 属性类型.外功基础攻击, 值: 4912 },
      { 属性: 属性类型.外功会心等级, 值: 5480 },
      { 属性: 属性类型.外功破防等级, 值: 6236 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37081,
    uid: 37081,
    装备名称: '愧琼瑰·玉碎',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 3262,
    武器伤害_最大值: 5436,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.身法, 值: 1356 },
      { 属性: 属性类型.外功基础攻击, 值: 4997 },
      { 属性: 属性类型.外功会心等级, 值: 5858 },
      { 属性: 属性类型.外功破防等级, 值: 5669 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.身法 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
  {
    id: 37078,
    uid: 37078,
    装备名称: '沙场闲客·幽冥',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 4265,
    武器伤害_最大值: 7108,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.力道, 值: 1356 },
      { 属性: 属性类型.外功基础攻击, 值: 5081 },
      { 属性: 属性类型.外功会心等级, 值: 5480 },
      { 属性: 属性类型.外功破防等级, 值: 5858 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功基础攻击 },
      { 镶嵌类型: 属性类型.力道 },
      { 镶嵌类型: 属性类型.外功破防等级 },
    ],
  },
]

export default 武器装备数据
