// import { GLOBAL_CDN_PREFIX } from '@/工具函数/const'
// import { GLOBAL_CDN_PREFIX } from '@/工具函数/const'
import ImgBg_1 from './bg.png'
// import ImgBg_2 from './bg2.png'

// const ImgBg_1 = `${GLOBAL_CDN_PREFIX}/bg_common_1.jpeg`

// const ImgBg_1 = `${GLOBAL_CDN_PREFIX}/bg_txj_1.jpg`
// const ImgBg_2 = `${GLOBAL_CDN_PREFIX}/bg_txj_2.png`
export default [ImgBg_1]
