import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 鞋子装备数据: 装备属性信息模型[] = [
  {
    id: 101603,
    uid: 101603,
    装备名称: '暮盈靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 26600,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10222 },
      { 属性: 属性类型.元气, 值: 834 },
      { 属性: 属性类型.内功基础攻击, 值: 2106 },
      { 属性: 属性类型.破招值, 值: 6970 },
      { 属性: 属性类型.无双等级, 值: 5576 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101602,
    uid: 101602,
    装备名称: '暮漠靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 26600,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10222 },
      { 属性: 属性类型.根骨, 值: 834 },
      { 属性: 属性类型.内功基础攻击, 值: 2106 },
      { 属性: 属性类型.破招值, 值: 6970 },
      { 属性: 属性类型.无双等级, 值: 5576 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101537,
    uid: 101537,
    装备名称: '白萱靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.全会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101536,
    uid: 101536,
    装备名称: '思菱靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101501,
    uid: 101501,
    装备名称: '凝青靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.加速等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101500,
    uid: 101500,
    装备名称: '晓菡靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.加速等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101425,
    uid: 101425,
    装备名称: '揽江·玉玑靴',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101421,
    uid: 101421,
    装备名称: '揽江·柳颦靴',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101420,
    uid: 101420,
    装备名称: '揽江·玉玑靴',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101415,
    uid: 101415,
    装备名称: '揽江·觅韵靴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101411,
    uid: 101411,
    装备名称: '揽江·挽沙靴',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101407,
    uid: 101407,
    装备名称: '揽江·悠清靴',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101405,
    uid: 101405,
    装备名称: '揽江·凝眸靴',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101403,
    uid: 101403,
    装备名称: '揽江·霜枝靴',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101401,
    uid: 101401,
    装备名称: '揽江·温羽靴',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101397,
    uid: 101397,
    装备名称: '揽江·落华靴',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101395,
    uid: 101395,
    装备名称: '揽江·忘语履',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功破防等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101250,
    uid: 101250,
    装备名称: '湘崖靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.元气, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.全会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101249,
    uid: 101249,
    装备名称: '湘沅靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9953 },
      { 属性: 属性类型.根骨, 值: 812 },
      { 属性: 属性类型.内功基础攻击, 值: 2051 },
      { 属性: 属性类型.内功会心等级, 值: 6786 },
      { 属性: 属性类型.无双等级, 值: 5429 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 103499,
    uid: 103499,
    装备名称: '沉龙荡波·沧远靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.全会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 103498,
    uid: 103498,
    装备名称: '沉龙荡波·漠天靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101208,
    uid: 101208,
    装备名称: '啸影靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.全会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101207,
    uid: 101207,
    装备名称: '末闻靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101172,
    uid: 101172,
    装备名称: '青霍靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101171,
    uid: 101171,
    装备名称: '持溯靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.加速等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101096,
    uid: 101096,
    装备名称: '展锋·晓河靴',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101092,
    uid: 101092,
    装备名称: '展锋·待芳履',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101091,
    uid: 101091,
    装备名称: '展锋·晓河靴',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101086,
    uid: 101086,
    装备名称: '展锋·松吟靴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101082,
    uid: 101082,
    装备名称: '展锋·夜云靴',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101078,
    uid: 101078,
    装备名称: '展锋·寒泉靴',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101076,
    uid: 101076,
    装备名称: '展锋·松寒履',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101074,
    uid: 101074,
    装备名称: '展锋·春断履',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101072,
    uid: 101072,
    装备名称: '展锋·圣化履',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101068,
    uid: 101068,
    装备名称: '展锋·看妆履',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101066,
    uid: 101066,
    装备名称: '展锋·玄解履',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功破防等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100987,
    uid: 100987,
    装备名称: '寻踪觅宝·莫南靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.元气, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.全会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100986,
    uid: 100986,
    装备名称: '寻踪觅宝·君溪靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8647 },
      { 属性: 属性类型.根骨, 值: 705 },
      { 属性: 属性类型.内功基础攻击, 值: 1781 },
      { 属性: 属性类型.内功会心等级, 值: 5896 },
      { 属性: 属性类型.无双等级, 值: 4716 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100957,
    uid: 100957,
    装备名称: '寻踪觅宝·晚崖靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1663 },
      { 属性: 属性类型.全会心等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100956,
    uid: 100956,
    装备名称: '寻踪觅宝·沉济靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1663 },
      { 属性: 属性类型.内功会心等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100921,
    uid: 100921,
    装备名称: '女曜靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.元气, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1663 },
      { 属性: 属性类型.内功破防等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100920,
    uid: 100920,
    装备名称: '女希靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8070 },
      { 属性: 属性类型.根骨, 值: 658 },
      { 属性: 属性类型.内功基础攻击, 值: 1663 },
      { 属性: 属性类型.内功破防等级, 值: 5502 },
      { 属性: 属性类型.无双等级, 值: 4402 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100795,
    uid: 100795,
    装备名称: '临虹鞋',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.元气, 值: 649 },
      { 属性: 属性类型.内功基础攻击, 值: 1639 },
      { 属性: 属性类型.破招值, 值: 5424 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100794,
    uid: 100794,
    装备名称: '临宇鞋',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7955 },
      { 属性: 属性类型.根骨, 值: 649 },
      { 属性: 属性类型.内功基础攻击, 值: 1639 },
      { 属性: 属性类型.破招值, 值: 5424 },
      { 属性: 属性类型.无双等级, 值: 4339 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104124,
    uid: 104124,
    装备名称: '故芳靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.全会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 104123,
    uid: 104123,
    装备名称: '剪桐靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.内功会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100891,
    uid: 100891,
    装备名称: '故芳靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100890,
    uid: 100890,
    装备名称: '剪桐靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.破招值, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100855,
    uid: 100855,
    装备名称: '花霭靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100854,
    uid: 100854,
    装备名称: '途南靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100819,
    uid: 100819,
    装备名称: '庭澜靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.全会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100818,
    uid: 100818,
    装备名称: '故云靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 643 },
      { 属性: 属性类型.内功基础攻击, 值: 1623 },
      { 属性: 属性类型.内功会心等级, 值: 5371 },
      { 属性: 属性类型.无双等级, 值: 4297 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100687,
    uid: 100687,
    装备名称: '若寓靴',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1298 },
      { 属性: 属性类型.内功破防等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100686,
    uid: 100686,
    装备名称: '越风靴',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1298 },
      { 属性: 属性类型.内功破防等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100651,
    uid: 100651,
    装备名称: '踏江履',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.元气, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1298 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100650,
    uid: 100650,
    装备名称: '承康履',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7878 },
      { 属性: 属性类型.根骨, 值: 597 },
      { 属性: 属性类型.内功基础攻击, 值: 1298 },
      { 属性: 属性类型.加速等级, 值: 5371 },
      { 属性: 属性类型.全能等级, 值: 2149 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
]

export default 鞋子装备数据
