import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 衣服装备数据: 装备属性信息模型[] = [
  {
    id: 101623,
    uid: 101623,
    装备名称: '暮渝衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 26600,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14603 },
      { 属性: 属性类型.身法, 值: 1191 },
      { 属性: 属性类型.外功基础攻击, 值: 2706 },
      { 属性: 属性类型.外功破防等级, 值: 9957 },
      { 属性: 属性类型.无双等级, 值: 7965 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101622,
    uid: 101622,
    装备名称: '暮祁衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 26600,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14603 },
      { 属性: 属性类型.力道, 值: 1191 },
      { 属性: 属性类型.外功基础攻击, 值: 2706 },
      { 属性: 属性类型.外功破防等级, 值: 9957 },
      { 属性: 属性类型.无双等级, 值: 7965 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101557,
    uid: 101557,
    装备名称: '寒江衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101556,
    uid: 101556,
    装备名称: '烟梦衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101521,
    uid: 101521,
    装备名称: '迟夜衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.加速等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101520,
    uid: 101520,
    装备名称: '旋山衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.加速等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101486,
    uid: 101486,
    装备名称: '揽江·云暮衣',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101485,
    uid: 101485,
    装备名称: '揽江·远帆衣',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101481,
    uid: 101481,
    装备名称: '揽江·厉峰衣',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101480,
    uid: 101480,
    装备名称: '揽江·点汐衣',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101479,
    uid: 101479,
    装备名称: '揽江·烬然衣',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101475,
    uid: 101475,
    装备名称: '揽江·日沉战甲',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101472,
    uid: 101472,
    装备名称: '揽江·逢夏衣',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101471,
    uid: 101471,
    装备名称: '揽江·尽拂衣',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101470,
    uid: 101470,
    装备名称: '揽江·寒锁衣',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101464,
    uid: 101464,
    装备名称: '揽江·空羲衣',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101461,
    uid: 101461,
    装备名称: '揽江·诛翳战甲',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101270,
    uid: 101270,
    装备名称: '湘浮衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101269,
    uid: 101269,
    装备名称: '湘灿衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 103519,
    uid: 103519,
    装备名称: '沉龙荡波·燕晚衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103518,
    uid: 103518,
    装备名称: '沉龙荡波·淘浪衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101228,
    uid: 101228,
    装备名称: '云屿衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101227,
    uid: 101227,
    装备名称: '闲月衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101192,
    uid: 101192,
    装备名称: '宛江衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101191,
    uid: 101191,
    装备名称: '玄卢衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101157,
    uid: 101157,
    装备名称: '展锋·风驰衫',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101156,
    uid: 101156,
    装备名称: '展锋·飞空衫',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101152,
    uid: 101152,
    装备名称: '展锋·寒鸿衫',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101151,
    uid: 101151,
    装备名称: '展锋·清跸衫',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101150,
    uid: 101150,
    装备名称: '展锋·霜鸿衫',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101146,
    uid: 101146,
    装备名称: '展锋·卷朔甲',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101143,
    uid: 101143,
    装备名称: '展锋·江蓠衣',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101142,
    uid: 101142,
    装备名称: '展锋·飞炎衣',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101141,
    uid: 101141,
    装备名称: '展锋·苦雨衣',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101135,
    uid: 101135,
    装备名称: '展锋·眠月衣',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101132,
    uid: 101132,
    装备名称: '展锋·望云战甲',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101001,
    uid: 101001,
    装备名称: '寻踪觅宝·途潇衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101000,
    uid: 101000,
    装备名称: '寻踪觅宝·光熙衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100971,
    uid: 100971,
    装备名称: '寻踪觅宝·春悉衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.身法, 值: 941 },
      { 属性: 属性类型.外功基础攻击, 值: 2136 },
      { 属性: 属性类型.加速等级, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100970,
    uid: 100970,
    装备名称: '寻踪觅宝·遮澜衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.力道, 值: 941 },
      { 属性: 属性类型.外功基础攻击, 值: 2136 },
      { 属性: 属性类型.加速等级, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100941,
    uid: 100941,
    装备名称: '女君衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.身法, 值: 941 },
      { 属性: 属性类型.外功基础攻击, 值: 2136 },
      { 属性: 属性类型.破招值, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100940,
    uid: 100940,
    装备名称: '女义衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.力道, 值: 941 },
      { 属性: 属性类型.外功基础攻击, 值: 2136 },
      { 属性: 属性类型.破招值, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104144,
    uid: 104144,
    装备名称: '风岱衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 104143,
    uid: 104143,
    装备名称: '项昌衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100911,
    uid: 100911,
    装备名称: '风岱衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100910,
    uid: 100910,
    装备名称: '项昌衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100875,
    uid: 100875,
    装备名称: '北邱衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100874,
    uid: 100874,
    装备名称: '曲郦衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100839,
    uid: 100839,
    装备名称: '渊忱衣',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100838,
    uid: 100838,
    装备名称: '羡双衣',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100707,
    uid: 100707,
    装备名称: '塞尘衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 853 },
      { 属性: 属性类型.外功基础攻击, 值: 1668 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100706,
    uid: 100706,
    装备名称: '峭月衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 853 },
      { 属性: 属性类型.外功基础攻击, 值: 1668 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100671,
    uid: 100671,
    装备名称: '奔枝衫',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 853 },
      { 属性: 属性类型.外功基础攻击, 值: 1668 },
      { 属性: 属性类型.外功会心等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100670,
    uid: 100670,
    装备名称: '环旌衫',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 853 },
      { 属性: 属性类型.外功基础攻击, 值: 1668 },
      { 属性: 属性类型.外功会心等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
]

export default 衣服装备数据
