import { 五彩石数据类型 } from '@/@types/五彩石'
import { 属性类型 } from '@/@types/属性'
import { 五彩石增益类型枚举 } from '@/@types/枚举'

const 五彩石_六级: 五彩石数据类型[] = [
  {
    五彩石名称: '彩·星见·激流·灭气(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '会心', 增益类型: 'atMagicCriticalStrike' },
      { 增益数值: 884, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
      { 增益数值: 5850, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·星见·灭气·激流(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '会心', 增益类型: 'atMagicCriticalStrike' },
      { 增益数值: 2925, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
      { 增益数值: 1768, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·激流·灭气·月华(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 442, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
      { 增益数值: 2925, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
      { 增益数值: 5850, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·激流·月华·灭气(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 442, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
      { 增益数值: 2925, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
      { 增益数值: 5850, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·灭气·激流·月华(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
      { 增益数值: 884, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
      { 增益数值: 5850, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·灭气·月华·激流(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
      { 增益数值: 2925, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
      { 增益数值: 1768, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·月华·激流·灭气(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
      { 增益数值: 884, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
      { 增益数值: 5850, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·月华·灭气·激流(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
      { 增益数值: 2925, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
      { 增益数值: 1768, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·无双·激流·灭气(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '无双', 增益类型: 'atStrainBase' },
      { 增益数值: 884, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
      { 增益数值: 5850, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·无双·灭气·激流(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '无双', 增益类型: 'atStrainBase' },
      { 增益数值: 2925, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
      { 增益数值: 1768, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·破招·激流·灭气(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '破招', 增益类型: 'atSurplusValueBase' },
      { 增益数值: 884, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
      { 增益数值: 5850, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·破招·灭气·激流(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '破招', 增益类型: 'atSurplusValueBase' },
      { 增益数值: 2925, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
      { 增益数值: 1768, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·真元·激流·灭气(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 188, 增益名称: '元气', 增益类型: 'atSpunkBase' },
      { 增益数值: 884, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
      { 增益数值: 5850, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·真元·灭气·激流(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 188, 增益名称: '元气', 增益类型: 'atSpunkBase' },
      { 增益数值: 2925, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
      { 增益数值: 1768, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·星见·激流·月华(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '会心', 增益类型: 'atMagicCriticalStrike' },
      { 增益数值: 884, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
      { 增益数值: 5850, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·星见·月华·激流(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '会心', 增益类型: 'atMagicCriticalStrike' },
      { 增益数值: 2925, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
      { 增益数值: 1768, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·无双·激流·月华(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '无双', 增益类型: 'atStrainBase' },
      { 增益数值: 884, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
      { 增益数值: 5850, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·无双·月华·激流(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '无双', 增益类型: 'atStrainBase' },
      { 增益数值: 2925, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
      { 增益数值: 1768, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·破招·激流·月华(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '破招', 增益类型: 'atSurplusValueBase' },
      { 增益数值: 884, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
      { 增益数值: 5850, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·破招·月华·激流(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '破招', 增益类型: 'atSurplusValueBase' },
      { 增益数值: 2925, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
      { 增益数值: 1768, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·真元·激流·月华(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 188, 增益名称: '元气', 增益类型: 'atSpunkBase' },
      { 增益数值: 884, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
      { 增益数值: 5850, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·真元·月华·激流(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 188, 增益名称: '元气', 增益类型: 'atSpunkBase' },
      { 增益数值: 2925, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
      { 增益数值: 1768, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·星见·灭气·月华(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '会心', 增益类型: 'atMagicCriticalStrike' },
      { 增益数值: 2925, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
      { 增益数值: 5850, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·星见·月华·灭气(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '会心', 增益类型: 'atMagicCriticalStrike' },
      { 增益数值: 2925, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
      { 增益数值: 5850, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·无双·灭气·月华(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '无双', 增益类型: 'atStrainBase' },
      { 增益数值: 2925, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
      { 增益数值: 5850, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·无双·月华·灭气(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '无双', 增益类型: 'atStrainBase' },
      { 增益数值: 2925, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
      { 增益数值: 5850, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·破招·灭气·月华(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '破招', 增益类型: 'atSurplusValueBase' },
      { 增益数值: 2925, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
      { 增益数值: 5850, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·破招·月华·灭气(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '破招', 增益类型: 'atSurplusValueBase' },
      { 增益数值: 2925, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
      { 增益数值: 5850, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·真元·灭气·月华(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 188, 增益名称: '元气', 增益类型: 'atSpunkBase' },
      { 增益数值: 2925, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
      { 增益数值: 5850, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·真元·月华·灭气(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 188, 增益名称: '元气', 增益类型: 'atSpunkBase' },
      { 增益数值: 2925, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
      { 增益数值: 5850, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·灵根·激流·灭气(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 188, 增益名称: '根骨', 增益类型: 'atSpiritBase' },
      { 增益数值: 884, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
      { 增益数值: 5850, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·灵根·灭气·激流(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 188, 增益名称: '根骨', 增益类型: 'atSpiritBase' },
      { 增益数值: 2925, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
      { 增益数值: 1768, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·灵根·激流·月华(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 188, 增益名称: '根骨', 增益类型: 'atSpiritBase' },
      { 增益数值: 884, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
      { 增益数值: 5850, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·灵根·月华·激流(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 188, 增益名称: '根骨', 增益类型: 'atSpiritBase' },
      { 增益数值: 2925, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
      { 增益数值: 1768, 增益名称: '内攻', 增益类型: 'atMagicAttackPowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·灵根·灭气·月华(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 188, 增益名称: '根骨', 增益类型: 'atSpiritBase' },
      { 增益数值: 2925, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
      { 增益数值: 5850, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·灵根·月华·灭气(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 188, 增益名称: '根骨', 增益类型: 'atSpiritBase' },
      { 增益数值: 2925, 增益名称: '会效', 增益类型: 'atMagicCriticalDamagePowerBase' },
      { 增益数值: 5850, 增益名称: '破防', 增益类型: 'atMagicOvercome' },
    ],
    DiamondCount1: 14,
    DiamondCount2: 16,
    DiamondCount3: 18,
    DiamondIntensity1: 50,
    DiamondIntensity2: 90,
    DiamondIntensity3: 108,
  },
  {
    五彩石名称: '彩·真元·急速·激流(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 188, 增益名称: '元气', 增益类型: 'atSpunkBase' },
      { 增益数值: 2925, 增益名称: '加速', 增益类型: 'atHasteBase' },
      {
        增益数值: 1768,
        增益名称: '内攻',
        增益类型: 'atMagicAttackPowerBase',
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·灵根·急速·激流(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 188, 增益名称: '根骨', 增益类型: 'atSpiritBase' },
      { 增益数值: 2925, 增益名称: '加速', 增益类型: 'atHasteBase' },
      {
        增益数值: 1768,
        增益名称: '内攻',
        增益类型: 'atMagicAttackPowerBase',
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·无双·急速·激流(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '无双', 增益类型: 'atStrainBase' },
      { 增益数值: 2925, 增益名称: '加速', 增益类型: 'atHasteBase' },
      {
        增益数值: 1768,
        增益名称: '内攻',
        增益类型: 'atMagicAttackPowerBase',
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·破招·急速·激流(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 1463, 增益名称: '破招', 增益类型: 'atSurplusValueBase' },
      { 增益数值: 2925, 增益名称: '加速', 增益类型: 'atHasteBase' },
      {
        增益数值: 1768,
        增益名称: '内攻',
        增益类型: 'atMagicAttackPowerBase',
      },
    ],
    DiamondCount1: '14',
    DiamondCount2: '16',
    DiamondCount3: '18',
    DiamondIntensity1: '50',
    DiamondIntensity2: '90',
    DiamondIntensity3: '108',
  },
  {
    五彩石名称: '彩·击破·强击·固筋(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 2925, 增益名称: 五彩石增益类型枚举.会心等级, 增益类型: 属性类型.全会心等级 },
      { 增益数值: 81, 增益名称: 五彩石增益类型枚举.根骨百分比, 增益类型: 属性类型.郭氏根骨 },
    ],
    DiamondCount1: '16',
    DiamondCount2: '18',
    DiamondIntensity1: '90',
    DiamondIntensity2: '108',
  },
  {
    五彩石名称: '彩·击破·强击·聚神(陆)',
    五彩石等级: 6,
    装备增益: [
      { 增益数值: 2925, 增益名称: 五彩石增益类型枚举.会心等级, 增益类型: 属性类型.全会心等级 },
      { 增益数值: 81, 增益名称: 五彩石增益类型枚举.元气百分比, 增益类型: 属性类型.郭氏元气 },
    ],
    DiamondCount1: '16',
    DiamondCount2: '18',
    DiamondIntensity1: '90',
    DiamondIntensity2: '108',
  },
]

export default 五彩石_六级
