export default {
  装备基础属性: {
    基础攻击: 32624,
    破防等级: 40769,
    无双等级: 38852,
    会心等级: 25147,
    会心效果等级: 5353,
    全能等级: 0,
    武器伤害_最小值: 3629,
    武器伤害_最大值: 6048,
    破招值: 5058,
    力道: 44,
    元气: 44,
    身法: 7254,
    根骨: 44,
    体质: 36991,
    基础气血上限: 52285,
    额外气血上限: 0,
    加速等级: 0,
    装分: 292422,
  },
  装备列表: [
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atAgilityBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 98333,
      装备部位: '帽子',
      附魔: '攻击+439',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsCriticalDamagePowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 98363,
      装备部位: '衣服',
      附魔: '无双+291',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 98273,
      装备部位: '腰带',
      附魔: '无双+291',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atSurplusValueBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 3,
      id: 98455,
      装备部位: '护腕',
      附魔: '破防+974',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalDamagePowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 3,
      id: 98465,
      装备部位: '下装',
      附魔: '破防+974',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 98303,
      装备部位: '鞋子',
      附魔: '攻击+439',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 39819,
      装备部位: '项链',
      附魔: '体质+361',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 4,
      id: 39855,
      装备部位: '腰坠',
      附魔: '体质+361',
    },
    {
      镶嵌孔数组: [],
      当前精炼等级: 3,
      id: 39865,
      装备部位: '戒指',
      附魔: '攻击+439',
    },
    {
      镶嵌孔数组: [],
      当前精炼等级: 3,
      id: 39865,
      装备部位: '戒指',
      附魔: '攻击+439',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 37188,
      装备部位: '暗器',
      附魔: '破防+974',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atAgilityBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 4,
      id: 37246,
      装备部位: '武器',
      附魔: '攻击+439',
    },
  ],
  五彩石: '彩·斩铁·锐刃·痛击(陆)',
  装备增益: {
    大附魔_伤帽: 1,
    大附魔_伤衣: 1,
    大附魔_伤腰: 1,
    大附魔_伤腕: 1,
    大附魔_伤鞋: 1,
    风特效腰坠_英雄: true,
    水特效武器_英雄: true,
    套装会心会效: true,
    套装技能: 1,
    切糕会心: false,
    切糕无双: false,
    切糕会心_英雄: false,
    切糕无双_英雄: false,
    冬至套装: false,
  },
}
