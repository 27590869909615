import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 帽子装备数据: 装备属性信息模型[] = [
  {
    id: 101617,
    uid: 101617,
    装备名称: '暮渝冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 26600,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13143 },
      { 属性: 属性类型.身法, 值: 1072 },
      { 属性: 属性类型.外功基础攻击, 值: 2435 },
      { 属性: 属性类型.破招值, 值: 8961 },
      { 属性: 属性类型.无双等级, 值: 7169 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101616,
    uid: 101616,
    装备名称: '暮祁冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 26600,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13143 },
      { 属性: 属性类型.力道, 值: 1072 },
      { 属性: 属性类型.外功基础攻击, 值: 2435 },
      { 属性: 属性类型.破招值, 值: 8961 },
      { 属性: 属性类型.无双等级, 值: 7169 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101659,
    uid: 101659,
    装备名称: '客行江湖·春帆冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.破招值, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101658,
    uid: 101658,
    装备名称: '客行江湖·凶炽冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.力道, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.破招值, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101551,
    uid: 101551,
    装备名称: '寒江帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.加速等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101550,
    uid: 101550,
    装备名称: '烟梦帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.力道, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.加速等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101515,
    uid: 101515,
    装备名称: '迟夜冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功会心等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101514,
    uid: 101514,
    装备名称: '旋山冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.力道, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功会心等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101455,
    uid: 101455,
    装备名称: '揽江·云暮冠',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101454,
    uid: 101454,
    装备名称: '揽江·远帆帽',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.力道, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101450,
    uid: 101450,
    装备名称: '揽江·厉峰冠',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101449,
    uid: 101449,
    装备名称: '揽江·点汐冠',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101448,
    uid: 101448,
    装备名称: '揽江·烬然冠',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.力道, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101444,
    uid: 101444,
    装备名称: '揽江·日沉冠',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101441,
    uid: 101441,
    装备名称: '揽江·逢夏冠',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.力道, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101440,
    uid: 101440,
    装备名称: '揽江·尽拂冠',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101439,
    uid: 101439,
    装备名称: '揽江·寒锁冠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.力道, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101433,
    uid: 101433,
    装备名称: '揽江·空羲冠',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101430,
    uid: 101430,
    装备名称: '揽江·诛翳战盔',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.力道, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.外功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101264,
    uid: 101264,
    装备名称: '湘浮冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.身法, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.破招值, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101263,
    uid: 101263,
    装备名称: '湘灿冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.力道, 值: 1044 },
      { 属性: 属性类型.外功基础攻击, 值: 2371 },
      { 属性: 属性类型.破招值, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 103513,
    uid: 103513,
    装备名称: '沉龙荡波·燕晚冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 103512,
    uid: 103512,
    装备名称: '沉龙荡波·淘浪冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101641,
    uid: 101641,
    装备名称: '客行江湖·凋阳冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.破招值, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101640,
    uid: 101640,
    装备名称: '客行江湖·近楼冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.破招值, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101222,
    uid: 101222,
    装备名称: '云屿帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.加速等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101221,
    uid: 101221,
    装备名称: '闲月帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.加速等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101186,
    uid: 101186,
    装备名称: '宛江冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101185,
    uid: 101185,
    装备名称: '玄卢冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101126,
    uid: 101126,
    装备名称: '展锋·风驰冠',
    所属门派: '万灵',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101125,
    uid: 101125,
    装备名称: '展锋·飞空冠',
    所属门派: '刀宗',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101121,
    uid: 101121,
    装备名称: '展锋·寒鸿冠',
    所属门派: '凌雪',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101120,
    uid: 101120,
    装备名称: '展锋·清跸冠',
    所属门派: '蓬莱',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101119,
    uid: 101119,
    装备名称: '展锋·霜鸿冠',
    所属门派: '霸刀',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101115,
    uid: 101115,
    装备名称: '展锋·卷朔盔',
    所属门派: '苍云',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101112,
    uid: 101112,
    装备名称: '展锋·江蓠冠',
    所属门派: '丐帮',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101111,
    uid: 101111,
    装备名称: '展锋·飞炎冠',
    所属门派: '藏剑',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101110,
    uid: 101110,
    装备名称: '展锋·苦雨冠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101104,
    uid: 101104,
    装备名称: '展锋·眠月冠',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 101101,
    uid: 101101,
    装备名称: '展锋·望云战盔',
    所属门派: '天策',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100995,
    uid: 100995,
    装备名称: '寻踪觅宝·途潇帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.身法, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100994,
    uid: 100994,
    装备名称: '寻踪觅宝·光熙帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.力道, 值: 907 },
      { 属性: 属性类型.外功基础攻击, 值: 2060 },
      { 属性: 属性类型.外功会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100965,
    uid: 100965,
    装备名称: '寻踪觅宝·春悉帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10376 },
      { 属性: 属性类型.身法, 值: 847 },
      { 属性: 属性类型.外功基础攻击, 值: 1923 },
      { 属性: 属性类型.外功会心等级, 值: 7075 },
      { 属性: 属性类型.无双等级, 值: 5660 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100964,
    uid: 100964,
    装备名称: '寻踪觅宝·遮澜帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10376 },
      { 属性: 属性类型.力道, 值: 847 },
      { 属性: 属性类型.外功基础攻击, 值: 1923 },
      { 属性: 属性类型.外功会心等级, 值: 7075 },
      { 属性: 属性类型.无双等级, 值: 5660 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100935,
    uid: 100935,
    装备名称: '女君帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10376 },
      { 属性: 属性类型.身法, 值: 847 },
      { 属性: 属性类型.外功基础攻击, 值: 1923 },
      { 属性: 属性类型.外功破防等级, 值: 7075 },
      { 属性: 属性类型.无双等级, 值: 5660 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100934,
    uid: 100934,
    装备名称: '女义帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10376 },
      { 属性: 属性类型.力道, 值: 847 },
      { 属性: 属性类型.外功基础攻击, 值: 1923 },
      { 属性: 属性类型.外功破防等级, 值: 7075 },
      { 属性: 属性类型.无双等级, 值: 5660 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100803,
    uid: 100803,
    装备名称: '临书帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.身法, 值: 834 },
      { 属性: 属性类型.外功基础攻击, 值: 1895 },
      { 属性: 属性类型.外功破防等级, 值: 6974 },
      { 属性: 属性类型.无双等级, 值: 5579 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 100802,
    uid: 100802,
    装备名称: '临雾帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.力道, 值: 834 },
      { 属性: 属性类型.外功基础攻击, 值: 1895 },
      { 属性: 属性类型.外功破防等级, 值: 6974 },
      { 属性: 属性类型.无双等级, 值: 5579 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }, { 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 104138,
    uid: 104138,
    装备名称: '风岱冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.身法, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.破招值, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 104137,
    uid: 104137,
    装备名称: '项昌冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.力道, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.破招值, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100905,
    uid: 100905,
    装备名称: '风岱冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.身法, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.破招值, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100904,
    uid: 100904,
    装备名称: '项昌冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.力道, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.破招值, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100869,
    uid: 100869,
    装备名称: '北邱冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.身法, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100868,
    uid: 100868,
    装备名称: '曲郦冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.力道, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100833,
    uid: 100833,
    装备名称: '渊忱冠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.身法, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.外功会心等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100832,
    uid: 100832,
    装备名称: '羡双冠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.力道, 值: 826 },
      { 属性: 属性类型.外功基础攻击, 值: 1877 },
      { 属性: 属性类型.外功会心等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100701,
    uid: 100701,
    装备名称: '塞尘帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.身法, 值: 767 },
      { 属性: 属性类型.外功基础攻击, 值: 1501 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.全能等级, 值: 2762 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100700,
    uid: 100700,
    装备名称: '峭月帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.力道, 值: 767 },
      { 属性: 属性类型.外功基础攻击, 值: 1501 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.全能等级, 值: 2762 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100665,
    uid: 100665,
    装备名称: '奔枝帽',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.身法, 值: 767 },
      { 属性: 属性类型.外功基础攻击, 值: 1501 },
      { 属性: 属性类型.外功会心等级, 值: 6906 },
      { 属性: 属性类型.全能等级, 值: 2762 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 100664,
    uid: 100664,
    装备名称: '环旌帽',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.力道, 值: 767 },
      { 属性: 属性类型.外功基础攻击, 值: 1501 },
      { 属性: 属性类型.外功会心等级, 值: 6906 },
      { 属性: 属性类型.全能等级, 值: 2762 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
]

export default 帽子装备数据
