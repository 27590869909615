import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 下装装备数据: 装备属性信息模型[] = [
  {
    id: 101611,
    uid: 101611,
    装备名称: '暮渝裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 26600,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14603 },
      { 属性: 属性类型.身法, 值: 1191 },
      { 属性: 属性类型.外功基础攻击, 值: 2706 },
      { 属性: 属性类型.外功会心等级, 值: 9957 },
      { 属性: 属性类型.无双等级, 值: 7965 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101610,
    uid: 101610,
    装备名称: '暮祁裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 26600,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14603 },
      { 属性: 属性类型.力道, 值: 1191 },
      { 属性: 属性类型.外功基础攻击, 值: 2706 },
      { 属性: 属性类型.外功会心等级, 值: 9957 },
      { 属性: 属性类型.无双等级, 值: 7965 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104338,
    uid: 104338,
    装备名称: '有隅裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [],
    镶嵌孔数组: [],
  },
  {
    id: 101653,
    uid: 101653,
    装备名称: '客行江湖·春帆下裳',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功破防等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101652,
    uid: 101652,
    装备名称: '客行江湖·凶炽下裳',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功破防等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101589,
    uid: 101589,
    装备名称: '鳞迹裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.外功基础攻击, 值: 4215 },
      { 属性: 属性类型.外功破防等级, 值: 7110 },
      { 属性: 属性类型.无双等级, 值: 14219 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 101588,
    uid: 101588,
    装备名称: '闲璧裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.外功基础攻击, 值: 5269 },
      { 属性: 属性类型.加速等级, 值: 17451 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101587,
    uid: 101587,
    装备名称: '熙雍裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.外功基础攻击, 值: 4215 },
      { 属性: 属性类型.外功破防等级, 值: 10987 },
      { 属性: 属性类型.破招值, 值: 10341 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101586,
    uid: 101586,
    装备名称: '有隅裤',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.外功基础攻击, 值: 4215 },
      { 属性: 属性类型.外功会心等级, 值: 7110 },
      { 属性: 属性类型.无双等级, 值: 14219 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101563,
    uid: 101563,
    装备名称: '寒江裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 101562,
    uid: 101562,
    装备名称: '烟梦裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 101545,
    uid: 101545,
    装备名称: '寒江裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101544,
    uid: 101544,
    装备名称: '烟梦裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101509,
    uid: 101509,
    装备名称: '迟夜下裳',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.加速等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101508,
    uid: 101508,
    装备名称: '旋山下裳',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.加速等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101258,
    uid: 101258,
    装备名称: '湘浮裳',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.身法, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 101257,
    uid: 101257,
    装备名称: '湘灿裳',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.力道, 值: 1160 },
      { 属性: 属性类型.外功基础攻击, 值: 2635 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 103507,
    uid: 103507,
    装备名称: '沉龙荡波·燕晚裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功破防等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 103506,
    uid: 103506,
    装备名称: '沉龙荡波·淘浪裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功破防等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101635,
    uid: 101635,
    装备名称: '客行江湖·凋阳裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功破防等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101634,
    uid: 101634,
    装备名称: '客行江湖·近楼裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功破防等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101234,
    uid: 101234,
    装备名称: '云屿裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 101233,
    uid: 101233,
    装备名称: '闲月裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 101216,
    uid: 101216,
    装备名称: '云屿裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101215,
    uid: 101215,
    装备名称: '闲月裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101180,
    uid: 101180,
    装备名称: '宛江下裳',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.身法, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 101179,
    uid: 101179,
    装备名称: '玄卢下裳',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.力道, 值: 1008 },
      { 属性: 属性类型.外功基础攻击, 值: 2289 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100929,
    uid: 100929,
    装备名称: '女君裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.身法, 值: 941 },
      { 属性: 属性类型.外功基础攻击, 值: 2136 },
      { 属性: 属性类型.外功会心等级, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100928,
    uid: 100928,
    装备名称: '女义裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.力道, 值: 941 },
      { 属性: 属性类型.外功基础攻击, 值: 2136 },
      { 属性: 属性类型.外功会心等级, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104132,
    uid: 104132,
    装备名称: '风岱裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 104131,
    uid: 104131,
    装备名称: '项昌裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100899,
    uid: 100899,
    装备名称: '风岱裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功会心等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100898,
    uid: 100898,
    装备名称: '项昌裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功会心等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100863,
    uid: 100863,
    装备名称: '北邱裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 100862,
    uid: 100862,
    装备名称: '曲郦裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 100827,
    uid: 100827,
    装备名称: '渊忱裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100826,
    uid: 100826,
    装备名称: '羡双裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 918 },
      { 属性: 属性类型.外功基础攻击, 值: 2085 },
      { 属性: 属性类型.外功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }, { 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 100695,
    uid: 100695,
    装备名称: '塞尘裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 853 },
      { 属性: 属性类型.外功基础攻击, 值: 1668 },
      { 属性: 属性类型.外功破防等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 100694,
    uid: 100694,
    装备名称: '峭月裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 853 },
      { 属性: 属性类型.外功基础攻击, 值: 1668 },
      { 属性: 属性类型.外功破防等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }, { 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 100659,
    uid: 100659,
    装备名称: '奔枝裤',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.身法, 值: 853 },
      { 属性: 属性类型.外功基础攻击, 值: 1668 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 100658,
    uid: 100658,
    装备名称: '环旌裤',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.力道, 值: 853 },
      { 属性: 属性类型.外功基础攻击, 值: 1668 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.外功基础攻击 }],
  },
]

export default 下装装备数据
