import { 属性系数, 等级常数, 等级系数 } from '@/数据/常量'
import { 目标属性类型 } from '@/@types/常量'

export const 目标集合: 目标属性类型[] = [
  {
    名称: '134级木桩',
    等级: 134,
    防御系数: 属性系数.防御 * (等级系数 * 134 - 等级常数),
    防御点数: 83679,
    目标类型: '木桩',
  },
  {
    名称: '133级木桩',
    等级: 133,
    防御系数: 属性系数.防御 * (等级系数 * 133 - 等级常数),
    防御点数: 79721,
    目标类型: '木桩',
  },
  {
    名称: '132级木桩',
    等级: 132,
    防御系数: 属性系数.防御 * (等级系数 * 132 - 等级常数),
    防御点数: 46901,
    目标类型: '木桩',
  },
  {
    名称: '131级木桩',
    等级: 131,
    防御系数: 属性系数.防御 * (等级系数 * 131 - 等级常数),
    防御点数: 33338,
    目标类型: '木桩',
  },
  // {
  //   名称: '雷神',
  //   等级: 132,
  //   防御系数: 属性系数.防御 * (等级系数 * 132 - 等级常数),
  //   防御点数: 15580,
  //   目标类型: '试炼',
  // },
  // {
  //   名称: '九色鹿',
  //   等级: 134,
  //   防御点数: 27561,
  //   防御系数: 属性系数.防御 * (等级系数 * 134 - 等级常数),
  //   目标类型: '试炼',
  // },
  // {
  //   名称: '地鼠门宝箱｜五行柱',
  //   等级: 130,
  //   防御系数: 属性系数.防御 * (等级系数 * 130 - 等级常数),
  //   防御点数: 7414,
  //   目标类型: '试炼',
  // },
]

export default 目标集合
