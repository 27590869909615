/**
 * @name 心法模块-凌海诀
 * 模块写入者：唐宋
 */
import type { 心法配置类型 } from '@/心法模块/interface'

import 背景图 from './资源/背景图'
import 默认数据 from './默认数据'
import 计算循环 from './计算循环'
import 奇穴数据 from './奇穴'
import 技能系数, { 技能增益 } from './技能系数'

const 心法配置: 心法配置类型 = {
  名称: '凌海诀',
  简写: 'lhj',
  数据提供: '数据：考拉；测试：会灰 方小皮',
  所属门派: '蓬莱',
  主属性: '身法',
  功法: '外功',
  基础属性: { 基础攻击: 6839, 会心等级: 6628 },
  主属性额外加成: { 面板攻击: 1802 / 1024, 会心等级: 420 / 1024 },
  基础气血加成: 245, // Math.floor((14 * 1 + 10) / 100 * 1024) // 130级心法等级14
  奇穴数据: 奇穴数据,
  技能系数: 技能系数,
  技能增益: 技能增益,
  计算循环: 计算循环,
  默认数据: 默认数据,
  系统配置: {
    主题色: '#5d607b',
    收益柱形图颜色: '#5d607b',
    心法图标: 'https://img.jx3box.com/image/xf/10533.png',
    背景图: 背景图,
    // 背景色渐变: `linear-gradient(to right, #08122b 30%, rgba(8, 18, 43, 0) 100%)`,
    背景色渐变: `linear-gradient(to right, rgba(0,0,0,0.6) 5%, rgba(93,96,123, 0.25) 70%)`,
    背景色: 'rgba(0, 0, 0, 0.5)',
  },
  // 性能功能关闭数组: ['优化算法'],
}

export default 心法配置
