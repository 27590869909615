import type { 循环数据 } from '@/@types/循环'

/**
 * @name 用于计算的循环数据
 * 该数据可以通过JCL分析器进行获取，也可以自己根据实际情况编写
 */

// 循环
// import 测试 from './测试.json'

import 纯指_三段加速 from './风雪/纯指_三段加速.json'
// import 风指_38引 from './风雪/风指_38引.json'

import 风指_稳定10破 from './福姬/风指_稳定10破.json'
// import 风指_控能简易版 from './福姬/风指_控能简易版.json'

import 风指_珠海盯帧 from './十九停/风指_珠海盯帧.json'

import 卫分_大风指 from './蔓海赋尘/卫分_大风指.json'
// import 风指_破起手 from './蔓海赋尘/风指_破起手.json'
// import 风指_引起手 from './蔓海赋尘/风指_引起手.json'

import 模拟器_工整 from './模拟器_工整.json'

const 计算循环: 循环数据[] = [
  // 测试,
  // 十九停
  // 风指_珠海盯帧,
  风指_珠海盯帧,
  // 福姬
  // 风指_一段加速_2,
  风指_稳定10破,
  // 风指_控能简易版,
  // 风雪
  // 风指_一段加速,
  // 风指_37引,
  // 风指_38引,
  // 蔓海赋尘
  卫分_大风指,
  // 风指_引起手,
  // 风指_破起手,
  纯指_三段加速,
  模拟器_工整,
] as 循环数据[]

export default 计算循环
