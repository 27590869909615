import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 腰坠装备数据: 装备属性信息模型[] = [
  {
    id: 41420,
    uid: 41420,
    装备名称: '凌容坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 26600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7302 },
      { 属性: 属性类型.身法, 值: 596 },
      { 属性: 属性类型.外功基础攻击, 值: 1353 },
      { 属性: 属性类型.外功会心等级, 值: 4978 },
      { 属性: 属性类型.无双等级, 值: 3983 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41419,
    uid: 41419,
    装备名称: '元雾坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 26600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7302 },
      { 属性: 属性类型.力道, 值: 596 },
      { 属性: 属性类型.外功基础攻击, 值: 1353 },
      { 属性: 属性类型.外功会心等级, 值: 4978 },
      { 属性: 属性类型.无双等级, 值: 3983 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41364,
    uid: 41364,
    装备名称: '贯鳞',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备特效: 装备特效枚举.风特效腰坠_英雄,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41363,
    uid: 41363,
    装备名称: '风落秋',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备特效: 装备特效枚举.风特效腰坠_英雄,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41340,
    uid: 41340,
    装备名称: '寒江坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41339,
    uid: 41339,
    装备名称: '烟梦坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41322,
    uid: 41322,
    装备名称: '迟夜坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 41321,
    uid: 41321,
    装备名称: '旋山坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 41304,
    uid: 41304,
    装备名称: '湘浮坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41303,
    uid: 41303,
    装备名称: '湘灿坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41408,
    uid: 41408,
    装备名称: '凌容坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 24600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.身法, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.外功会心等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41407,
    uid: 41407,
    装备名称: '元雾坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 24600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.力道, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.外功会心等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41396,
    uid: 41396,
    装备名称: '凌容坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 506 },
      { 属性: 属性类型.外功基础攻击, 值: 1149 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.无双等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41395,
    uid: 41395,
    装备名称: '元雾坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.力道, 值: 506 },
      { 属性: 属性类型.外功基础攻击, 值: 1149 },
      { 属性: 属性类型.外功会心等级, 值: 4230 },
      { 属性: 属性类型.无双等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41274,
    uid: 41274,
    装备名称: '云屿坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.破招值, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41273,
    uid: 41273,
    装备名称: '闲月坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.破招值, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41256,
    uid: 41256,
    装备名称: '宛江坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 41255,
    uid: 41255,
    装备名称: '玄卢坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 41172,
    uid: 41172,
    装备名称: '临苑坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.身法, 值: 464 },
      { 属性: 属性类型.外功基础攻击, 值: 1053 },
      { 属性: 属性类型.破招值, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41171,
    uid: 41171,
    装备名称: '临梧坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.力道, 值: 464 },
      { 属性: 属性类型.外功基础攻击, 值: 1053 },
      { 属性: 属性类型.破招值, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 41384,
    uid: 41384,
    装备名称: '凌容坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5655 },
      { 属性: 属性类型.身法, 值: 461 },
      { 属性: 属性类型.外功基础攻击, 值: 1048 },
      { 属性: 属性类型.外功会心等级, 值: 3855 },
      { 属性: 属性类型.无双等级, 值: 3084 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41383,
    uid: 41383,
    装备名称: '元雾坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5655 },
      { 属性: 属性类型.力道, 值: 461 },
      { 属性: 属性类型.外功基础攻击, 值: 1048 },
      { 属性: 属性类型.外功会心等级, 值: 3855 },
      { 属性: 属性类型.无双等级, 值: 3084 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 42629,
    uid: 42629,
    装备名称: '风岱坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42628,
    uid: 42628,
    装备名称: '项昌坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41226,
    uid: 41226,
    装备名称: '风岱坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41225,
    uid: 41225,
    装备名称: '项昌坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41208,
    uid: 41208,
    装备名称: '北邱坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41207,
    uid: 41207,
    装备名称: '曲郦坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41190,
    uid: 41190,
    装备名称: '渊忱坠',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 41189,
    uid: 41189,
    装备名称: '羡双坠',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 41100,
    uid: 41100,
    装备名称: '塞尘佩',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 834 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41099,
    uid: 41099,
    装备名称: '峭月佩',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 834 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41082,
    uid: 41082,
    装备名称: '奔枝佩',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 834 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41081,
    uid: 41081,
    装备名称: '环旌佩',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 834 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
]

export default 腰坠装备数据
