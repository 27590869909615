import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 戒指装备数据: 装备属性信息模型[] = [
  {
    id: 42728,
    uid: 42728,
    装备名称: '容与戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.加速等级, 值: 8725 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41430,
    uid: 41430,
    装备名称: '客行江湖·凝章戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41429,
    uid: 41429,
    装备名称: '客行江湖·惊憩戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41370,
    uid: 41370,
    装备名称: '容与戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.内功基础攻击, 值: 2344 },
      { 属性: 属性类型.内功破防等级, 值: 7433 },
      { 属性: 属性类型.无双等级, 值: 3232 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41369,
    uid: 41369,
    装备名称: '轻漓戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.内功基础攻击, 值: 2344 },
      { 属性: 属性类型.全会心等级, 值: 7433 },
      { 属性: 属性类型.无双等级, 值: 3232 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41368,
    uid: 41368,
    装备名称: '聊吟戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.内功基础攻击, 值: 2344 },
      { 属性: 属性类型.内功破防等级, 值: 7433 },
      { 属性: 属性类型.破招值, 值: 3232 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41367,
    uid: 41367,
    装备名称: '羁心戒',
    所属门派: '通用',
    装备主属性: '通用',
    装备品级: 25900,
    装备类型: 装备类型枚举.副本精简,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.内功基础攻击, 值: 3320 },
      { 属性: 属性类型.无双等级, 值: 7110 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41356,
    uid: 41356,
    装备名称: '白萱戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41355,
    uid: 41355,
    装备名称: '思菱戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41344,
    uid: 41344,
    装备名称: '白萱戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.全会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41343,
    uid: 41343,
    装备名称: '思菱戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41326,
    uid: 41326,
    装备名称: '凝青指环',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41325,
    uid: 41325,
    装备名称: '晓菡指环',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.破招值, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41308,
    uid: 41308,
    装备名称: '湘崖戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.元气, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.全会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41307,
    uid: 41307,
    装备名称: '湘沅戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.根骨, 值: 580 },
      { 属性: 属性类型.内功基础攻击, 值: 1465 },
      { 属性: 属性类型.内功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41424,
    uid: 41424,
    装备名称: '客行江湖·长绣戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.元气, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.内功破防等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41423,
    uid: 41423,
    装备名称: '客行江湖·襄云戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.根骨, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.内功破防等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41290,
    uid: 41290,
    装备名称: '啸影戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.元气, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41289,
    uid: 41289,
    装备名称: '末闻戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.根骨, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41278,
    uid: 41278,
    装备名称: '啸影戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.元气, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.全会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41277,
    uid: 41277,
    装备名称: '末闻戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.根骨, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.内功会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41260,
    uid: 41260,
    装备名称: '青霍戒指',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.元气, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.破招值, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41259,
    uid: 41259,
    装备名称: '持溯戒指',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.根骨, 值: 504 },
      { 属性: 属性类型.内功基础攻击, 值: 1272 },
      { 属性: 属性类型.破招值, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41242,
    uid: 41242,
    装备名称: '女曜戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5765 },
      { 属性: 属性类型.元气, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 1188 },
      { 属性: 属性类型.内功破防等级, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3144 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41241,
    uid: 41241,
    装备名称: '女希戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5765 },
      { 属性: 属性类型.根骨, 值: 470 },
      { 属性: 属性类型.内功基础攻击, 值: 1188 },
      { 属性: 属性类型.内功破防等级, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3144 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41176,
    uid: 41176,
    装备名称: '临曦戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.元气, 值: 464 },
      { 属性: 属性类型.内功基础攻击, 值: 1171 },
      { 属性: 属性类型.全会心等级, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41175,
    uid: 41175,
    装备名称: '临衣戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.根骨, 值: 464 },
      { 属性: 属性类型.内功基础攻击, 值: 1171 },
      { 属性: 属性类型.内功会心等级, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42633,
    uid: 42633,
    装备名称: '故芳戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42632,
    uid: 42632,
    装备名称: '剪桐戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41230,
    uid: 41230,
    装备名称: '故芳戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.全会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41229,
    uid: 41229,
    装备名称: '剪桐戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.内功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41212,
    uid: 41212,
    装备名称: '花霭戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41211,
    uid: 41211,
    装备名称: '途南戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41194,
    uid: 41194,
    装备名称: '庭澜戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.破招值, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41193,
    uid: 41193,
    装备名称: '故云戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 459 },
      { 属性: 属性类型.内功基础攻击, 值: 1159 },
      { 属性: 属性类型.破招值, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41104,
    uid: 41104,
    装备名称: '若寓戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 927 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41103,
    uid: 41103,
    装备名称: '越风戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 927 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41086,
    uid: 41086,
    装备名称: '踏江戒',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.元气, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 927 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 41085,
    uid: 41085,
    装备名称: '承康戒',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.根骨, 值: 426 },
      { 属性: 属性类型.内功基础攻击, 值: 927 },
      { 属性: 属性类型.内功破防等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42439,
    uid: 42439,
    装备名称: '落琼霄·乘',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 19800,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6348 },
      { 属性: 属性类型.元气, 值: 704 },
      { 属性: 属性类型.内功基础攻击, 值: 1348 },
      { 属性: 属性类型.全会心等级, 值: 4804 },
      { 属性: 属性类型.内功破防等级, 值: 5147 },
      { 属性: 属性类型.加速等级, 值: 1716 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42438,
    uid: 42438,
    装备名称: '落琼霄·羽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 19800,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6348 },
      { 属性: 属性类型.根骨, 值: 704 },
      { 属性: 属性类型.内功基础攻击, 值: 1348 },
      { 属性: 属性类型.内功会心等级, 值: 4804 },
      { 属性: 属性类型.内功破防等级, 值: 5147 },
      { 属性: 属性类型.加速等级, 值: 1716 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42434,
    uid: 42434,
    装备名称: '落琼霄·乘',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 17250,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5530 },
      { 属性: 属性类型.元气, 值: 613 },
      { 属性: 属性类型.内功基础攻击, 值: 1174 },
      { 属性: 属性类型.全会心等级, 值: 4185 },
      { 属性: 属性类型.内功破防等级, 值: 4484 },
      { 属性: 属性类型.加速等级, 值: 1495 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42433,
    uid: 42433,
    装备名称: '落琼霄·羽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 17250,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5530 },
      { 属性: 属性类型.根骨, 值: 613 },
      { 属性: 属性类型.内功基础攻击, 值: 1174 },
      { 属性: 属性类型.内功会心等级, 值: 4185 },
      { 属性: 属性类型.内功破防等级, 值: 4484 },
      { 属性: 属性类型.加速等级, 值: 1495 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42429,
    uid: 42429,
    装备名称: '落琼霄·乘',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 15500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4969 },
      { 属性: 属性类型.元气, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1055 },
      { 属性: 属性类型.全会心等级, 值: 3761 },
      { 属性: 属性类型.内功破防等级, 值: 4029 },
      { 属性: 属性类型.加速等级, 值: 1343 },
    ],
    镶嵌孔数组: [],
  },
  {
    id: 42428,
    uid: 42428,
    装备名称: '落琼霄·羽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 15500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 4969 },
      { 属性: 属性类型.根骨, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1055 },
      { 属性: 属性类型.内功会心等级, 值: 3761 },
      { 属性: 属性类型.内功破防等级, 值: 4029 },
      { 属性: 属性类型.加速等级, 值: 1343 },
    ],
    镶嵌孔数组: [],
  },
]

export default 戒指装备数据
