import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 帽子装备数据: 装备属性信息模型[] = [
  {
    id: 101615,
    uid: 101615,
    装备名称: '暮盈冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 26600,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13143 },
      { 属性: 属性类型.元气, 值: 1072 },
      { 属性: 属性类型.内功基础攻击, 值: 2708 },
      { 属性: 属性类型.破招值, 值: 8961 },
      { 属性: 属性类型.无双等级, 值: 7169 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101614,
    uid: 101614,
    装备名称: '暮漠冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 26600,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13143 },
      { 属性: 属性类型.根骨, 值: 1072 },
      { 属性: 属性类型.内功基础攻击, 值: 2708 },
      { 属性: 属性类型.破招值, 值: 8961 },
      { 属性: 属性类型.无双等级, 值: 7169 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101657,
    uid: 101657,
    装备名称: '客行江湖·凝章冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.破招值, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101656,
    uid: 101656,
    装备名称: '客行江湖·惊憩冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.根骨, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.破招值, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101549,
    uid: 101549,
    装备名称: '白萱帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.加速等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101548,
    uid: 101548,
    装备名称: '思菱帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.根骨, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.加速等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101513,
    uid: 101513,
    装备名称: '凝青冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.全会心等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101512,
    uid: 101512,
    装备名称: '晓菡冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.根骨, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功会心等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101456,
    uid: 101456,
    装备名称: '揽江·玉玑冠',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101452,
    uid: 101452,
    装备名称: '揽江·柳颦冠',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.根骨, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101451,
    uid: 101451,
    装备名称: '揽江·玉玑冠',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101446,
    uid: 101446,
    装备名称: '揽江·觅韵冠',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.根骨, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101442,
    uid: 101442,
    装备名称: '揽江·挽沙冠',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101438,
    uid: 101438,
    装备名称: '揽江·悠清冠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101436,
    uid: 101436,
    装备名称: '揽江·凝眸冠',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.根骨, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101434,
    uid: 101434,
    装备名称: '揽江·霜枝冠',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.根骨, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101432,
    uid: 101432,
    装备名称: '揽江·温羽冠',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.根骨, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101428,
    uid: 101428,
    装备名称: '揽江·落华冠',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101426,
    uid: 101426,
    装备名称: '揽江·忘语冠',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.内功破防等级, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101262,
    uid: 101262,
    装备名称: '湘崖冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.元气, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.破招值, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101261,
    uid: 101261,
    装备名称: '湘沅冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12797 },
      { 属性: 属性类型.根骨, 值: 1044 },
      { 属性: 属性类型.内功基础攻击, 值: 2637 },
      { 属性: 属性类型.破招值, 值: 8725 },
      { 属性: 属性类型.无双等级, 值: 6980 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 103511,
    uid: 103511,
    装备名称: '沉龙荡波·沧远冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.全会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 103510,
    uid: 103510,
    装备名称: '沉龙荡波·漠天冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101639,
    uid: 101639,
    装备名称: '客行江湖·长绣冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.破招值, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101638,
    uid: 101638,
    装备名称: '客行江湖·襄云冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.破招值, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101220,
    uid: 101220,
    装备名称: '啸影帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.加速等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101219,
    uid: 101219,
    装备名称: '末闻帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.加速等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 101184,
    uid: 101184,
    装备名称: '青霍冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.全会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101183,
    uid: 101183,
    装备名称: '持溯冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 101127,
    uid: 101127,
    装备名称: '展锋·晓河冠',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101123,
    uid: 101123,
    装备名称: '展锋·待芳冠',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101122,
    uid: 101122,
    装备名称: '展锋·晓河冠',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101117,
    uid: 101117,
    装备名称: '展锋·松吟冠',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101113,
    uid: 101113,
    装备名称: '展锋·夜云冠',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101109,
    uid: 101109,
    装备名称: '展锋·寒泉冠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101107,
    uid: 101107,
    装备名称: '展锋·松寒冠',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101105,
    uid: 101105,
    装备名称: '展锋·春断钗',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101103,
    uid: 101103,
    装备名称: '展锋·圣化冠',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101099,
    uid: 101099,
    装备名称: '展锋·看妆冠',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 101097,
    uid: 101097,
    装备名称: '展锋·玄解冠',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功破防等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100993,
    uid: 100993,
    装备名称: '寻踪觅宝·莫南帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.元气, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.全会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100992,
    uid: 100992,
    装备名称: '寻踪觅宝·君溪帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11117 },
      { 属性: 属性类型.根骨, 值: 907 },
      { 属性: 属性类型.内功基础攻击, 值: 2290 },
      { 属性: 属性类型.内功会心等级, 值: 7580 },
      { 属性: 属性类型.无双等级, 值: 6064 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100963,
    uid: 100963,
    装备名称: '寻踪觅宝·晚崖帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10376 },
      { 属性: 属性类型.元气, 值: 847 },
      { 属性: 属性类型.内功基础攻击, 值: 2138 },
      { 属性: 属性类型.全会心等级, 值: 7075 },
      { 属性: 属性类型.无双等级, 值: 5660 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100962,
    uid: 100962,
    装备名称: '寻踪觅宝·沉济帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10376 },
      { 属性: 属性类型.根骨, 值: 847 },
      { 属性: 属性类型.内功基础攻击, 值: 2138 },
      { 属性: 属性类型.内功会心等级, 值: 7075 },
      { 属性: 属性类型.无双等级, 值: 5660 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100933,
    uid: 100933,
    装备名称: '女曜帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10376 },
      { 属性: 属性类型.元气, 值: 847 },
      { 属性: 属性类型.内功基础攻击, 值: 2138 },
      { 属性: 属性类型.内功破防等级, 值: 7075 },
      { 属性: 属性类型.无双等级, 值: 5660 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100932,
    uid: 100932,
    装备名称: '女希帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10376 },
      { 属性: 属性类型.根骨, 值: 847 },
      { 属性: 属性类型.内功基础攻击, 值: 2138 },
      { 属性: 属性类型.内功破防等级, 值: 7075 },
      { 属性: 属性类型.无双等级, 值: 5660 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100801,
    uid: 100801,
    装备名称: '临起帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.元气, 值: 834 },
      { 属性: 属性类型.内功基础攻击, 值: 2107 },
      { 属性: 属性类型.内功破防等级, 值: 6974 },
      { 属性: 属性类型.无双等级, 值: 5579 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 100800,
    uid: 100800,
    装备名称: '临可帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10228 },
      { 属性: 属性类型.根骨, 值: 834 },
      { 属性: 属性类型.内功基础攻击, 值: 2107 },
      { 属性: 属性类型.内功破防等级, 值: 6974 },
      { 属性: 属性类型.无双等级, 值: 5579 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功破防等级 }],
  },
  {
    id: 104136,
    uid: 104136,
    装备名称: '故芳冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.元气, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.破招值, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 104135,
    uid: 104135,
    装备名称: '剪桐冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.根骨, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.破招值, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100903,
    uid: 100903,
    装备名称: '故芳冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.元气, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.破招值, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100902,
    uid: 100902,
    装备名称: '剪桐冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.根骨, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.破招值, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100867,
    uid: 100867,
    装备名称: '花霭冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.元气, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100866,
    uid: 100866,
    装备名称: '途南冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.根骨, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }, { 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 100831,
    uid: 100831,
    装备名称: '庭澜冠',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.元气, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.全会心等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100830,
    uid: 100830,
    装备名称: '故云冠',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.根骨, 值: 826 },
      { 属性: 属性类型.内功基础攻击, 值: 2087 },
      { 属性: 属性类型.内功会心等级, 值: 6906 },
      { 属性: 属性类型.无双等级, 值: 5525 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 100699,
    uid: 100699,
    装备名称: '若寓帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.元气, 值: 767 },
      { 属性: 属性类型.内功基础攻击, 值: 1669 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.全能等级, 值: 2762 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100698,
    uid: 100698,
    装备名称: '越风帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.根骨, 值: 767 },
      { 属性: 属性类型.内功基础攻击, 值: 1669 },
      { 属性: 属性类型.加速等级, 值: 6906 },
      { 属性: 属性类型.全能等级, 值: 2762 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100663,
    uid: 100663,
    装备名称: '踏江帽',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.元气, 值: 767 },
      { 属性: 属性类型.内功基础攻击, 值: 1669 },
      { 属性: 属性类型.全会心等级, 值: 6906 },
      { 属性: 属性类型.全能等级, 值: 2762 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 100662,
    uid: 100662,
    装备名称: '承康帽',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 10129 },
      { 属性: 属性类型.根骨, 值: 767 },
      { 属性: 属性类型.内功基础攻击, 值: 1669 },
      { 属性: 属性类型.内功会心等级, 值: 6906 },
      { 属性: 属性类型.全能等级, 值: 2762 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
]

export default 帽子装备数据
