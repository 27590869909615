import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 武器装备数据: 装备属性信息模型[] = [
  {
    id: 40321,
    uid: 40321,
    装备名称: '伏龙阳焰',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 4739,
    武器伤害_最大值: 7898,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.元气, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 9133 },
      { 属性: 属性类型.全会心等级, 值: 11438 },
      { 属性: 属性类型.内功破防等级, 值: 11438 },
      { 属性: 属性类型.加速等级, 值: 1830 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40317,
    uid: 40317,
    装备名称: '鹿王本生',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 4739,
    武器伤害_最大值: 7898,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.根骨, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 8995 },
      { 属性: 属性类型.内功会心等级, 值: 10980 },
      { 属性: 属性类型.内功破防等级, 值: 12353 },
      { 属性: 属性类型.加速等级, 值: 1830 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40316,
    uid: 40316,
    装备名称: '月升海楼',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 4739,
    武器伤害_最大值: 7898,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.元气, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 9133 },
      { 属性: 属性类型.内功会心等级, 值: 10523 },
      { 属性: 属性类型.内功破防等级, 值: 11438 },
      { 属性: 属性类型.加速等级, 值: 2745 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40311,
    uid: 40311,
    装备名称: '抚今',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 2369,
    武器伤害_最大值: 3949,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.根骨, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 8857 },
      { 属性: 属性类型.内功会心等级, 值: 11438 },
      { 属性: 属性类型.内功破防等级, 值: 11895 },
      { 属性: 属性类型.加速等级, 值: 2288 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40307,
    uid: 40307,
    装备名称: '血月',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 4739,
    武器伤害_最大值: 7898,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.元气, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 8857 },
      { 属性: 属性类型.内功会心等级, 值: 10980 },
      { 属性: 属性类型.内功破防等级, 值: 12353 },
      { 属性: 属性类型.加速等级, 值: 2288 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40302,
    uid: 40302,
    装备名称: '罪骨浮屠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 4739,
    武器伤害_最大值: 7898,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.元气, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 8857 },
      { 属性: 属性类型.外功会心等级, 值: 10980 },
      { 属性: 属性类型.内功破防等级, 值: 12810 },
      { 属性: 属性类型.加速等级, 值: 1830 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40300,
    uid: 40300,
    装备名称: '墨语沉香',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 2369,
    武器伤害_最大值: 3949,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.根骨, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 8995 },
      { 属性: 属性类型.内功会心等级, 值: 10980 },
      { 属性: 属性类型.内功破防等级, 值: 12353 },
      { 属性: 属性类型.加速等级, 值: 1830 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40298,
    uid: 40298,
    装备名称: '飞霜绛露',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 3080,
    武器伤害_最大值: 5133,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.根骨, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 9133 },
      { 属性: 属性类型.内功会心等级, 值: 10980 },
      { 属性: 属性类型.内功破防等级, 值: 11438 },
      { 属性: 属性类型.加速等级, 值: 2288 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40296,
    uid: 40296,
    装备名称: '苍冥游',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 3080,
    武器伤害_最大值: 5133,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.根骨, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 8857 },
      { 属性: 属性类型.内功会心等级, 值: 12810 },
      { 属性: 属性类型.内功破防等级, 值: 10980 },
      { 属性: 属性类型.加速等级, 值: 1830 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40292,
    uid: 40292,
    装备名称: '璃光浮远',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 2369,
    武器伤害_最大值: 3949,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.元气, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 9133 },
      { 属性: 属性类型.内功会心等级, 值: 10980 },
      { 属性: 属性类型.内功破防等级, 值: 11895 },
      { 属性: 属性类型.加速等级, 值: 1830 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40290,
    uid: 40290,
    装备名称: '桑莲妙境',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22000,
    武器伤害_最小值: 8056,
    武器伤害_最大值: 13426,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 20130 },
      { 属性: 属性类型.元气, 值: 1642 },
      { 属性: 属性类型.内功基础攻击, 值: 9410 },
      { 属性: 属性类型.内功会心等级, 值: 10523 },
      { 属性: 属性类型.内功破防等级, 值: 11438 },
      { 属性: 属性类型.加速等级, 值: 1830 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40289,
    uid: 40289,
    装备名称: '伏龙阳焰',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 4469,
    武器伤害_最大值: 7449,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.元气, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8614 },
      { 属性: 属性类型.全会心等级, 值: 10788 },
      { 属性: 属性类型.内功破防等级, 值: 10788 },
      { 属性: 属性类型.加速等级, 值: 1726 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40285,
    uid: 40285,
    装备名称: '鹿王本生',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 4469,
    武器伤害_最大值: 7449,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.根骨, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8484 },
      { 属性: 属性类型.内功会心等级, 值: 10356 },
      { 属性: 属性类型.内功破防等级, 值: 11651 },
      { 属性: 属性类型.加速等级, 值: 1726 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40284,
    uid: 40284,
    装备名称: '月升海楼',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 4469,
    武器伤害_最大值: 7449,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.元气, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8614 },
      { 属性: 属性类型.内功会心等级, 值: 9925 },
      { 属性: 属性类型.内功破防等级, 值: 10788 },
      { 属性: 属性类型.加速等级, 值: 2589 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40279,
    uid: 40279,
    装备名称: '抚今',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 2235,
    武器伤害_最大值: 3725,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.根骨, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8353 },
      { 属性: 属性类型.内功会心等级, 值: 10788 },
      { 属性: 属性类型.内功破防等级, 值: 11219 },
      { 属性: 属性类型.加速等级, 值: 2158 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40275,
    uid: 40275,
    装备名称: '血月',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 4469,
    武器伤害_最大值: 7449,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.元气, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8353 },
      { 属性: 属性类型.内功会心等级, 值: 10356 },
      { 属性: 属性类型.内功破防等级, 值: 11651 },
      { 属性: 属性类型.加速等级, 值: 2158 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40270,
    uid: 40270,
    装备名称: '罪骨浮屠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 4469,
    武器伤害_最大值: 7449,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.元气, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8353 },
      { 属性: 属性类型.外功会心等级, 值: 10356 },
      { 属性: 属性类型.内功破防等级, 值: 12082 },
      { 属性: 属性类型.加速等级, 值: 1726 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40268,
    uid: 40268,
    装备名称: '墨语沉香',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 2235,
    武器伤害_最大值: 3725,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.根骨, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8484 },
      { 属性: 属性类型.内功会心等级, 值: 10356 },
      { 属性: 属性类型.内功破防等级, 值: 11651 },
      { 属性: 属性类型.加速等级, 值: 1726 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40266,
    uid: 40266,
    装备名称: '飞霜绛露',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 2905,
    武器伤害_最大值: 4842,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.根骨, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8614 },
      { 属性: 属性类型.内功会心等级, 值: 10356 },
      { 属性: 属性类型.内功破防等级, 值: 10788 },
      { 属性: 属性类型.加速等级, 值: 2158 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40264,
    uid: 40264,
    装备名称: '苍冥游',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 2905,
    武器伤害_最大值: 4842,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.根骨, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8353 },
      { 属性: 属性类型.内功会心等级, 值: 12082 },
      { 属性: 属性类型.内功破防等级, 值: 10356 },
      { 属性: 属性类型.加速等级, 值: 1726 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40260,
    uid: 40260,
    装备名称: '璃光浮远',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 2235,
    武器伤害_最大值: 3725,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.元气, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8614 },
      { 属性: 属性类型.内功会心等级, 值: 10356 },
      { 属性: 属性类型.内功破防等级, 值: 11219 },
      { 属性: 属性类型.加速等级, 值: 1726 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40258,
    uid: 40258,
    装备名称: '桑莲妙境',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 20750,
    武器伤害_最小值: 7598,
    武器伤害_最大值: 12663,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 18986 },
      { 属性: 属性类型.元气, 值: 1549 },
      { 属性: 属性类型.内功基础攻击, 值: 8875 },
      { 属性: 属性类型.内功会心等级, 值: 9925 },
      { 属性: 属性类型.内功破防等级, 值: 10788 },
      { 属性: 属性类型.加速等级, 值: 1726 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40257,
    uid: 40257,
    装备名称: '伏龙阳焰',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 4200,
    武器伤害_最大值: 7000,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.元气, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 8095 },
      { 属性: 属性类型.全会心等级, 值: 10138 },
      { 属性: 属性类型.内功破防等级, 值: 10138 },
      { 属性: 属性类型.加速等级, 值: 1622 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40253,
    uid: 40253,
    装备名称: '鹿王本生',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 4200,
    武器伤害_最大值: 7000,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.根骨, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 7973 },
      { 属性: 属性类型.内功会心等级, 值: 9732 },
      { 属性: 属性类型.内功破防等级, 值: 10949 },
      { 属性: 属性类型.加速等级, 值: 1622 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40252,
    uid: 40252,
    装备名称: '月升海楼',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 4200,
    武器伤害_最大值: 7000,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.元气, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 8095 },
      { 属性: 属性类型.内功会心等级, 值: 9327 },
      { 属性: 属性类型.内功破防等级, 值: 10138 },
      { 属性: 属性类型.加速等级, 值: 2433 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40247,
    uid: 40247,
    装备名称: '抚今',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 2100,
    武器伤害_最大值: 3500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.根骨, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 7850 },
      { 属性: 属性类型.内功会心等级, 值: 10138 },
      { 属性: 属性类型.内功破防等级, 值: 10543 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40243,
    uid: 40243,
    装备名称: '血月',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 4200,
    武器伤害_最大值: 7000,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.元气, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 7850 },
      { 属性: 属性类型.内功会心等级, 值: 9732 },
      { 属性: 属性类型.内功破防等级, 值: 10949 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40238,
    uid: 40238,
    装备名称: '罪骨浮屠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 4200,
    武器伤害_最大值: 7000,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.元气, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 7850 },
      { 属性: 属性类型.外功会心等级, 值: 9732 },
      { 属性: 属性类型.内功破防等级, 值: 11354 },
      { 属性: 属性类型.加速等级, 值: 1622 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40236,
    uid: 40236,
    装备名称: '墨语沉香',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 2100,
    武器伤害_最大值: 3500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.根骨, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 7973 },
      { 属性: 属性类型.内功会心等级, 值: 9732 },
      { 属性: 属性类型.内功破防等级, 值: 10949 },
      { 属性: 属性类型.加速等级, 值: 1622 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40234,
    uid: 40234,
    装备名称: '飞霜绛露',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 2730,
    武器伤害_最大值: 4550,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.根骨, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 8095 },
      { 属性: 属性类型.内功会心等级, 值: 9732 },
      { 属性: 属性类型.内功破防等级, 值: 10138 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40232,
    uid: 40232,
    装备名称: '苍冥游',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 2730,
    武器伤害_最大值: 4550,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.根骨, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 7850 },
      { 属性: 属性类型.内功会心等级, 值: 11354 },
      { 属性: 属性类型.内功破防等级, 值: 9732 },
      { 属性: 属性类型.加速等级, 值: 1622 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40228,
    uid: 40228,
    装备名称: '璃光浮远',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 2100,
    武器伤害_最大值: 3500,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.元气, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 8095 },
      { 属性: 属性类型.内功会心等级, 值: 9732 },
      { 属性: 属性类型.内功破防等级, 值: 10543 },
      { 属性: 属性类型.加速等级, 值: 1622 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40226,
    uid: 40226,
    装备名称: '桑莲妙境',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 19500,
    武器伤害_最小值: 7140,
    武器伤害_最大值: 11900,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17843 },
      { 属性: 属性类型.元气, 值: 1456 },
      { 属性: 属性类型.内功基础攻击, 值: 8340 },
      { 属性: 属性类型.内功会心等级, 值: 9327 },
      { 属性: 属性类型.内功破防等级, 值: 10138 },
      { 属性: 属性类型.加速等级, 值: 1622 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40225,
    uid: 40225,
    装备名称: '伏龙阳焰',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 3942,
    武器伤害_最大值: 6570,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.元气, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7597 },
      { 属性: 属性类型.全会心等级, 值: 9514 },
      { 属性: 属性类型.内功破防等级, 值: 9514 },
      { 属性: 属性类型.加速等级, 值: 1522 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40221,
    uid: 40221,
    装备名称: '鹿王本生',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 3942,
    武器伤害_最大值: 6570,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.根骨, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7482 },
      { 属性: 属性类型.内功会心等级, 值: 9133 },
      { 属性: 属性类型.内功破防等级, 值: 10275 },
      { 属性: 属性类型.加速等级, 值: 1522 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40220,
    uid: 40220,
    装备名称: '月升海楼',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 3942,
    武器伤害_最大值: 6570,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.元气, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7597 },
      { 属性: 属性类型.内功会心等级, 值: 8753 },
      { 属性: 属性类型.内功破防等级, 值: 9514 },
      { 属性: 属性类型.加速等级, 值: 2283 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40215,
    uid: 40215,
    装备名称: '抚今',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 1971,
    武器伤害_最大值: 3285,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.根骨, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7367 },
      { 属性: 属性类型.内功会心等级, 值: 9514 },
      { 属性: 属性类型.内功破防等级, 值: 9894 },
      { 属性: 属性类型.加速等级, 值: 1903 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40211,
    uid: 40211,
    装备名称: '血月',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 3942,
    武器伤害_最大值: 6570,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.元气, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7367 },
      { 属性: 属性类型.内功会心等级, 值: 9133 },
      { 属性: 属性类型.内功破防等级, 值: 10275 },
      { 属性: 属性类型.加速等级, 值: 1903 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40206,
    uid: 40206,
    装备名称: '罪骨浮屠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 3942,
    武器伤害_最大值: 6570,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.元气, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7367 },
      { 属性: 属性类型.外功会心等级, 值: 9133 },
      { 属性: 属性类型.内功破防等级, 值: 10656 },
      { 属性: 属性类型.加速等级, 值: 1522 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40204,
    uid: 40204,
    装备名称: '墨语沉香',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 1971,
    武器伤害_最大值: 3285,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.根骨, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7482 },
      { 属性: 属性类型.内功会心等级, 值: 9133 },
      { 属性: 属性类型.内功破防等级, 值: 10275 },
      { 属性: 属性类型.加速等级, 值: 1522 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40202,
    uid: 40202,
    装备名称: '飞霜绛露',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 2562,
    武器伤害_最大值: 4270,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.根骨, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7597 },
      { 属性: 属性类型.内功会心等级, 值: 9133 },
      { 属性: 属性类型.内功破防等级, 值: 9514 },
      { 属性: 属性类型.加速等级, 值: 1903 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40200,
    uid: 40200,
    装备名称: '苍冥游',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 2562,
    武器伤害_最大值: 4270,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.根骨, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7367 },
      { 属性: 属性类型.内功会心等级, 值: 10656 },
      { 属性: 属性类型.内功破防等级, 值: 9133 },
      { 属性: 属性类型.加速等级, 值: 1522 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40196,
    uid: 40196,
    装备名称: '璃光浮远',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 1971,
    武器伤害_最大值: 3285,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.元气, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7597 },
      { 属性: 属性类型.内功会心等级, 值: 9133 },
      { 属性: 属性类型.内功破防等级, 值: 9894 },
      { 属性: 属性类型.加速等级, 值: 1522 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40194,
    uid: 40194,
    装备名称: '桑莲妙境',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 18300,
    武器伤害_最小值: 6701,
    武器伤害_最大值: 11168,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 16744 },
      { 属性: 属性类型.元气, 值: 1366 },
      { 属性: 属性类型.内功基础攻击, 值: 7827 },
      { 属性: 属性类型.内功会心等级, 值: 8753 },
      { 属性: 属性类型.内功破防等级, 值: 9514 },
      { 属性: 属性类型.加速等级, 值: 1522 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40193,
    uid: 40193,
    装备名称: '伏龙阳焰',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 3683,
    武器伤害_最大值: 6138,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.元气, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 7099 },
      { 属性: 属性类型.全会心等级, 值: 8890 },
      { 属性: 属性类型.内功破防等级, 值: 8890 },
      { 属性: 属性类型.加速等级, 值: 1422 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40189,
    uid: 40189,
    装备名称: '鹿王本生',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 3683,
    武器伤害_最大值: 6138,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.根骨, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 6991 },
      { 属性: 属性类型.内功会心等级, 值: 8534 },
      { 属性: 属性类型.内功破防等级, 值: 9601 },
      { 属性: 属性类型.加速等级, 值: 1422 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40188,
    uid: 40188,
    装备名称: '月升海楼',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 3683,
    武器伤害_最大值: 6138,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.元气, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 7099 },
      { 属性: 属性类型.内功会心等级, 值: 8179 },
      { 属性: 属性类型.内功破防等级, 值: 8890 },
      { 属性: 属性类型.加速等级, 值: 2134 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40183,
    uid: 40183,
    装备名称: '抚今',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 1842,
    武器伤害_最大值: 3070,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.根骨, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 6884 },
      { 属性: 属性类型.内功会心等级, 值: 8890 },
      { 属性: 属性类型.内功破防等级, 值: 9246 },
      { 属性: 属性类型.加速等级, 值: 1778 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40179,
    uid: 40179,
    装备名称: '血月',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 3683,
    武器伤害_最大值: 6138,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.元气, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 6884 },
      { 属性: 属性类型.内功会心等级, 值: 8534 },
      { 属性: 属性类型.内功破防等级, 值: 9601 },
      { 属性: 属性类型.加速等级, 值: 1778 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40174,
    uid: 40174,
    装备名称: '罪骨浮屠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 3683,
    武器伤害_最大值: 6138,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.元气, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 6884 },
      { 属性: 属性类型.外功会心等级, 值: 8534 },
      { 属性: 属性类型.内功破防等级, 值: 9957 },
      { 属性: 属性类型.加速等级, 值: 1422 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40172,
    uid: 40172,
    装备名称: '墨语沉香',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 1842,
    武器伤害_最大值: 3070,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.根骨, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 6991 },
      { 属性: 属性类型.内功会心等级, 值: 8534 },
      { 属性: 属性类型.内功破防等级, 值: 9601 },
      { 属性: 属性类型.加速等级, 值: 1422 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40170,
    uid: 40170,
    装备名称: '飞霜绛露',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 2394,
    武器伤害_最大值: 3990,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.根骨, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 7099 },
      { 属性: 属性类型.内功会心等级, 值: 8534 },
      { 属性: 属性类型.内功破防等级, 值: 8890 },
      { 属性: 属性类型.加速等级, 值: 1778 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40168,
    uid: 40168,
    装备名称: '苍冥游',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 2394,
    武器伤害_最大值: 3990,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.根骨, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 6884 },
      { 属性: 属性类型.内功会心等级, 值: 9957 },
      { 属性: 属性类型.内功破防等级, 值: 8534 },
      { 属性: 属性类型.加速等级, 值: 1422 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40164,
    uid: 40164,
    装备名称: '璃光浮远',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 1842,
    武器伤害_最大值: 3070,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.元气, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 7099 },
      { 属性: 属性类型.内功会心等级, 值: 8534 },
      { 属性: 属性类型.内功破防等级, 值: 9246 },
      { 属性: 属性类型.加速等级, 值: 1422 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40162,
    uid: 40162,
    装备名称: '桑莲妙境',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 17100,
    武器伤害_最小值: 6261,
    武器伤害_最大值: 10435,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 15647 },
      { 属性: 属性类型.元气, 值: 1277 },
      { 属性: 属性类型.内功基础攻击, 值: 7314 },
      { 属性: 属性类型.内功会心等级, 值: 8179 },
      { 属性: 属性类型.内功破防等级, 值: 8890 },
      { 属性: 属性类型.加速等级, 值: 1422 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40557,
    uid: 40557,
    装备名称: '停湖雀',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 16000,
    武器伤害_最小值: 3446,
    武器伤害_最大值: 5743,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12311 },
      { 属性: 属性类型.元气, 值: 1365 },
      { 属性: 属性类型.内功基础攻击, 值: 6240 },
      { 属性: 属性类型.全会心等级, 值: 9316 },
      { 属性: 属性类型.内功破防等级, 值: 9982 },
      { 属性: 属性类型.加速等级, 值: 3327 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40556,
    uid: 40556,
    装备名称: '停湖雀',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 15500,
    武器伤害_最小值: 3338,
    武器伤害_最大值: 5564,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11926 },
      { 属性: 属性类型.元气, 值: 1322 },
      { 属性: 属性类型.内功基础攻击, 值: 6045 },
      { 属性: 属性类型.全会心等级, 值: 9025 },
      { 属性: 属性类型.内功破防等级, 值: 9670 },
      { 属性: 属性类型.加速等级, 值: 3223 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40555,
    uid: 40555,
    装备名称: '停湖雀',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 15000,
    武器伤害_最小值: 3231,
    武器伤害_最大值: 5385,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11541 },
      { 属性: 属性类型.元气, 值: 1280 },
      { 属性: 属性类型.内功基础攻击, 值: 5850 },
      { 属性: 属性类型.全会心等级, 值: 8734 },
      { 属性: 属性类型.内功破防等级, 值: 9358 },
      { 属性: 属性类型.加速等级, 值: 3119 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40617,
    uid: 40617,
    装备名称: '他山月',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9618 },
      { 属性: 属性类型.元气, 值: 1066 },
      { 属性: 属性类型.内功基础攻击, 值: 4875 },
      { 属性: 属性类型.全会心等级, 值: 7278 },
      { 属性: 属性类型.内功破防等级, 值: 7798 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40554,
    uid: 40554,
    装备名称: '煞·他山月',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 9618 },
      { 属性: 属性类型.元气, 值: 1066 },
      { 属性: 属性类型.内功基础攻击, 值: 4875 },
      { 属性: 属性类型.全会心等级, 值: 7278 },
      { 属性: 属性类型.内功破防等级, 值: 7798 },
      { 属性: 属性类型.加速等级, 值: 2599 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38584,
    uid: 38584,
    装备名称: '煞·鹿王本生',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.根骨, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6651 },
      { 属性: 属性类型.内功会心等级, 值: 5474 },
      { 属性: 属性类型.内功破防等级, 值: 6488 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38583,
    uid: 38583,
    装备名称: '煞·月升海楼',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.元气, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6760 },
      { 属性: 属性类型.内功会心等级, 值: 5272 },
      { 属性: 属性类型.内功破防等级, 值: 6083 },
      { 属性: 属性类型.加速等级, 值: 2433 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38578,
    uid: 38578,
    装备名称: '煞·抚今',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1346,
    武器伤害_最大值: 2243,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.根骨, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6542 },
      { 属性: 属性类型.内功会心等级, 值: 5677 },
      { 属性: 属性类型.内功破防等级, 值: 6285 },
      { 属性: 属性类型.加速等级, 值: 2230 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38574,
    uid: 38574,
    装备名称: '煞·血月',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.元气, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6542 },
      { 属性: 属性类型.内功会心等级, 值: 5474 },
      { 属性: 属性类型.内功破防等级, 值: 6488 },
      { 属性: 属性类型.加速等级, 值: 2230 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38569,
    uid: 38569,
    装备名称: '煞·罪骨浮屠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.元气, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6542 },
      { 属性: 属性类型.外功会心等级, 值: 5474 },
      { 属性: 属性类型.内功破防等级, 值: 6691 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38567,
    uid: 38567,
    装备名称: '煞·墨语沉香',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1346,
    武器伤害_最大值: 2243,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.根骨, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6651 },
      { 属性: 属性类型.内功会心等级, 值: 5474 },
      { 属性: 属性类型.内功破防等级, 值: 6488 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38565,
    uid: 38565,
    装备名称: '煞·飞霜绛露',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1750,
    武器伤害_最大值: 2917,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.根骨, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6760 },
      { 属性: 属性类型.内功会心等级, 值: 5474 },
      { 属性: 属性类型.内功破防等级, 值: 6083 },
      { 属性: 属性类型.加速等级, 值: 2230 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38563,
    uid: 38563,
    装备名称: '煞·苍冥游',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1750,
    武器伤害_最大值: 2917,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.根骨, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6542 },
      { 属性: 属性类型.内功会心等级, 值: 6285 },
      { 属性: 属性类型.内功破防等级, 值: 5880 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38559,
    uid: 38559,
    装备名称: '煞·璃光浮远',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1346,
    武器伤害_最大值: 2243,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.元气, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6760 },
      { 属性: 属性类型.内功会心等级, 值: 5474 },
      { 属性: 属性类型.内功破防等级, 值: 6285 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38557,
    uid: 38557,
    装备名称: '煞·桑莲妙境',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4576,
    武器伤害_最大值: 7627,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.元气, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6978 },
      { 属性: 属性类型.内功会心等级, 值: 5272 },
      { 属性: 属性类型.内功破防等级, 值: 6083 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38074,
    uid: 38074,
    装备名称: '鹿王本生',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.根骨, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6651 },
      { 属性: 属性类型.内功会心等级, 值: 5474 },
      { 属性: 属性类型.内功破防等级, 值: 6488 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38073,
    uid: 38073,
    装备名称: '月升海楼',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.元气, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6760 },
      { 属性: 属性类型.内功会心等级, 值: 5272 },
      { 属性: 属性类型.内功破防等级, 值: 6083 },
      { 属性: 属性类型.加速等级, 值: 2433 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38068,
    uid: 38068,
    装备名称: '抚今',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1346,
    武器伤害_最大值: 2243,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.根骨, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6542 },
      { 属性: 属性类型.内功会心等级, 值: 5677 },
      { 属性: 属性类型.内功破防等级, 值: 6285 },
      { 属性: 属性类型.加速等级, 值: 2230 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38064,
    uid: 38064,
    装备名称: '血月',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.元气, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6542 },
      { 属性: 属性类型.内功会心等级, 值: 5474 },
      { 属性: 属性类型.内功破防等级, 值: 6488 },
      { 属性: 属性类型.加速等级, 值: 2230 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38059,
    uid: 38059,
    装备名称: '罪骨浮屠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 2692,
    武器伤害_最大值: 4487,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.元气, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6542 },
      { 属性: 属性类型.外功会心等级, 值: 5474 },
      { 属性: 属性类型.内功破防等级, 值: 6691 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38057,
    uid: 38057,
    装备名称: '墨语沉香',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1346,
    武器伤害_最大值: 2243,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.根骨, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6651 },
      { 属性: 属性类型.内功会心等级, 值: 5474 },
      { 属性: 属性类型.内功破防等级, 值: 6488 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38055,
    uid: 38055,
    装备名称: '飞霜绛露',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1750,
    武器伤害_最大值: 2917,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.根骨, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6760 },
      { 属性: 属性类型.内功会心等级, 值: 5474 },
      { 属性: 属性类型.内功破防等级, 值: 6083 },
      { 属性: 属性类型.加速等级, 值: 2230 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38053,
    uid: 38053,
    装备名称: '苍冥游',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1750,
    武器伤害_最大值: 2917,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.根骨, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6542 },
      { 属性: 属性类型.内功会心等级, 值: 6285 },
      { 属性: 属性类型.内功破防等级, 值: 5880 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38049,
    uid: 38049,
    装备名称: '璃光浮远',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 1346,
    武器伤害_最大值: 2243,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.元气, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6760 },
      { 属性: 属性类型.内功会心等级, 值: 5474 },
      { 属性: 属性类型.内功破防等级, 值: 6285 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38047,
    uid: 38047,
    装备名称: '桑莲妙境',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 12500,
    武器伤害_最小值: 4576,
    武器伤害_最大值: 7627,
    装备特效: 装备特效枚举.大橙武特效,
    装备类型: 装备类型枚举.橙武,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7502 },
      { 属性: 属性类型.元气, 值: 1455 },
      { 属性: 属性类型.内功基础攻击, 值: 6978 },
      { 属性: 属性类型.内功会心等级, 值: 5272 },
      { 属性: 属性类型.内功破防等级, 值: 6083 },
      { 属性: 属性类型.加速等级, 值: 2028 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40630,
    uid: 40630,
    装备名称: '大风吟',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 4125,
    武器伤害_最大值: 6875,
    装备类型: 装备类型枚举.普通,
    装备增益: [],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39395,
    uid: 39395,
    装备名称: '浮生孤吟',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 4125,
    武器伤害_最大值: 6875,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.元气, 值: 1430 },
      { 属性: 属性类型.内功基础攻击, 值: 7951 },
      { 属性: 属性类型.全会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39391,
    uid: 39391,
    装备名称: '如梭',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 4125,
    武器伤害_最大值: 6875,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.根骨, 值: 1430 },
      { 属性: 属性类型.内功基础攻击, 值: 7951 },
      { 属性: 属性类型.内功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39390,
    uid: 39390,
    装备名称: '浮生孤吟',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 4125,
    武器伤害_最大值: 6875,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.元气, 值: 1430 },
      { 属性: 属性类型.内功基础攻击, 值: 7951 },
      { 属性: 属性类型.内功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39385,
    uid: 39385,
    装备名称: '古意',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 2063,
    武器伤害_最大值: 3438,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.根骨, 值: 1430 },
      { 属性: 属性类型.内功基础攻击, 值: 7951 },
      { 属性: 属性类型.内功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39381,
    uid: 39381,
    装备名称: '漠风',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 4125,
    武器伤害_最大值: 6875,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.元气, 值: 1430 },
      { 属性: 属性类型.内功基础攻击, 值: 7951 },
      { 属性: 属性类型.内功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39376,
    uid: 39376,
    装备名称: '狂雨',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 4125,
    武器伤害_最大值: 6875,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.元气, 值: 1430 },
      { 属性: 属性类型.内功基础攻击, 值: 7951 },
      { 属性: 属性类型.外功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39374,
    uid: 39374,
    装备名称: '红雾',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 2063,
    武器伤害_最大值: 3438,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.根骨, 值: 1430 },
      { 属性: 属性类型.内功基础攻击, 值: 7951 },
      { 属性: 属性类型.内功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39372,
    uid: 39372,
    装备名称: '故园愁',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 2681,
    武器伤害_最大值: 4469,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.根骨, 值: 1430 },
      { 属性: 属性类型.内功基础攻击, 值: 7951 },
      { 属性: 属性类型.内功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39370,
    uid: 39370,
    装备名称: '卧松',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 2681,
    武器伤害_最大值: 4469,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.根骨, 值: 1430 },
      { 属性: 属性类型.内功基础攻击, 值: 7951 },
      { 属性: 属性类型.内功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39366,
    uid: 39366,
    装备名称: '雪梅寒',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 2063,
    武器伤害_最大值: 3438,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.元气, 值: 1430 },
      { 属性: 属性类型.内功基础攻击, 值: 7951 },
      { 属性: 属性类型.内功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39364,
    uid: 39364,
    装备名称: '无心自在',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 26600,
    武器伤害_最小值: 7013,
    武器伤害_最大值: 11688,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17524 },
      { 属性: 属性类型.元气, 值: 1430 },
      { 属性: 属性类型.内功基础攻击, 值: 7951 },
      { 属性: 属性类型.内功会心等级, 值: 11948 },
      { 属性: 属性类型.无双等级, 值: 11550 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39357,
    uid: 39357,
    装备名称: '攒星斗',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备特效: 装备特效枚举.内功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39353,
    uid: 39353,
    装备名称: '待归游',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备特效: 装备特效枚举.内功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39352,
    uid: 39352,
    装备名称: '攒星斗',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备特效: 装备特效枚举.内功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39347,
    uid: 39347,
    装备名称: '香雪乱',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备特效: 装备特效枚举.内功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39343,
    uid: 39343,
    装备名称: '朝夜合',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备特效: 装备特效枚举.内功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39338,
    uid: 39338,
    装备名称: '竹影声',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备特效: 装备特效枚举.内功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39336,
    uid: 39336,
    装备名称: '幽鸣莺',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备特效: 装备特效枚举.内功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39334,
    uid: 39334,
    装备名称: '朝游暮',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2611,
    武器伤害_最大值: 4352,
    装备特效: 装备特效枚举.内功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39332,
    uid: 39332,
    装备名称: '云酿雪',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2611,
    武器伤害_最大值: 4352,
    装备特效: 装备特效枚举.内功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39328,
    uid: 39328,
    装备名称: '乱山横',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备特效: 装备特效枚举.内功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39326,
    uid: 39326,
    装备名称: '本亦幻',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备特效: 装备特效枚举.内功_水特效武器_英雄,
    装备类型: 装备类型枚举.特效武器,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功破防等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39323,
    uid: 39323,
    装备名称: '无期星',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39319,
    uid: 39319,
    装备名称: '落白露',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39318,
    uid: 39318,
    装备名称: '无期星',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39313,
    uid: 39313,
    装备名称: '正文音',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39309,
    uid: 39309,
    装备名称: '去日行',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39304,
    uid: 39304,
    装备名称: '蜀星遥',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39302,
    uid: 39302,
    装备名称: '云凤声',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39300,
    uid: 39300,
    装备名称: '绮窗鹭',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2611,
    武器伤害_最大值: 4352,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39298,
    uid: 39298,
    装备名称: '溯风佪',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2611,
    武器伤害_最大值: 4352,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39294,
    uid: 39294,
    装备名称: '比翼归',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39292,
    uid: 39292,
    装备名称: '宿智通',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39283,
    uid: 39283,
    装备名称: '无期星',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.全会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39279,
    uid: 39279,
    装备名称: '落白露',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39278,
    uid: 39278,
    装备名称: '无期星',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39273,
    uid: 39273,
    装备名称: '正文音',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39269,
    uid: 39269,
    装备名称: '去日行',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39264,
    uid: 39264,
    装备名称: '蜀星遥',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.外功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39262,
    uid: 39262,
    装备名称: '云凤声',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39260,
    uid: 39260,
    装备名称: '绮窗鹭',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2611,
    武器伤害_最大值: 4352,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39258,
    uid: 39258,
    装备名称: '溯风佪',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2611,
    武器伤害_最大值: 4352,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39254,
    uid: 39254,
    装备名称: '比翼归',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39252,
    uid: 39252,
    装备名称: '宿智通',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.内功会心等级, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39179,
    uid: 39179,
    装备名称: '星机',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39175,
    uid: 39175,
    装备名称: '庭柯',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39174,
    uid: 39174,
    装备名称: '星易',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39169,
    uid: 39169,
    装备名称: '出岫',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39165,
    uid: 39165,
    装备名称: '孤壑',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39160,
    uid: 39160,
    装备名称: '幽眠',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 4017,
    武器伤害_最大值: 6695,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39158,
    uid: 39158,
    装备名称: '雨微罗',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39156,
    uid: 39156,
    装备名称: '泷月水',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2611,
    武器伤害_最大值: 4352,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39154,
    uid: 39154,
    装备名称: '雨濯',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2611,
    武器伤害_最大值: 4352,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.根骨, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39150,
    uid: 39150,
    装备名称: '玉树飞花',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 2008,
    武器伤害_最大值: 3347,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39148,
    uid: 39148,
    装备名称: '山空',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 25900,
    武器伤害_最小值: 6828,
    武器伤害_最大值: 11380,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 17063 },
      { 属性: 属性类型.元气, 值: 1392 },
      { 属性: 属性类型.内功基础攻击, 值: 7742 },
      { 属性: 属性类型.破招值, 值: 11634 },
      { 属性: 属性类型.无双等级, 值: 11246 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40095,
    uid: 40095,
    装备名称: '沉龙荡波·垂星',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40091,
    uid: 40091,
    装备名称: '沉龙荡波·淡痕',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40090,
    uid: 40090,
    装备名称: '沉龙荡波·垂星',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40085,
    uid: 40085,
    装备名称: '沉龙荡波·曳雾',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40081,
    uid: 40081,
    装备名称: '沉龙荡波·苦峰',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40076,
    uid: 40076,
    装备名称: '沉龙荡波·闲去',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40074,
    uid: 40074,
    装备名称: '沉龙荡波·叶归暮',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40072,
    uid: 40072,
    装备名称: '沉龙荡波·月似霰',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 2268,
    武器伤害_最大值: 3780,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40070,
    uid: 40070,
    装备名称: '沉龙荡波·烛浮',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 2268,
    武器伤害_最大值: 3780,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40066,
    uid: 40066,
    装备名称: '沉龙荡波·零花',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 40064,
    uid: 40064,
    装备名称: '沉龙荡波·雁误',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功破防等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39139,
    uid: 39139,
    装备名称: '星机',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39135,
    uid: 39135,
    装备名称: '暮寒',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39134,
    uid: 39134,
    装备名称: '星机',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39129,
    uid: 39129,
    装备名称: '松越',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39125,
    uid: 39125,
    装备名称: '引沙意',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39120,
    uid: 39120,
    装备名称: '思絮',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39118,
    uid: 39118,
    装备名称: '暗镜',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39116,
    uid: 39116,
    装备名称: '寒昭',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 2268,
    武器伤害_最大值: 3780,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39114,
    uid: 39114,
    装备名称: '玉遥剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 2268,
    武器伤害_最大值: 3780,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39110,
    uid: 39110,
    装备名称: '雨过洲',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39108,
    uid: 39108,
    装备名称: '莲不渡',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39099,
    uid: 39099,
    装备名称: '星机',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.全会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39095,
    uid: 39095,
    装备名称: '暮寒',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39094,
    uid: 39094,
    装备名称: '星机',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39089,
    uid: 39089,
    装备名称: '松越',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39085,
    uid: 39085,
    装备名称: '引沙意',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39080,
    uid: 39080,
    装备名称: '思絮',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.外功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39078,
    uid: 39078,
    装备名称: '暗镜',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39076,
    uid: 39076,
    装备名称: '寒昭',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 2268,
    武器伤害_最大值: 3780,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39074,
    uid: 39074,
    装备名称: '玉遥剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 2268,
    武器伤害_最大值: 3780,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 39070,
    uid: 39070,
    装备名称: '雨过洲',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39068,
    uid: 39068,
    装备名称: '莲不渡',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.内功会心等级, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 39061,
    uid: 39061,
    装备名称: '寻踪觅宝·千繁扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39057,
    uid: 39057,
    装备名称: '寻踪觅宝·叶虞卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39056,
    uid: 39056,
    装备名称: '寻踪觅宝·千繁灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39051,
    uid: 39051,
    装备名称: '寻踪觅宝·叶致琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39047,
    uid: 39047,
    装备名称: '寻踪觅宝·顾衡双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39042,
    uid: 39042,
    装备名称: '寻踪觅宝·萧云弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 3489,
    武器伤害_最大值: 5815,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39040,
    uid: 39040,
    装备名称: '寻踪觅宝·望夙笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39038,
    uid: 39038,
    装备名称: '寻踪觅宝·凝意双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 2268,
    武器伤害_最大值: 3780,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39036,
    uid: 39036,
    装备名称: '寻踪觅宝·玉微剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 2268,
    武器伤害_最大值: 3780,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.根骨, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39032,
    uid: 39032,
    装备名称: '寻踪觅宝·景漪笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 1745,
    武器伤害_最大值: 2908,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39030,
    uid: 39030,
    装备名称: '寻踪觅宝·肃辰棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22500,
    武器伤害_最小值: 5932,
    武器伤害_最大值: 9887,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14823 },
      { 属性: 属性类型.元气, 值: 1209 },
      { 属性: 属性类型.内功基础攻击, 值: 6725 },
      { 属性: 属性类型.破招值, 值: 10107 },
      { 属性: 属性类型.无双等级, 值: 9770 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39029,
    uid: 39029,
    装备名称: '寻踪觅宝·千繁扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39025,
    uid: 39025,
    装备名称: '寻踪觅宝·湘湖卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39024,
    uid: 39024,
    装备名称: '寻踪觅宝·缘山灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39019,
    uid: 39019,
    装备名称: '寻踪觅宝·银涛琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 1628,
    武器伤害_最大值: 2714,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39015,
    uid: 39015,
    装备名称: '寻踪觅宝·瑞午双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39010,
    uid: 39010,
    装备名称: '寻踪觅宝·腾沸弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39008,
    uid: 39008,
    装备名称: '寻踪觅宝·香桥笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 1628,
    武器伤害_最大值: 2714,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39006,
    uid: 39006,
    装备名称: '寻踪觅宝·舟北双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 2117,
    武器伤害_最大值: 3528,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39004,
    uid: 39004,
    装备名称: '寻踪觅宝·摄山剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 2117,
    武器伤害_最大值: 3528,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 39000,
    uid: 39000,
    装备名称: '寻踪觅宝·霜空笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 1628,
    武器伤害_最大值: 2714,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38998,
    uid: 38998,
    装备名称: '寻踪觅宝·阳和棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 5536,
    武器伤害_最大值: 9227,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.破招值, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38997,
    uid: 38997,
    装备名称: '青女扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.全会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38993,
    uid: 38993,
    装备名称: '秋女卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38992,
    uid: 38992,
    装备名称: '青女灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38987,
    uid: 38987,
    装备名称: '溪女琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 1628,
    武器伤害_最大值: 2714,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38983,
    uid: 38983,
    装备名称: '寒女双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38978,
    uid: 38978,
    装备名称: '荣女弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 3257,
    武器伤害_最大值: 5428,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.外功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38976,
    uid: 38976,
    装备名称: '明雪女笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 1628,
    武器伤害_最大值: 2714,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38974,
    uid: 38974,
    装备名称: '璇女双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 2117,
    武器伤害_最大值: 3528,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38972,
    uid: 38972,
    装备名称: '岩女剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 2117,
    武器伤害_最大值: 3528,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.根骨, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38968,
    uid: 38968,
    装备名称: '虹女笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 1628,
    武器伤害_最大值: 2714,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38966,
    uid: 38966,
    装备名称: '肃女棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 21000,
    武器伤害_最小值: 5536,
    武器伤害_最大值: 9227,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13835 },
      { 属性: 属性类型.元气, 值: 1129 },
      { 属性: 属性类型.内功基础攻击, 值: 6277 },
      { 属性: 属性类型.内功会心等级, 值: 9433 },
      { 属性: 属性类型.无双等级, 值: 9118 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 40613,
    uid: 40613,
    装备名称: '镜灵扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.全会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 40609,
    uid: 40609,
    装备名称: '淳语卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 40608,
    uid: 40608,
    装备名称: '镜灵灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 40603,
    uid: 40603,
    装备名称: '云疏琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 40599,
    uid: 40599,
    装备名称: '何沉双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 40594,
    uid: 40594,
    装备名称: '水漓弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 40592,
    uid: 40592,
    装备名称: '絮容笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 40590,
    uid: 40590,
    装备名称: '扬月双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 40588,
    uid: 40588,
    装备名称: '问筠剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 40584,
    uid: 40584,
    装备名称: '诺枫笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 40582,
    uid: 40582,
    装备名称: '越空棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38959,
    uid: 38959,
    装备名称: '镜灵扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38955,
    uid: 38955,
    装备名称: '淳语卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38954,
    uid: 38954,
    装备名称: '镜灵灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38949,
    uid: 38949,
    装备名称: '云疏琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38945,
    uid: 38945,
    装备名称: '何沉双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38940,
    uid: 38940,
    装备名称: '水漓弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.外功会心等级 },
    ],
  },
  {
    id: 38938,
    uid: 38938,
    装备名称: '絮容笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38936,
    uid: 38936,
    装备名称: '扬月双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38934,
    uid: 38934,
    装备名称: '问筠剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38930,
    uid: 38930,
    装备名称: '诺枫笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38928,
    uid: 38928,
    装备名称: '越空棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.内功会心等级 },
    ],
  },
  {
    id: 38921,
    uid: 38921,
    装备名称: '聆星扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.全会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38917,
    uid: 38917,
    装备名称: '辞玥卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38916,
    uid: 38916,
    装备名称: '聆星灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38911,
    uid: 38911,
    装备名称: '谷泠琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38907,
    uid: 38907,
    装备名称: '离光双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38902,
    uid: 38902,
    装备名称: '燕寒弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.外功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.外功会心效果等级 },
      { 镶嵌类型: 属性类型.外功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38900,
    uid: 38900,
    装备名称: '予霜笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38898,
    uid: 38898,
    装备名称: '潇颜双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38896,
    uid: 38896,
    装备名称: '瀚邱剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.根骨 },
    ],
  },
  {
    id: 38892,
    uid: 38892,
    装备名称: '捧露笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38890,
    uid: 38890,
    装备名称: '天飏棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功会心等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功会心效果等级 },
      { 镶嵌类型: 属性类型.内功会心等级 },
      { 镶嵌类型: 属性类型.元气 },
    ],
  },
  {
    id: 38883,
    uid: 38883,
    装备名称: '星浮扇',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38879,
    uid: 38879,
    装备名称: '引湖卷',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38878,
    uid: 38878,
    装备名称: '星浮灯',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38873,
    uid: 38873,
    装备名称: '西染琴',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38869,
    uid: 38869,
    装备名称: '晚凌双刀',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38864,
    uid: 38864,
    装备名称: '末商弩',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38862,
    uid: 38862,
    装备名称: '离夏笛',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38860,
    uid: 38860,
    装备名称: '江夕双剑',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38858,
    uid: 38858,
    装备名称: '陌尘剑',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38854,
    uid: 38854,
    装备名称: '棠月笔',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38852,
    uid: 38852,
    装备名称: '尽归棍',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1102 },
      { 属性: 属性类型.内功基础攻击, 值: 6128 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.无双等级, 值: 8901 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 38821,
    uid: 38821,
    装备名称: '天路行征',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38817,
    uid: 38817,
    装备名称: '岫青',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38816,
    uid: 38816,
    装备名称: '天路行征',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38811,
    uid: 38811,
    装备名称: '菱歌晚',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38807,
    uid: 38807,
    装备名称: '昼夜成岁',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38802,
    uid: 38802,
    装备名称: '芦花风',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38800,
    uid: 38800,
    装备名称: '偎春草',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38798,
    uid: 38798,
    装备名称: '碧云斜',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38796,
    uid: 38796,
    装备名称: '素商时序',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38792,
    uid: 38792,
    装备名称: '河星稀',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38790,
    uid: 38790,
    装备名称: '忘尘心',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.加速等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38783,
    uid: 38783,
    装备名称: '经纬四极',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38779,
    uid: 38779,
    装备名称: '芜菁花下',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38778,
    uid: 38778,
    装备名称: '经纬四极',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38773,
    uid: 38773,
    装备名称: '谢公卿',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38769,
    uid: 38769,
    装备名称: '未见孤烟',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38764,
    uid: 38764,
    装备名称: '移舟酌酒',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 3179,
    武器伤害_最大值: 5298,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38762,
    uid: 38762,
    装备名称: '枯木无华',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38760,
    uid: 38760,
    装备名称: '莲叶东',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38758,
    uid: 38758,
    装备名称: '云峰影叠',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 2066,
    武器伤害_最大值: 3444,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.根骨, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38754,
    uid: 38754,
    装备名称: '见月惊',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 1590,
    武器伤害_最大值: 2650,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 38752,
    uid: 38752,
    装备名称: '念浮图',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 20500,
    武器伤害_最小值: 5405,
    武器伤害_最大值: 9008,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 13505 },
      { 属性: 属性类型.元气, 值: 1023 },
      { 属性: 属性类型.内功基础攻击, 值: 5571 },
      { 属性: 属性类型.内功破防等级, 值: 9208 },
      { 属性: 属性类型.全能等级, 值: 3683 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
      { 镶嵌类型: 属性类型.全能等级 },
    ],
  },
  {
    id: 37101,
    uid: 37101,
    装备名称: '迟莲·旧歌',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 2509,
    武器伤害_最大值: 4182,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.根骨, 值: 1356 },
      { 属性: 属性类型.内功基础攻击, 值: 6199 },
      { 属性: 属性类型.内功会心等级, 值: 5102 },
      { 属性: 属性类型.内功破防等级, 值: 6047 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37100,
    uid: 37100,
    装备名称: '长生缘',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 2509,
    武器伤害_最大值: 4182,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.元气, 值: 1356 },
      { 属性: 属性类型.内功基础攻击, 值: 6300 },
      { 属性: 属性类型.内功会心等级, 值: 4913 },
      { 属性: 属性类型.内功破防等级, 值: 5669 },
      { 属性: 属性类型.加速等级, 值: 2268 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37095,
    uid: 37095,
    装备名称: '飘铃语·月寂',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 1254,
    武器伤害_最大值: 2090,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.根骨, 值: 1356 },
      { 属性: 属性类型.内功基础攻击, 值: 6097 },
      { 属性: 属性类型.内功会心等级, 值: 5291 },
      { 属性: 属性类型.内功破防等级, 值: 5858 },
      { 属性: 属性类型.加速等级, 值: 2079 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37091,
    uid: 37091,
    装备名称: '封霜曲刃·忆',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 2509,
    武器伤害_最大值: 4182,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.元气, 值: 1356 },
      { 属性: 属性类型.内功基础攻击, 值: 6097 },
      { 属性: 属性类型.内功会心等级, 值: 5102 },
      { 属性: 属性类型.内功破防等级, 值: 6047 },
      { 属性: 属性类型.加速等级, 值: 2079 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37086,
    uid: 37086,
    装备名称: '清渊玉骨·霜锋',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 2509,
    武器伤害_最大值: 4182,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.元气, 值: 1356 },
      { 属性: 属性类型.内功基础攻击, 值: 6097 },
      { 属性: 属性类型.外功会心等级, 值: 5102 },
      { 属性: 属性类型.内功破防等级, 值: 6236 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37084,
    uid: 37084,
    装备名称: '死生往复·牵丝',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 1254,
    武器伤害_最大值: 2090,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.根骨, 值: 1356 },
      { 属性: 属性类型.内功基础攻击, 值: 6199 },
      { 属性: 属性类型.内功会心等级, 值: 5102 },
      { 属性: 属性类型.内功破防等级, 值: 6047 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37082,
    uid: 37082,
    装备名称: '鳞海人间·水穷',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 1631,
    武器伤害_最大值: 2718,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.根骨, 值: 1356 },
      { 属性: 属性类型.内功基础攻击, 值: 6300 },
      { 属性: 属性类型.内功会心等级, 值: 5102 },
      { 属性: 属性类型.内功破防等级, 值: 5669 },
      { 属性: 属性类型.加速等级, 值: 2079 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37080,
    uid: 37080,
    装备名称: '愧琼瑰·珠沉',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 1631,
    武器伤害_最大值: 2718,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.根骨, 值: 1356 },
      { 属性: 属性类型.内功基础攻击, 值: 6097 },
      { 属性: 属性类型.内功会心等级, 值: 5858 },
      { 属性: 属性类型.内功破防等级, 值: 5480 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.根骨 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37076,
    uid: 37076,
    装备名称: '衔羽还·今夕',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 1254,
    武器伤害_最大值: 2090,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.元气, 值: 1356 },
      { 属性: 属性类型.内功基础攻击, 值: 6300 },
      { 属性: 属性类型.内功会心等级, 值: 5102 },
      { 属性: 属性类型.内功破防等级, 值: 5858 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
  {
    id: 37074,
    uid: 37074,
    装备名称: '慈悲音·观照',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 11650,
    武器伤害_最小值: 4265,
    武器伤害_最大值: 7108,
    装备特效: 装备特效枚举.小橙武特效,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6992 },
      { 属性: 属性类型.元气, 值: 1356 },
      { 属性: 属性类型.内功基础攻击, 值: 6503 },
      { 属性: 属性类型.内功会心等级, 值: 4913 },
      { 属性: 属性类型.内功破防等级, 值: 5669 },
      { 属性: 属性类型.加速等级, 值: 1890 },
    ],
    镶嵌孔数组: [
      { 镶嵌类型: 属性类型.内功基础攻击 },
      { 镶嵌类型: 属性类型.元气 },
      { 镶嵌类型: 属性类型.内功破防等级 },
    ],
  },
]

export default 武器装备数据
