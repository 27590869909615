import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备类型枚举 } from '@/@types/装备'

const 项链装备数据: 装备属性信息模型[] = [
  {
    id: 41414,
    uid: 41414,
    装备名称: '凌容链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 26600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7302 },
      { 属性: 属性类型.身法, 值: 596 },
      { 属性: 属性类型.外功基础攻击, 值: 1353 },
      { 属性: 属性类型.破招值, 值: 4978 },
      { 属性: 属性类型.无双等级, 值: 3983 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 41413,
    uid: 41413,
    装备名称: '元雾链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 26600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7302 },
      { 属性: 属性类型.力道, 值: 596 },
      { 属性: 属性类型.外功基础攻击, 值: 1353 },
      { 属性: 属性类型.破招值, 值: 4978 },
      { 属性: 属性类型.无双等级, 值: 3983 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 41352,
    uid: 41352,
    装备名称: '寒江项饰',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41351,
    uid: 41351,
    装备名称: '烟梦项饰',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.加速等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41334,
    uid: 41334,
    装备名称: '寒江项饰',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41333,
    uid: 41333,
    装备名称: '烟梦项饰',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41316,
    uid: 41316,
    装备名称: '迟夜链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41315,
    uid: 41315,
    装备名称: '旋山链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功破防等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41298,
    uid: 41298,
    装备名称: '湘浮链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.身法, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41297,
    uid: 41297,
    装备名称: '湘灿链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7110 },
      { 属性: 属性类型.力道, 值: 580 },
      { 属性: 属性类型.外功基础攻击, 值: 1317 },
      { 属性: 属性类型.外功会心等级, 值: 4847 },
      { 属性: 属性类型.无双等级, 值: 3878 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41402,
    uid: 41402,
    装备名称: '凌容链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 24600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.身法, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 41401,
    uid: 41401,
    装备名称: '元雾链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 24600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.力道, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 41390,
    uid: 41390,
    装备名称: '凌容链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.身法, 值: 506 },
      { 属性: 属性类型.外功基础攻击, 值: 1149 },
      { 属性: 属性类型.破招值, 值: 4230 },
      { 属性: 属性类型.无双等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 41389,
    uid: 41389,
    装备名称: '元雾链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6204 },
      { 属性: 属性类型.力道, 值: 506 },
      { 属性: 属性类型.外功基础攻击, 值: 1149 },
      { 属性: 属性类型.破招值, 值: 4230 },
      { 属性: 属性类型.无双等级, 值: 3384 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 41286,
    uid: 41286,
    装备名称: '云屿项饰',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41285,
    uid: 41285,
    装备名称: '闲月项饰',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.加速等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41268,
    uid: 41268,
    装备名称: '云屿项饰',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.外功会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41267,
    uid: 41267,
    装备名称: '闲月项饰',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.外功会心等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41250,
    uid: 41250,
    装备名称: '宛江链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.身法, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.外功破防等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41249,
    uid: 41249,
    装备名称: '玄卢链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6176 },
      { 属性: 属性类型.力道, 值: 504 },
      { 属性: 属性类型.外功基础攻击, 值: 1144 },
      { 属性: 属性类型.外功破防等级, 值: 4211 },
      { 属性: 属性类型.无双等级, 值: 3369 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41238,
    uid: 41238,
    装备名称: '女君链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5765 },
      { 属性: 属性类型.身法, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 1068 },
      { 属性: 属性类型.外功破防等级, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3144 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41237,
    uid: 41237,
    装备名称: '女义链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5765 },
      { 属性: 属性类型.力道, 值: 470 },
      { 属性: 属性类型.外功基础攻击, 值: 1068 },
      { 属性: 属性类型.外功破防等级, 值: 3930 },
      { 属性: 属性类型.无双等级, 值: 3144 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41166,
    uid: 41166,
    装备名称: '临越链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.身法, 值: 464 },
      { 属性: 属性类型.外功基础攻击, 值: 1053 },
      { 属性: 属性类型.外功破防等级, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41165,
    uid: 41165,
    装备名称: '临邦链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5682 },
      { 属性: 属性类型.力道, 值: 464 },
      { 属性: 属性类型.外功基础攻击, 值: 1053 },
      { 属性: 属性类型.外功破防等级, 值: 3874 },
      { 属性: 属性类型.无双等级, 值: 3099 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41378,
    uid: 41378,
    装备名称: '凌容链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5655 },
      { 属性: 属性类型.身法, 值: 461 },
      { 属性: 属性类型.外功基础攻击, 值: 1048 },
      { 属性: 属性类型.破招值, 值: 3855 },
      { 属性: 属性类型.无双等级, 值: 3084 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 41377,
    uid: 41377,
    装备名称: '元雾链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20600,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5655 },
      { 属性: 属性类型.力道, 值: 461 },
      { 属性: 属性类型.外功基础攻击, 值: 1048 },
      { 属性: 属性类型.破招值, 值: 3855 },
      { 属性: 属性类型.无双等级, 值: 3084 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 42623,
    uid: 42623,
    装备名称: '风岱链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 42622,
    uid: 42622,
    装备名称: '项昌链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41220,
    uid: 41220,
    装备名称: '风岱链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41219,
    uid: 41219,
    装备名称: '项昌链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功破防等级 }],
  },
  {
    id: 41202,
    uid: 41202,
    装备名称: '北邱链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41201,
    uid: 41201,
    装备名称: '曲郦链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41184,
    uid: 41184,
    装备名称: '渊忱链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41183,
    uid: 41183,
    装备名称: '羡双链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 459 },
      { 属性: 属性类型.外功基础攻击, 值: 1043 },
      { 属性: 属性类型.加速等级, 值: 3837 },
      { 属性: 属性类型.无双等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 41094,
    uid: 41094,
    装备名称: '塞尘链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 834 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41093,
    uid: 41093,
    装备名称: '峭月链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 834 },
      { 属性: 属性类型.外功破防等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 41076,
    uid: 41076,
    装备名称: '奔枝链',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.身法, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 834 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 41075,
    uid: 41075,
    装备名称: '环旌链',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 5627 },
      { 属性: 属性类型.力道, 值: 426 },
      { 属性: 属性类型.外功基础攻击, 值: 834 },
      { 属性: 属性类型.外功会心等级, 值: 3837 },
      { 属性: 属性类型.全能等级, 值: 1535 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
]

export default 项链装备数据
