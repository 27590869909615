import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备类型枚举 } from '@/@types/装备'

const 暗器装备数据: 装备属性信息模型[] = [
  {
    id: 39361,
    uid: 39361,
    装备名称: '尘许囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.身法, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39360,
    uid: 39360,
    装备名称: '度飞囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.力道, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39289,
    uid: 39289,
    装备名称: '寒江囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.身法, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.外功会心等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39288,
    uid: 39288,
    装备名称: '烟梦囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.力道, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.外功会心等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39249,
    uid: 39249,
    装备名称: '迟夜箭囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.身法, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.加速等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 39248,
    uid: 39248,
    装备名称: '旋山箭囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.力道, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.加速等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 39145,
    uid: 39145,
    装备名称: '湘浮囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.身法, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.外功会心等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39144,
    uid: 39144,
    装备名称: '湘灿囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.力道, 值: 696 },
      { 属性: 属性类型.外功基础攻击, 值: 1581 },
      { 属性: 属性类型.外功会心等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 40832,
    uid: 40832,
    装备名称: '尘许囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.身法, 值: 605 },
      { 属性: 属性类型.外功基础攻击, 值: 1373 },
      { 属性: 属性类型.破招值, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 40831,
    uid: 40831,
    装备名称: '度飞囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.力道, 值: 605 },
      { 属性: 属性类型.外功基础攻击, 值: 1373 },
      { 属性: 属性类型.破招值, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 40061,
    uid: 40061,
    装备名称: '沉龙荡波·燕晚囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.身法, 值: 605 },
      { 属性: 属性类型.外功基础攻击, 值: 1373 },
      { 属性: 属性类型.外功会心等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 40060,
    uid: 40060,
    装备名称: '沉龙荡波·淘浪囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.力道, 值: 605 },
      { 属性: 属性类型.外功基础攻击, 值: 1373 },
      { 属性: 属性类型.外功会心等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39105,
    uid: 39105,
    装备名称: '云屿囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.身法, 值: 605 },
      { 属性: 属性类型.外功基础攻击, 值: 1373 },
      { 属性: 属性类型.外功会心等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39104,
    uid: 39104,
    装备名称: '闲月囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.力道, 值: 605 },
      { 属性: 属性类型.外功基础攻击, 值: 1373 },
      { 属性: 属性类型.外功会心等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 39065,
    uid: 39065,
    装备名称: '宛江囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.身法, 值: 605 },
      { 属性: 属性类型.外功基础攻击, 值: 1373 },
      { 属性: 属性类型.加速等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 39064,
    uid: 39064,
    装备名称: '玄卢囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.力道, 值: 605 },
      { 属性: 属性类型.外功基础攻击, 值: 1373 },
      { 属性: 属性类型.加速等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 38963,
    uid: 38963,
    装备名称: '女君囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6917 },
      { 属性: 属性类型.身法, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 1282 },
      { 属性: 属性类型.破招值, 值: 4716 },
      { 属性: 属性类型.无双等级, 值: 3773 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38962,
    uid: 38962,
    装备名称: '女义囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6917 },
      { 属性: 属性类型.力道, 值: 564 },
      { 属性: 属性类型.外功基础攻击, 值: 1282 },
      { 属性: 属性类型.破招值, 值: 4716 },
      { 属性: 属性类型.无双等级, 值: 3773 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38843,
    uid: 38843,
    装备名称: '临晚囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.身法, 值: 556 },
      { 属性: 属性类型.外功基础攻击, 值: 1263 },
      { 属性: 属性类型.外功会心等级, 值: 4649 },
      { 属性: 属性类型.无双等级, 值: 3719 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38842,
    uid: 38842,
    装备名称: '临掣囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.力道, 值: 556 },
      { 属性: 属性类型.外功基础攻击, 值: 1263 },
      { 属性: 属性类型.外功会心等级, 值: 4649 },
      { 属性: 属性类型.无双等级, 值: 3719 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 40579,
    uid: 40579,
    装备名称: '风岱囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.身法, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 40578,
    uid: 40578,
    装备名称: '项昌囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.力道, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38925,
    uid: 38925,
    装备名称: '风岱囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.身法, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.加速等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 38924,
    uid: 38924,
    装备名称: '项昌囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.力道, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.加速等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
  {
    id: 38887,
    uid: 38887,
    装备名称: '北邱囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.身法, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.外功破防等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 38886,
    uid: 38886,
    装备名称: '曲郦囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.力道, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.外功破防等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功基础攻击 }],
  },
  {
    id: 38849,
    uid: 38849,
    装备名称: '渊忱囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.身法, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38848,
    uid: 38848,
    装备名称: '羡双囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.力道, 值: 551 },
      { 属性: 属性类型.外功基础攻击, 值: 1251 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38787,
    uid: 38787,
    装备名称: '塞尘囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.身法, 值: 512 },
      { 属性: 属性类型.外功基础攻击, 值: 1001 },
      { 属性: 属性类型.外功会心等级, 值: 4604 },
      { 属性: 属性类型.全能等级, 值: 1842 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38786,
    uid: 38786,
    装备名称: '峭月囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.力道, 值: 512 },
      { 属性: 属性类型.外功基础攻击, 值: 1001 },
      { 属性: 属性类型.外功会心等级, 值: 4604 },
      { 属性: 属性类型.全能等级, 值: 1842 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }],
  },
  {
    id: 38749,
    uid: 38749,
    装备名称: '奔枝囊',
    所属门派: '通用',
    装备主属性: '身法',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.身法, 值: 512 },
      { 属性: 属性类型.外功基础攻击, 值: 1001 },
      { 属性: 属性类型.外功破防等级, 值: 4604 },
      { 属性: 属性类型.全能等级, 值: 1842 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.身法 }],
  },
  {
    id: 38748,
    uid: 38748,
    装备名称: '环旌囊',
    所属门派: '通用',
    装备主属性: '力道',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.力道, 值: 512 },
      { 属性: 属性类型.外功基础攻击, 值: 1001 },
      { 属性: 属性类型.外功破防等级, 值: 4604 },
      { 属性: 属性类型.全能等级, 值: 1842 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.力道 }],
  },
]

export default 暗器装备数据
