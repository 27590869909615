import { App, Form, Input, message, Modal, Select, Tabs } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/hooks'
import 获取当前数据 from '@/数据/数据工具/获取当前数据'
import { 更新当前自定义循环列表 } from '@/store/data'
import { 心法枚举 } from '../tool'
import { 循环数据, 循环详情 } from '@/@types/循环'
import './index.css'
import { 获取心法数据 } from '@/心法模块'

const { 计算循环 } = 获取当前数据()

interface 保存循环弹窗类型 {
  导入循环保存弹窗: boolean
  设置导入循环保存弹窗: (e: boolean) => void
  结果数据: 循环详情 | undefined
  目标心法: string
  原始数据: string | undefined
}

function 保存循环弹窗(props: 保存循环弹窗类型) {
  const { modal } = App.useApp()

  const { 导入循环保存弹窗, 设置导入循环保存弹窗, 结果数据, 目标心法, 原始数据 } = props
  // 保存类型，覆盖｜新增
  const [导入循环类型, 设置导入循环类型] = useState<'覆盖' | '新增'>()
  // 导入循环名称保存输入
  const [导入循环名称输入, 设置导入循环名称输入] = useState<string>()
  // 保存类型，覆盖｜新增
  const [覆盖循环名, 设置覆盖循环名] = useState<string>()

  const 自定义循环列表 = useAppSelector((state) => state?.data?.自定义循环列表)

  const dispatch = useAppDispatch()

  const 全部循环 = useMemo(() => {
    return (计算循环 || []).concat(自定义循环列表)
  }, [自定义循环列表, 计算循环])

  useEffect(() => {
    if (导入循环保存弹窗) {
      if (自定义循环列表?.length) {
        设置导入循环类型('覆盖')
      } else {
        设置导入循环类型('新增')
      }
    } else {
      设置导入循环类型(undefined)
      设置导入循环名称输入(undefined)
      设置覆盖循环名(undefined)
    }
  }, [导入循环保存弹窗, 自定义循环列表])

  const 保存导入循环前 = () => {
    const 名称 = 导入循环类型 === '覆盖' ? 覆盖循环名 : 导入循环名称输入 || ''
    if (!名称) {
      message.error('请输入保存循环名称')
      return
    }

    if (导入循环类型 === '新增' && 全部循环?.find((item) => item?.名称 === 名称)) {
      message.error('该循环名称重复，请更换后再试')
      return
    }

    const 快照计算: any[] = 判断快照计算(目标心法, 结果数据)
    const 奇穴 = 判断奇穴(目标心法, 原始数据)

    if (!奇穴?.length) {
      return
    }

    const 保存循环: 循环数据 = {
      名称: 名称,
      标题: 名称,
      类型: '自定义',
      标记: '导入',
      快照计算,
      奇穴,
      循环详情: [结果数据 as any],
    }

    const 新自定义循环 = 自定义循环列表?.some((item) => item?.名称 === 名称)
      ? 自定义循环列表.map((item) => {
          return item.名称 === 名称 ? 保存循环 : item
        })
      : (自定义循环列表 || []).concat([保存循环])

    dispatch(更新当前自定义循环列表(新自定义循环))

    message.success('保存成功')
    设置导入循环保存弹窗(false)
  }

  // 删除导入循环
  const 删除循环前提示 = (e, 名称) => {
    e.stopPropagation()
    e.preventDefault()

    modal.confirm({
      title: `确定要删除循环【${名称}】吗?`,
      content: '删除后将无法恢复',
      okText: '我要删除',
      onOk: async () => {
        删除循环(名称)
      },
    })
  }

  const 删除循环 = (名称) => {
    dispatch(更新当前自定义循环列表(自定义循环列表.filter((item) => item.名称 !== 名称)))
  }

  return (
    <Modal
      centered
      title='保存导入循环'
      okButtonProps={{
        disabled: !(导入循环类型 === '覆盖' ? 覆盖循环名 : 导入循环名称输入),
      }}
      open={导入循环保存弹窗}
      onCancel={() => 设置导入循环保存弹窗(false)}
      onOk={保存导入循环前}
      width={630}
      destroyOnClose
      className={'jcl-import-cycle-save-modal'}
    >
      <Tabs
        className={'jcl-import-cycle-save-modal-content'}
        type='card'
        activeKey={导入循环类型}
        onChange={(e) => 设置导入循环类型(e as any)}
      >
        <Tabs.TabPane tab={'覆盖'} key='覆盖'>
          <Select
            value={覆盖循环名}
            onChange={设置覆盖循环名}
            optionLabelProp='label'
            placeholder={'请选择你要覆盖的循环'}
          >
            {自定义循环列表.map((item) => {
              return (
                <Select.Option value={item.名称} key={item.名称} label={item.名称}>
                  <div className={'jcl-import-cycle-save-label'}>
                    <span>{item.名称}</span>
                    <span
                      onClick={(e) => 删除循环前提示(e, item.名称)}
                      className={'jcl-import-cycle-save-delete'}
                    >
                      删除
                    </span>
                  </div>
                </Select.Option>
              )
            })}
          </Select>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'新增'} key='新增'>
          <Form>
            <Form.Item>
              <Input
                value={导入循环名称输入}
                placeholder='请输入导入循环名称'
                onChange={(e) => 设置导入循环名称输入(e?.target?.value)}
                maxLength={12}
              />
            </Form.Item>
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  )
}

export const 判断快照计算 = (目标心法, 结果数据) => {
  const 心法数据枚举 = 心法枚举[目标心法]
  const 套装Buff名称 = 心法数据枚举?.门派套装buff?.[0]
  const 快照列表: string[] = []
  const 数据字符串 = JSON.stringify(结果数据 || '{}')
  if (套装Buff名称) {
    if (数据字符串?.includes('套装Buff名称')) {
      快照列表.push('套装会心会效')
    }
  }
  if (数据字符串?.includes('大附魔')) {
    快照列表.push('大附魔')
  }
  if (数据字符串?.includes('风特效')) {
    快照列表.push('风特效')
  }
  if (数据字符串?.includes('水特效')) {
    快照列表.push('水特效')
  }

  return 快照列表
}

export const 判断奇穴 = (目标心法, 原始数据) => {
  const 原始数据对象 = JSON.parse(原始数据 || '{}')
  const { 奇穴数据 } = 获取心法数据(目标心法)

  const PlayersId = 原始数据对象?.players && Object.keys(原始数据对象?.players)?.[0]
  const 奇穴IDs = 原始数据对象?.select_talents && 原始数据对象?.select_talents?.[PlayersId]
  if (奇穴IDs?.length && 奇穴数据?.[0]?.奇穴列表?.[0]?.id) {
    const res: string[] = []
    let errorId = ''
    奇穴数据?.map((item, index) => {
      const 该层奇穴 = item?.奇穴列表 || []
      const 对应ID奇穴 = 该层奇穴?.find((a) => a?.id?.toString() === 奇穴IDs?.[index]?.toString())
      if (对应ID奇穴) {
        res.push(对应ID奇穴?.奇穴名称)
      } else {
        errorId = 奇穴IDs?.[index]
      }
    })
    if (errorId) {
      message.error(`存在未识别奇穴：${errorId}`)
      return []
    } else {
      return res
    }
  } else {
    message.error(`该心法暂未支持`)
    return []
  }
}

export default 保存循环弹窗
