import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'
// import 一阳指通用增益 from './通用增益/一阳指通用增益'

const 破穴增益: 技能增益列表类型[] = [
  ...通用增益,

  // 目前没吃出岫 加成
  // ...一阳指通用增益
]

export default 破穴增益
