import { 属性类型 } from '@/@types/属性'
import { 技能基础数据模型 } from '@/@types/技能'
import { 计算公式计算类型 } from '@/@types/伤害计算'
import { 获取破招实际系数 } from '@/数据/数据工具/获取技能系数'

import 通用增益 from './技能增益/通用增益/通用增益'
import 断脉增益 from './技能增益/断脉'
import 绝脉增益 from './技能增益/绝脉'
import 骤风增益 from './技能增益/骤风'
import 截阳增益 from './技能增益/截阳'
import 引窍增益 from './技能增益/引窍'
import 破穴增益 from './技能增益/破穴'
import 劈风增益 from './技能增益/劈风'

const 技能基础数据: 技能基础数据模型[] = [
  {
    技能名称: '云和扇法',
    伤害类型: '外功',
    技能伤害系数: 16,
    武器伤害系数: 1,
    技能增益列表: 断脉增益,
  },
  {
    技能ID: 38093,
    技能名称: '破', // 38093
    统计名称: '破',
    技能伤害系数: 获取破招实际系数(1048576 * (0.5 * (1 + 0.05 * 1) - 1)),
    技能增益列表: 通用增益,
    技能等级数据: {
      '1,52': {
        技能伤害系数: (i) => 获取破招实际系数(1048576 * (0.5 * (1 + 0.05 * i) - 1)),
      },
    },
  },
  {
    技能ID: 38084,
    技能名称: '断脉', // 38084
    技能伤害系数: 960,
    基础伤害_基础值: 20,
    技能增益列表: 断脉增益,
  },
  {
    技能ID: 38090,
    技能名称: '截阳', // 38090
    技能伤害系数: 1300,
    基础伤害_基础值: 20,
    技能增益列表: 截阳增益,
  },
  {
    技能ID: 38438,
    技能名称: '引窍', // 38438
    统计名称: '引窍',
    基础伤害_基础值: 20,
    技能增益列表: 引窍增益,
    技能伤害系数: 2500,
    技能等级数据: {
      '1,52': {
        技能伤害系数: (i) => 1000 * (1 + 0.03 * (i - 1)),
        // 点奇穴以后系数变成
        // 1000 * (1 + 0.05 * (dwSkillLevel - 1))
      },
    },
  },
  {
    技能ID: 38083,
    技能名称: '破穴', // 38083
    技能伤害系数: 480,
    基础伤害_基础值: 20,
    技能增益列表: 破穴增益,
  },
  {
    技能ID: 38531,
    技能名称: '封府', // 38531
    技能伤害系数: 240,
    基础伤害_基础值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 38554,
    技能名称: '朔风扬尘', // 38554
    统计名称: '朔风扬尘',
    技能伤害系数: 240,
    基础伤害_基础值: 1,
    基础伤害_浮动值: 1,
    技能增益列表: 通用增益,
    技能等级数据: {
      '1,8': {
        技能伤害系数: (i) => 240 * (1 + 0.5 * (i - 1)),
      },
    },
  },
  {
    技能ID: 38556,
    技能名称: '欺霜', // 38556
    统计名称: '欺霜',
    技能伤害系数: 260,
    基础伤害_基础值: 20,
    技能增益列表: 通用增益,
    技能等级数据: {
      '1,5': {
        技能伤害系数: (i) => 220 + i * 40,
      },
    },
  },
  {
    技能ID: 38557,
    技能名称: '催烟', // 38557
    技能伤害系数: 300,
    基础伤害_基础值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 38085,
    技能名称: '绝脉', // 38085
    统计名称: '绝脉',
    基础伤害_基础值: 20,
    技能伤害系数: 80, // 80 * dwSkillLevel
    技能增益列表: 绝脉增益,
    技能等级数据: {
      '1,11': {
        技能伤害系数: (i) => 80 * i,
      },
    },
  },
  {
    技能ID: '37804_1',
    技能名称: '骤风', // 37804/1
    统计名称: '骤风',
    技能伤害系数: 229.99999999999997,
    基础伤害_基础值: 20,
    技能增益列表: 骤风增益,
  },
  {
    技能ID: '37804_2',
    技能名称: '横驱风靡', // 37804/2
    统计名称: '横驱风靡',
    技能伤害系数: 459.99999999999994,
    基础伤害_基础值: 20,
    技能增益列表: 骤风增益,
  },
  {
    技能ID: 38016,
    技能名称: '雾刃飞光·雾', // 38016
    秘籍依赖技能: '雾刃飞光',
    统计名称: '雾刃飞光',
    技能伤害系数: 400,
    基础伤害_基础值: 10,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 38075,
    技能名称: '雾刃飞光·刃', // 38075
    秘籍依赖技能: '雾刃飞光',
    统计名称: '雾刃飞光',
    技能伤害系数: 440,
    基础伤害_基础值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 38076,
    技能名称: '雾刃飞光·飞', // 38076
    秘籍依赖技能: '雾刃飞光',
    统计名称: '雾刃飞光',
    技能伤害系数: 480,
    基础伤害_基础值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 38077,
    技能名称: '雾刃飞光·光', // 38077
    秘籍依赖技能: '雾刃飞光',
    统计名称: '雾刃飞光',
    技能伤害系数: 520,
    基础伤害_基础值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 37816,
    技能名称: '劈风刃', // 37816
    技能伤害系数: 940,
    基础伤害_基础值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 38452,
    技能名称: '劈风令·骤', // 38452
    技能伤害系数: 360,
    技能增益列表: 劈风增益,
  },
  {
    技能ID: 38447,
    技能名称: '抟风令', // 38447
    技能伤害系数: 240,
    基础伤害_基础值: 20,
    技能增益列表: 通用增益,
  },
  {
    技能ID: 38453,
    技能名称: '抟风令·爆', // 38453
    技能伤害系数: 256,
    基础伤害_基础值: 1220,
    技能增益列表: 通用增益,
  },
  // --------------
  {
    技能名称: '逐云寒蕊',
    技能伤害系数: 384,
    基础伤害_基础值: 40,
    基础伤害_浮动值: 17,
    伤害计算次数: 1,
    技能增益列表: [
      ...通用增益,
      {
        增益名称: '飘黄',
        增益所在位置: '技能',
        增益类型: '全局启用',
        增益启用: true,
        增益集合: [{ 属性: 属性类型.郭氏内功基础防御, 值: -1024 }],
      },
    ],
  },
  {
    // 伤腕
    技能名称: '昆吾·弦刃',
    真实伤害: 145300, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
    伤害计算类型标记: [计算公式计算类型.真实伤害, 计算公式计算类型.等级减伤, 计算公式计算类型.易伤],
  },
  {
    // 伤鞋
    技能名称: '刃凌',
    真实伤害: 96900, // 雾海寻龙版本改为固定伤害，只吃秋肃和等级减伤
    伤害计算类型标记: [计算公式计算类型.真实伤害, 计算公式计算类型.等级减伤, 计算公式计算类型.易伤],
  },
  {
    // 龙门武器
    技能名称: '剑风',
    基础伤害_基础值: 3950,
    技能增益列表: 通用增益,
  },
]

export default 技能基础数据

const 技能增益 = {
  通用增益,
  断脉增益,
  骤风增益,
  绝脉增益,
}

export { 技能增益 }
