import { 奇穴列表数据类型 } from '@/@types/奇穴'

const 奇穴数据: 奇穴列表数据类型[] = [
  {
    奇穴列表: [
      {
        奇穴名称: '海隅',
        奇穴图片: 'https://icon.jx3box.com/icon/10760.png',
        id: '20324',
        奇穴描述: '“击水三千”伤害提高10%，第三段招式附带1次击退效果。',
      },
      {
        奇穴名称: '江汉',
        奇穴图片: 'https://icon.jx3box.com/icon/10855.png',
        id: '20333',
        奇穴描述: '“木落雁归”会心几率提高10%，会心效果提高10%。',
      },
      {
        奇穴名称: '凌霄',
        奇穴图片: 'https://icon.jx3box.com/icon/10867.png',
        id: '20756',
        奇穴描述: '“浮游天地”伤害提高20%，能挑飞范围内敌对目标。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '苍梧',
        奇穴图片: 'https://icon.jx3box.com/icon/10882.png',
        id: '20716',
        奇穴描述: '“木落雁归”伤害提高10%，释放距离增加6尺。',
      },
      {
        奇穴名称: '扶桑',
        奇穴图片: 'https://icon.jx3box.com/icon/10768.png',
        id: '20335',
        奇穴描述: '“翼绝云天”伤害提升10%，每次命中目标回复自身4%内力最大值。',
      },
      {
        奇穴名称: '潮音',
        奇穴图片: 'https://icon.jx3box.com/icon/17166.png',
        id: '30504',
        奇穴描述: '“跃潮斩波”对距离超过8尺的目标施展，造成额外20%伤害。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '羽彰',
        奇穴图片: 'https://icon.jx3box.com/icon/10863.png',
        id: '20746',
        奇穴描述: '“翼绝云天”攻击期间，自身破防等级提高20%。',
      },
      {
        奇穴名称: '乘龙',
        奇穴图片: 'https://icon.jx3box.com/icon/10837.png',
        id: '20718',
        奇穴描述: '撑伞状态结束受到的伤害降低30%，自身免疫控制（击退和被拉除外），持续3秒。',
      },
      {
        奇穴名称: '澜升',
        奇穴图片: 'https://icon.jx3box.com/icon/16208.png',
        id: '28987',
        奇穴描述:
          '有击落效果的招式命中空中的目标，造成2段外功伤害；若成功击落空中的目标，造成的击倒效果减少至2秒, 额外造成1段最低最高外功伤害，目标距离地面距离越高造成的伤害越高。',
      },
      {
        奇穴名称: '驾鸾',
        奇穴图片: 'https://icon.jx3box.com/icon/10827.png',
        id: '25267',
        奇穴描述: '“振翅图南”调息时间降低5秒，施展时对目标区域立即造成一次伤害。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '清源',
        奇穴图片: 'https://icon.jx3box.com/icon/10764.png',
        id: '20348',
        奇穴描述:
          '“碧海缥缈掌”套路下伤害招式不再附带冲刺及控制效果，“物化天行”过程中施展时，降低“定波砥澜”“浮游天地”“物化天行”1秒调息时间。',
      },
      {
        奇穴名称: '遥思',
        奇穴图片: 'https://icon.jx3box.com/icon/10857.png',
        id: '20720',
        奇穴描述: '“溟海御波”变为2层充能技，伤害提高15%，施展过程中受到的伤害降低25%。',
      },
      {
        奇穴名称: '冥意',
        奇穴图片: 'https://icon.jx3box.com/icon/10760.png',
        id: '20721',
        奇穴描述:
          '“逐波灵游”攻击距离提高到20尺，并会对目标周围6尺内敌方额外5个目标造成伤害和控制效果，并使其5秒内无法跳跃。',
      },
      {
        奇穴名称: '倾波',
        奇穴图片: 'https://icon.jx3box.com/icon/18364.png',
        id: '32592',
        奇穴描述:
          '施展使自身移动15尺及以上的招式将引来浪潮环绕自身并延时1.5秒后对自身周围半径8尺内最多5个目标造成点外功伤害，在自身周围有浪潮时无法获得浪潮。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '澄穆',
        奇穴图片: 'https://icon.jx3box.com/icon/11849.png',
        id: '26745',
        奇穴描述:
          '“溟海御波”“海运南冥”调息时间降低1秒，并使“碧海缥缈掌”套路招式伤害提高10%，最多叠加3层，持续6秒。',
      },
      {
        奇穴名称: '兰棹',
        奇穴图片: 'https://icon.jx3box.com/icon/10884.png',
        id: '20722',
        奇穴描述:
          '“疾电叱羽”吸收的伤害提高到自身身法的80倍效果，且每秒卸除范围内队友阴性、混元性、阳性、毒性、点穴不利效果各一个，并回复其3%的最大气血值和内力值。',
      },
      {
        奇穴名称: '游仙',
        奇穴图片: 'https://icon.jx3box.com/icon/10913.png',
        id: '30912',
        奇穴描述:
          '“物化天行”移动至目标位置所需的时间降低，施展“浮游天地”后获得5秒内额外施展一次“物化天行”的机会。',
      },
      {
        奇穴名称: '海碧',
        奇穴图片: 'https://icon.jx3box.com/icon/18294.png',
        id: '32488',
        奇穴描述:
          '处于非浮空状态下“碧海飘渺掌”命中目标3次会使得下一次在“物化天行”过程中“溟海御波”或“海运南冥”首次命中目标返还招式6秒调息时间，并在自身位置生成4秒的“驰风震域”。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '青冥',
        奇穴图片: 'https://icon.jx3box.com/icon/14108.png',
        id: '25272',
        奇穴描述:
          '每次施展“翼绝云天”攻击期间，“击水三千”命中被攻击目标会使其获得4秒的“破绽”，自身攻击有“破绽”的目标将造成外功伤害，“破绽”结束后根据期间被攻击次数叠加最多6层的持续伤害，使目标每2秒受到点外功伤害，持续12秒。',
      },
      {
        奇穴名称: '遨宇',
        奇穴图片: 'https://icon.jx3box.com/icon/10876.png',
        id: '20363',
        奇穴描述:
          '施展“逸尘步虚”后降低“物化天行”5秒调息时间，并增加自身10%减伤，最多3层，持续10秒。',
      },
      {
        奇穴名称: '游心',
        奇穴图片: 'https://icon.jx3box.com/icon/10643.png',
        id: '20350',
        奇穴描述:
          '施展“逸尘步虚”立即回复6%气血值。若成功闪避招式，则使自身“碧海缥缈掌”伤害提高20%并回复2%的气血值，最多触发3次。',
      },
      {
        奇穴名称: '征夷',
        奇穴图片: 'https://icon.jx3box.com/icon/10771.png',
        id: '20700',
        奇穴描述:
          '“逸尘步虚”释放后使自身无法被选中，并在自身位置生成持续3秒的“伞影”。若场上存在两把“伞影”且距离小于20尺，则使“伞影”伤害立即生效并对其路径上最多10个目标造成点外功伤害随即将目标拉向空中（使击倒目标浮空则高度提升2尺），伞影浮空使目标无法施展轻功招式1秒。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '惊潮',
        奇穴图片: 'https://icon.jx3box.com/icon/17167.png',
        id: '30502',
        奇穴描述:
          '“跃潮斩波”会标记目标，持续3秒，标记结束时对目标造成一次外功伤害，若标记结束时目标在自身“驰风震域”内则造成外功伤害。',
      },
      {
        奇穴名称: '鸿轨',
        奇穴图片: 'https://icon.jx3box.com/icon/10917.png',
        id: '20751',
        奇穴描述:
          '8秒内连续对同一目标施展2次物化天行后，使自身外功会心几率提高15%，外功会心效果提高20%，持续8秒，并返还“海运南冥”“溟海御波”5秒调息时间。',
      },
      {
        奇穴名称: '奋翼',
        奇穴图片: 'https://icon.jx3box.com/icon/10841.png',
        id: '20729',
        奇穴描述:
          '对击倒状态的目标伤害提高20%。若自身成功击倒目标，则在目标身后最远6尺位置生成“伞影”（每0.25秒最多生成一个）。',
      },
      {
        奇穴名称: '凝气',
        奇穴图片: 'https://icon.jx3box.com/icon/10813.png',
        id: '20694',
        奇穴描述: '施展“逸尘步虚”时返还3秒“跃潮斩波”调息时间。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '怅归',
        奇穴图片: 'https://icon.jx3box.com/icon/10840.png',
        id: '20730',
        奇穴描述:
          '“定波砥澜”变为2层充能招式，充能时间13秒，不再具有防守反击效果，变为蓄力范围攻击，根据蓄力时间生成一个“驰风震域”的浪潮并造成（0.575*破招系数*破招值）到（1.265*破招系数*破招值）点破招伤害，最多持续8秒。',
      },
      {
        奇穴名称: '崔嵬',
        奇穴图片: 'https://icon.jx3box.com/icon/10875.png',
        id: '20753',
        奇穴描述:
          '“定波砥澜”不再具有防守反击效果，3秒内减伤增加到80%并免疫控制和恐惧效果，期间每受到一次攻击降低2秒调息时间。',
      },
      {
        奇穴名称: '驾澜',
        奇穴图片: 'https://icon.jx3box.com/icon/16207.png',
        id: '28985',
        奇穴描述:
          '若自身气血值低于1%，则召唤青冥在自身位置释放“疾电叱羽”保护自身，并使青冥带领自身脱离险境并解除控制。该效果每3分钟最多触发一次。',
      },
      {
        奇穴名称: '跃潮',
        奇穴图片: 'https://icon.jx3box.com/icon/10864.png',
        id: '20757',
        奇穴描述: '“跃潮斩波”命中目标使目标受到的疗伤成效降低50%，持续8秒。',
      },
      {
        奇穴名称: '烟涛',
        奇穴图片: 'https://icon.jx3box.com/icon/14112.png',
        id: '25270',
        奇穴描述:
          '“定波砥澜”变为3秒蓄力招式，期间减伤40%并免疫控制和恐惧效果。蓄力后释放对8尺范围敌方目标造成最高外功伤害。期间若被攻击或蓄力1.5至2秒期间释放，反击附带5秒击倒效果并在原地生成“振翅图南”飓风。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '藏锋',
        奇穴图片: 'https://icon.jx3box.com/icon/10851.png',
        id: '20351',
        奇穴描述: '“浮游天地”变为2层充能招式，充能时间21秒。',
      },
      {
        奇穴名称: '溯徊',
        奇穴图片: 'https://icon.jx3box.com/icon/10869.png',
        id: '21293',
        奇穴描述: '在非浮空姿态下，施展“跃潮斩波”命中目标后，形成一个“驰风震域”的浪潮。',
      },
      {
        奇穴名称: '横绝',
        奇穴图片: 'https://icon.jx3box.com/icon/10751.png',
        id: '20759',
        奇穴描述:
          '“驭羽骋风”充能时间降低10秒，可对目标范围施展，快速持雕冲刺至目标位置，至少冲刺8尺，冲刺过程中无法被选中，3秒内可施展二段技能，快速回到冲刺前位置，对路径上敌人造成外功伤害，并眩晕2秒。',
      },
      {
        奇穴名称: '倾盖',
        奇穴图片: 'https://icon.jx3box.com/icon/10874.png',
        id: '20365',
        奇穴描述:
          '“驭羽骋风”充能时间增加10秒，能对自身或友方目标施展，并解除目标受控状态（击倒和被击僵直除外），指挥海雕立即把队友带离险境带到自身位置。',
      },
      {
        奇穴名称: '风驰',
        奇穴图片: 'https://icon.jx3box.com/icon/18293.png',
        id: '32476',
        奇穴描述:
          '不处于浮空状态生成“驰风震域”返还“碧海飘渺掌”套路招式2秒调息时间。若在自身的“驰风震域”中生成“驰风震域”，会引爆已有“驰风震域”并对6尺内最多5个敌方目标造成点外功伤害。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '驰行',
        奇穴图片: 'https://icon.jx3box.com/icon/10753.png',
        id: '20374',
        奇穴描述:
          '施展“振翅图南”形成的飓风持续时间增加2秒，处于自身飓风效果范围内，施展“碧海缥缈掌”套路下招式伤害提高30%。',
      },
      {
        奇穴名称: '神降',
        奇穴图片: 'https://icon.jx3box.com/icon/10918.png',
        id: '20758',
        奇穴描述:
          '“物化天行”过程中自身伤害提高10%，每次使用“碧海飘渺掌”套路招式使自身下一个“跃潮斩波”会心几率提高10%，会心效果提高10%，效果最多叠加5层。',
      },
      {
        奇穴名称: '空桑舞瑟',
        奇穴图片: 'https://icon.jx3box.com/icon/10766.png',
        id: '20641',
        奇穴描述:
          '命令雕猛扑目标，对其周围4尺内最多5个敌对目标造成点外功伤害，并击倒3秒。若目标距离地面超过8尺则击落到地面3秒并额外造成外功伤害。',
      },
      {
        奇穴名称: '翔极碧落',
        奇穴图片: 'https://icon.jx3box.com/icon/10810.png',
        id: '20065',
        奇穴描述: '命令海雕猛扑目标，造成点外功伤害，若其正在运功，则将运功打断并使其沉默4秒。',
      },
      {
        奇穴名称: '镜壁',
        奇穴图片: 'https://icon.jx3box.com/icon/13408.png',
        id: '25276',
        奇穴描述:
          '施展“振翅图南”，形成的飓风范围提高2尺，立即对目标前方矩形范围造成一次击退效果。海雕会在“振翅图南”边缘盘旋，目标被命中时若处于“振翅图南”边缘，则会被吸引回“振翅图南”中心位置。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '梦悠',
        奇穴图片: 'https://icon.jx3box.com/icon/10859.png',
        id: '20747',
        奇穴描述:
          '施展招式无视目标30%防御，生成“驰风震域”的时间增加2秒，自身处于“驰风震域”中施展招式额外无视目标20%防御。',
      },
      {
        奇穴名称: '无涯',
        奇穴图片: 'https://icon.jx3box.com/icon/10881.png',
        id: '20372',
        奇穴描述:
          '“驰风震域”持续时间减少4秒，使处于“驰风震域”外的目标，对处于“驰风震域”内的目标无法施展招式。',
      },
      {
        奇穴名称: '穹庐',
        奇穴图片: 'https://icon.jx3box.com/icon/10852.png',
        id: '20741',
        奇穴描述: '施展“驭羽骋风”清除全部“浮游天地”调息时间。',
      },
      {
        奇穴名称: '鸿渐于飞',
        奇穴图片: 'https://icon.jx3box.com/icon/10842.png',
        id: '20601',
        奇穴描述:
          '一段冲向目标把目标周围敌对目标挑飞造成100%武器伤害外加外功伤害，二段击落自身前方目标造成100%武器伤害外加外功伤害并在目标位置生成4秒的“驰风震域”，三段对前方目标造成100%武器伤害外加外功伤害，命中倒地状态目标目标则该次伤害提高50%。',
      },
      {
        奇穴名称: '连涯',
        奇穴图片: 'https://icon.jx3box.com/icon/10919.png',
        id: '25283',
        奇穴描述:
          '“逸尘步虚”变为3层充能招式，充能时间12秒，施展后使下一个“跃潮斩波”附带3秒击落效果。',
      },
    ],
  },
  {
    是否不可编辑: true,
    奇穴列表: [
      {
        奇穴名称: '罔象',
        奇穴图片: 'https://icon.jx3box.com/icon/10752.png',
        id: '36846',
        奇穴描述: '“振翅图南”会心几率提高10%，会心效果提高10%，命中目标数量由6个提升至10个。',
      },
      {
        奇穴名称: '濯流',
        奇穴图片: 'https://icon.jx3box.com/icon/10824.png',
        id: '20701',
        奇穴描述:
          '“振翅图南”对非侠士目标的伤害提高150%。在自身“驰风震域”的浪潮中施展“浮游天地”后会生成“振翅图南”的飓风，持续5秒。',
      },
      {
        奇穴名称: '怒翼',
        奇穴图片: 'https://icon.jx3box.com/icon/18363.png',
        id: '32594',
        奇穴描述:
          '在自身的“振翅图南”飓风中施展“浮游天地”完成浮空后，会额外引来一只海雕协同自身攻击，8秒内自身每次对目标造成伤害都会额外追击点外功伤害。',
      },
      {
        奇穴名称: '澹然若海',
        奇穴图片: 'https://icon.jx3box.com/icon/10858.png',
        id: '20837',
        奇穴描述:
          '对地上猛击一掌，激起千层浪震飞对手，造成点外功伤害，并会在原地出现水龙卷，随机跟踪被击中的目标，威力巨大，范围内减速70%，并被击浮空，持续6秒。自身在水龙卷内施展“碧海飘渺掌”命中目标会使水龙卷造成的伤害提高25%，最多提高3次。',
      },
      {
        奇穴名称: '飞倾列缺',
        奇穴图片: 'https://icon.jx3box.com/icon/14113.png',
        id: '25285',
        奇穴描述:
          '自身获得5秒“逸尘”效果，在目标区域生成伞影，对目标区域最多3个目标造成点外功伤害并定身，持续1秒。随即伞影浮空对下方12尺范围最多3个目标造成点外功伤害并拉向空中，期间召唤海雕对伞影周围8尺范围内最多3个目标持续造成5次点外功伤害，自身击落目标则海雕会继续追击目标，“伞影”浮空和海雕的攻击均使其无法施展轻功招式，持续1秒。',
      },
    ],
  },
]

export default 奇穴数据
