export default {
  装备基础属性: {
    基础攻击: 30947,
    破防等级: 41510,
    无双等级: 29773,
    会心等级: 28195,
    会心效果等级: 5353,
    全能等级: 0,
    武器伤害_最小值: 4287,
    武器伤害_最大值: 6706,
    破招值: 8192,
    力道: 7347,
    元气: 44,
    身法: 44,
    根骨: 44,
    体质: 36991,
    基础气血上限: 52285,
    额外气血上限: 0,
    加速等级: 4499,
    装分: 292422,
  },
  装备列表: [
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrengthBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 98338,
      装备部位: '帽子',
      附魔: '攻击+439',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsCriticalDamagePowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 98368,
      装备部位: '衣服',
      附魔: '无双+291',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalStrike',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 98278,
      装备部位: '腰带',
      附魔: '无双+291',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atSurplusValueBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 3,
      id: 98455,
      装备部位: '护腕',
      附魔: '破防+974',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsCriticalDamagePowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 3,
      id: 98465,
      装备部位: '下装',
      附魔: '破防+974',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 98308,
      装备部位: '鞋子',
      附魔: '加速+974',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrainBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 39818,
      装备部位: '项链',
      附魔: '体质+361',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 4,
      id: 39854,
      装备部位: '腰坠',
      附魔: '体质+361',
    },
    {
      镶嵌孔数组: [],
      当前精炼等级: 3,
      id: 39862,
      装备部位: '戒指',
      附魔: '攻击+439',
    },
    {
      镶嵌孔数组: [],
      当前精炼等级: 3,
      id: 39862,
      装备部位: '戒指',
      附魔: '攻击+439',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atStrengthBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 6,
      id: 37181,
      装备部位: '暗器',
      附魔: '破防+974',
    },
    {
      镶嵌孔数组: [
        {
          镶嵌类型: 'atPhysicsAttackPowerBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atStrengthBase',
          镶嵌宝石等级: 8,
        },
        {
          镶嵌类型: 'atPhysicsOvercomeBase',
          镶嵌宝石等级: 8,
        },
      ],
      当前精炼等级: 4,
      id: 37251,
      装备部位: '武器',
      附魔: '武伤+658',
    },
  ],
  五彩石: '彩·无双·斩铁·痛击(陆)',
  装备增益: {
    大附魔_伤帽: 1,
    大附魔_伤衣: 1,
    大附魔_伤腰: 1,
    大附魔_伤腕: 1,
    大附魔_伤鞋: 1,
    风特效腰坠_英雄: true,
    水特效武器_英雄: true,
    套装会心会效: true,
    套装技能: 1,
    切糕会心: false,
    切糕无双: false,
    切糕会心_英雄: false,
    切糕无双_英雄: false,
    冬至套装: false,
  },
}
