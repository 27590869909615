import { 技能增益列表类型 } from '@/@types/技能'
import 通用增益 from './通用增益/通用增益'
import 一阳指通用增益 from './通用增益/一阳指通用增益'
import { 属性类型 } from '@/@types/属性'

const 引窍增益: 技能增益列表类型[] = [
  ...通用增益,
  ...一阳指通用增益,
  {
    增益名称: '涌泉',
    增益所在位置: '奇穴',
    增益类型: '全局启用',
    增益集合: [{ 属性: 属性类型.系数置换函数, 值: (i) => 1000 * (1 + 0.05 * (i - 1)) }],
  },
]

export default 引窍增益
