import { 奇穴列表数据类型 } from '@/@types/奇穴'

const 奇穴数据: 奇穴列表数据类型[] = [
  {
    奇穴列表: [
      {
        奇穴名称: '烟霞',
        奇穴图片: 'https://icon.jx3box.com/icon/4219.png',
        id: '5756',
        奇穴描述: '“阳明指”的会心几率提高10%，会心效果提高10%。',
      },
      {
        奇穴名称: '放歌',
        奇穴图片: 'https://icon.jx3box.com/icon/3015.png',
        id: '32484',
        奇穴描述: '每次恢复墨意值时，额外恢复2点。',
      },
      {
        奇穴名称: '少阳指',
        奇穴图片: 'https://icon.jx3box.com/icon/1517.png',
        id: '18271',
        奇穴描述: '施展“商阳指”使目标移动速度降低60%，持续4秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '青冠',
        奇穴图片: 'https://icon.jx3box.com/icon/4492.png',
        id: '32489',
        奇穴描述:
          '自身持续伤害被吞噬时伤害提升25%。“芙蓉并蒂”调息时间受自身加速影响，命中后使下一次“玉石俱焚”吞噬的持续伤害额外提升75%。增伤效果只对非侠士目标生效。',
      },
      {
        奇穴名称: '飞白',
        奇穴图片: 'https://icon.jx3box.com/icon/17211.png',
        id: '30596',
        奇穴描述:
          '“玉石俱焚”命中时不再吞噬目标身上属于自己的持续伤害，而是使其剩余伤害提升80%。目标身上每存在一个自身的持续性伤害效果，则造成额外的混元伤害。',
      },
      {
        奇穴名称: '定式黑白',
        奇穴图片: 'https://icon.jx3box.com/icon/16219.png',
        id: '5751',
        奇穴描述:
          '施展“玉石俱焚”命中目标后会在目标10尺范围内随机落下一枚黑子/白子，棋子持续8秒。“玉石俱焚”每吞噬一个持续伤害则会延迟额外生成一个棋子。10尺内两枚异色子互相引爆，对10尺范围内最多5个目标造成点混元内功伤害。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '列宿',
        奇穴图片: 'https://icon.jx3box.com/icon/3015.png',
        id: '5759',
        奇穴描述: '战斗中受击使自身获得一层“春泥护花”。该效果每3秒可触发一次。',
      },
      {
        奇穴名称: '风烟翠',
        奇穴图片: 'https://icon.jx3box.com/icon/3016.png',
        id: '5774',
        奇穴描述:
          '“阳明指”命中附带自身“商阳指”“钟林毓秀”的目标，可将该效果添加给目标半径8尺内的最多5个目标。若“玉石俱焚”成功吞噬目标身上属于自己的持续伤害，则可以吞噬目标半径8尺内额外5个目标身上属于自身的持续伤害。',
      },
      {
        奇穴名称: '倚天',
        奇穴图片: 'https://icon.jx3box.com/icon/7209.png',
        id: '17510',
        奇穴描述:
          '若目标存在自身的“兰摧玉折”不利状态，则无视目标10%内功防御等级且每跳恢复自身0.75%内力值。',
      },
      {
        奇穴名称: '清风垂露',
        奇穴图片: 'https://icon.jx3box.com/icon/1523.png',
        id: '133',
        奇穴描述:
          '为友方目标卸除阳性不利效果、阴性不利效果、混元不利效果、毒性不利效果、点穴不利效果、持续伤害不利效果各一个。“离经易道”心法下返还5秒调息时间。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '束发',
        奇穴图片: 'https://icon.jx3box.com/icon/4573.png',
        id: '5771',
        奇穴描述: '“兰摧玉折”持续期间额外降低目标10%化劲等级。',
      },
      {
        奇穴名称: '墨海临源',
        奇穴图片: 'https://icon.jx3box.com/icon/21978.png',
        id: '37267',
        奇穴描述:
          '被动效果：“阳明指”“玉石俱焚”命中时，获得“点染”最多8个。&lt;br/&gt;主动效果：恢复20墨意，对目标造成无质混元伤害（无质伤害无法会心，但伤害受到会心、会心效果加成）。施展招式时若“点染”不少于5个，则消耗5个“点染”使该招式造成的伤害提升150%。对侠士及其召唤物造成伤害降低75%。',
      },
      {
        奇穴名称: '青歌',
        奇穴图片: 'https://icon.jx3box.com/icon/2999.png',
        id: '5762',
        奇穴描述:
          '“快雪时晴”会心提高20%，会心效果提高20%。自身“玉石俱焚”命中后，15秒内下一次“快雪时晴”可在移动中施展。',
      },
      {
        奇穴名称: '厥阴指',
        奇穴图片: 'https://icon.jx3box.com/icon/1513.png',
        id: '183',
        奇穴描述:
          '若目标正在运功则进行打断并造成点混元性内功伤害，并使其无法施放内功招式，持续4秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '皓空',
        奇穴图片: 'https://icon.jx3box.com/icon/20083.png',
        id: '36096',
        奇穴描述:
          '“星楼月影”效果结束后，恢复自身剩余护盾值的等额气血值，并获得15%可叠加的受到伤害降低效果，持续8秒，效果持续时间内，自身受到的“锁足”“定身”“眩晕”“击倒”控制效果持续时间降低20%。',
      },
      {
        奇穴名称: '折花',
        奇穴图片: 'https://icon.jx3box.com/icon/11823.png',
        id: '21744',
        奇穴描述:
          '“阳明指”变为3层充能招式，充能时间6秒。命中后吞噬目标身上剩余时间最长的持续伤害效果。自身持续伤害被吞噬后，使下一个对应持续伤害招式附带混元伤害。',
      },
      {
        奇穴名称: '踏莲',
        奇穴图片: 'https://icon.jx3box.com/icon/7252.png',
        id: '14635',
        奇穴描述: '“芙蓉并蒂”伤害提高100%，调息时间降低10秒，定身效果持续时间延长1秒。',
      },
      {
        奇穴名称: '晴昼',
        奇穴图片: 'https://icon.jx3box.com/icon/3001.png',
        id: '25951',
        奇穴描述: '“乱洒青荷”变为2层充能招式，充能时间1分35秒。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '雪中行',
        奇穴图片: 'https://icon.jx3box.com/icon/4519.png',
        id: '32477',
        奇穴描述:
          '“快雪时晴”每0.6秒造成一次伤害，持续3秒。“快雪时晴”每跳使自身获得“溅玉”气劲，每层使自身“快雪时晴”伤害提升17%，基础攻击力提升10%，会心提升6%，最多3层。施展“玉石俱焚”后会清除所有“溅玉”气劲。',
      },
      {
        奇穴名称: '随墨',
        奇穴图片: 'https://icon.jx3box.com/icon/18309.png',
        id: '32638',
        奇穴描述:
          '战斗中累计受到20%最大气血值的伤害时，将强化自身笔锋，使下一个“阳明指”命中目标时泼墨，造成额外伤害。短暂延迟后将附带“兰摧玉折”“钟林毓秀”“商阳指”中缺少的一个，随后重新记录伤害。',
      },
      {
        奇穴名称: '游斤',
        奇穴图片: 'https://icon.jx3box.com/icon/20082.png',
        id: '36017',
        奇穴描述:
          '“芙蓉并蒂”命中敌对侠士后，在其身上放置一个天工铁颅，使其负重缓行，持续5秒。期间目标被自身“玉石俱焚”命中触发吞噬时，铁颅会产生一次共鸣爆破，对目标及其附近6尺最多5名敌对目标造成点无质混元伤害（无质伤害无法会心，但伤害受到会心、会心效果加成）。',
      },
      {
        奇穴名称: '生风',
        奇穴图片: 'https://icon.jx3box.com/icon/11824.png',
        id: '23945',
        奇穴描述: '“钟林毓秀”“兰摧玉折”会心几率提高10%，可以在移动中施展。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '寒笙',
        奇穴图片: 'https://icon.jx3box.com/icon/11827.png',
        id: '21736',
        奇穴描述:
          '“商阳指”伤害提高10%，效果持续时间增加至24秒，持续时间增加效果无法与同类效果叠加。',
      },
      {
        奇穴名称: '清流',
        奇穴图片: 'https://icon.jx3box.com/icon/3017.png',
        id: '16855',
        奇穴描述: '“兰摧玉折”调息时间降低4秒。自身对“兰摧玉折”标记目标伤害提升10%。',
      },
      {
        奇穴名称: '弹梦',
        奇穴图片: 'https://icon.jx3box.com/icon/4501.png',
        id: '5770',
        奇穴描述: '主动施展的“春泥护花”不受被击消耗和卸除影响，持续12秒。',
      },
      {
        奇穴名称: '春寒',
        奇穴图片: 'https://icon.jx3box.com/icon/405.png',
        id: '18258',
        奇穴描述: '“乱洒青荷”调息时间降低25秒，持续期间自身会心率提高15%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '秋毫',
        奇穴图片: 'https://icon.jx3box.com/icon/3007.png',
        id: '17565',
        奇穴描述: '“清心静气”调息时间增加至30秒，施展后为友方目标添加7层“毫针”效果。',
      },
      {
        奇穴名称: '如馥',
        奇穴图片: 'https://icon.jx3box.com/icon/19178.png',
        id: '34573',
        奇穴描述:
          '自身拥有的“春泥护花”最后一层被击破时，根据击破者运转的心法内功类型，使自身受到该类型的伤害额外降低15%，自身混元内功伤害提升7%，持续10秒。',
      },
      {
        奇穴名称: '金屋',
        奇穴图片: 'https://icon.jx3box.com/icon/4546.png',
        id: '6685',
        奇穴描述: '施展“太阴指”后，受到的伤害降低30%，持续8秒。',
      },
      {
        奇穴名称: '春脉',
        奇穴图片: 'https://icon.jx3box.com/icon/1516.png',
        id: '18259',
        奇穴描述:
          '“玉石俱焚”每吞噬目标身上一个自身的持续伤害效果，则卸除目标混元、阳性、阴性、毒性有益气劲各一个。',
      },
      {
        奇穴名称: '钟灵',
        奇穴图片: 'https://icon.jx3box.com/icon/4555.png',
        id: '26692',
        奇穴描述:
          '施展“玉石俱焚”触发吞噬时造成混元伤害，下一运功招式无需运功且消耗20墨意，自身18秒内基础攻击力提升10%、破招提升10%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '兰舟',
        奇穴图片: 'https://icon.jx3box.com/icon/3016.png',
        id: '6686',
        奇穴描述:
          '混元性持续伤害提高5%，持续伤害效果被卸除后，每个持续伤害使目标1.5秒内无法受到治疗效果，最多叠加4.5秒。',
      },
      {
        奇穴名称: '流离',
        奇穴图片: 'https://icon.jx3box.com/icon/3002.png',
        id: '6682',
        奇穴描述:
          '每次消耗墨意，使下一次“玉石俱焚”命中后恢复20墨意并附带“兰摧玉折”持续伤害效果。每吞噬一个持续伤害效果，调息时间降低1.5秒。',
      },
      {
        奇穴名称: '碎玉',
        奇穴图片: 'https://icon.jx3box.com/icon/13441.png',
        id: '26694',
        奇穴描述:
          '施展“商阳指”/“阳明指”，有50%/100%几率在目标位置落下一枚黑子/白子，持续8秒。10尺内两枚异色子互相引爆，对10尺范围内最多5个目标造成点混元内功伤害。',
      },
      {
        奇穴名称: '守拙',
        奇穴图片: 'https://icon.jx3box.com/icon/11826.png',
        id: '28464',
        奇穴描述:
          '受到控制时可以对8尺内敌对目标施展，唤出“阿甘贰型”，将敌方目标关入“阿甘贰型”体内。期间“阿甘贰型”不可移动，持续4秒。',
      },
      {
        奇穴名称: '丹鼎',
        奇穴图片: 'https://icon.jx3box.com/icon/2999.png',
        id: '24912',
        奇穴描述:
          '“快雪时晴”不会被打退打断，期间免疫控制和封内效果(击退和被拉除外)，调息时间增加至25秒，伤害提高120%且额外附带2跳伤害。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '丹青',
        奇穴图片: 'https://icon.jx3box.com/icon/17187.png',
        id: '30650',
        奇穴描述:
          '“玉石俱焚”会在目标8尺区域产生墨池持续6秒，墨池每2秒浸染其中最多5个目标造成点伤害并减速50%，若墨池浸染的目标有自身持续伤害，则对其造成该持续伤害的一跳伤害。同时画出雨墨雕，延迟1.5秒后对墨池内最多5个敌方目标造成混元伤害。',
      },
      {
        奇穴名称: '活络',
        奇穴图片: 'https://icon.jx3box.com/icon/399.png',
        id: '37065',
        奇穴描述:
          '“水月无间”变为两层充能招式，充能时间50秒。“水月无间”免控效果期间，使自身免疫缴械和无法运功效果的影响，且运功不会被打断。',
      },
      {
        奇穴名称: '清湛',
        奇穴图片: 'https://icon.jx3box.com/icon/19177.png',
        id: '34564',
        奇穴描述:
          '“碧水滔天”会消耗所有墨意。每消耗20点墨意，使“芙蓉并蒂”“玉石俱焚”“星楼月影”“春泥护花”“水月无间”中随机一个招式额外施展一次，最多三个，持续20秒。',
      },
      {
        奇穴名称: '雪弃',
        奇穴图片: 'https://icon.jx3box.com/icon/2999.png',
        id: '32480',
        奇穴描述:
          '“快雪时晴”每跳使主目标每4秒受到38点伤害，持续28秒，最多叠加6层。完整运功“快雪时晴”时额外叠加1层。若主目标不是侠士，则“快雪时晴”对其伤害提升50%，并使附带持续伤害可以被吞噬。该持续伤害效果不受“快雪时晴”秘籍影响。',
      },
      {
        奇穴名称: '空山',
        奇穴图片: 'https://icon.jx3box.com/icon/2998.png',
        id: '24914',
        奇穴描述:
          '“听风吹雪”可以对敌方侠士施展，运功1秒，将自身与敌方侠士当前气血值百分比互换，互换后目标气血值不会低于50%，调息时间1分钟。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '焚玉',
        奇穴图片: 'https://icon.jx3box.com/icon/411.png',
        id: '32469',
        奇穴描述:
          '自身持续伤害被吞噬时，该效果剩余跳数每有1跳，其触发的“破招”伤害增加20%，最多增加120%。',
      },
      {
        奇穴名称: '故幽',
        奇穴图片: 'https://icon.jx3box.com/icon/11828.png',
        id: '26669',
        奇穴描述:
          '“芙蓉并蒂”及其附带的刷新持续伤害会心几率提高15%，会心效果提高15%，持续效果期间目标受到伤害提高10%。',
      },
      {
        奇穴名称: '望月',
        奇穴图片: 'https://icon.jx3box.com/icon/1520.png',
        id: '14634',
        奇穴描述: '“星楼月影”调息时间降低3秒，期间自身御劲提高5%，持续时间增加1秒。',
      },
      {
        奇穴名称: '轻鸿',
        奇穴图片: 'https://icon.jx3box.com/icon/1515.png',
        id: '18268',
        奇穴描述: '“太阴指”变为2层充能技能，充能时间17秒，但无法被“春泥护花”重置调息时间。',
      },
      {
        奇穴名称: '乘墨由心',
        奇穴图片: 'https://icon.jx3box.com/icon/14145.png',
        id: '24916',
        奇穴描述:
          '挥洒墨圈对自身周围6尺范围最多5个敌对侠士造成混元内功伤害，并沾染上墨滴，持续6秒。沾染上墨滴的目标施展招式命中目标几率降低80%。',
      },
    ],
  },
  {
    奇穴列表: [
      {
        奇穴名称: '涓流',
        奇穴图片: 'https://icon.jx3box.com/icon/7468.png',
        id: '14643',
        奇穴描述:
          '施展伤害招式命中目标，使自身会心几率提高1.6%，会心效果提高1.6%，每次造成伤害时叠加一层，叠加至10层后重置。',
      },
      {
        奇穴名称: '翰墨永誓',
        奇穴图片: 'https://icon.jx3box.com/icon/17210.png',
        id: '30737',
        奇穴描述:
          '“水月无间”释放后使自身获得5层“挥毫墨意”效果，加速提升15%。每次释放混元伤害技能将消耗一层并书写文字，当书写结束后将激活二段技能“翰墨永誓”：使目标无法受到伤害及治疗，持续8秒，该状态将在目标被“阳明指”“玉石俱焚”造成伤害之前结束。对友方施展时持续时间减半。',
      },
      {
        奇穴名称: '天工甲士',
        奇穴图片: 'https://icon.jx3box.com/icon/16223.png',
        id: '28465',
        奇穴描述:
          '持续运功5秒组装天工甲士，随后激活二段招式“天工”操纵天工甲士，每次操纵时间最多持续30秒。天工甲士无法受到治疗，其气血值耗尽时会损坏并使操纵者脱离。当场上存在自身损坏的天工甲士时，“天工甲士”无需运功并使其自我修复5秒。若主动离开天工甲士则记录离开时天工甲士的气血值，25秒后可以再次使用“天工”并继承离开时天工甲士的气血值。',
      },
      {
        奇穴名称: '南风吐月',
        奇穴图片: 'https://icon.jx3box.com/icon/7510.png',
        id: '14965',
        奇穴描述:
          '可对团队友方目标施展，清除其所有移动限制和不利效果且不会受到攻击但效果期间无法移动，持续8秒。',
      },
      {
        奇穴名称: '洛川神韵',
        奇穴图片: 'https://icon.jx3box.com/icon/14146.png',
        id: '24919',
        奇穴描述:
          '挥洒墨笔，对目标侠士造成混元内功伤害，并溅射其周围10尺内的一位敌方侠士造成混元内功伤害，使其被墨迹相连，持续10秒。墨迹相连期间，两者也会同样受到对方受到的伤害的20%。若两者相距超过12尺则墨迹炸裂，造成混元内功伤害并且无法施展招式，持续4秒。',
      },
    ],
  },
]

export default 奇穴数据
