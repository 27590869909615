import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备类型枚举 } from '@/@types/装备'

const 暗器装备数据: 装备属性信息模型[] = [
  {
    id: 39359,
    uid: 39359,
    装备名称: '令仪囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.元气, 值: 696 },
      { 属性: 属性类型.内功基础攻击, 值: 1758 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39358,
    uid: 39358,
    装备名称: '素溱囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.根骨, 值: 696 },
      { 属性: 属性类型.内功基础攻击, 值: 1758 },
      { 属性: 属性类型.破招值, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 39287,
    uid: 39287,
    装备名称: '白萱囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.元气, 值: 696 },
      { 属性: 属性类型.内功基础攻击, 值: 1758 },
      { 属性: 属性类型.全会心等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 39286,
    uid: 39286,
    装备名称: '思菱囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.根骨, 值: 696 },
      { 属性: 属性类型.内功基础攻击, 值: 1758 },
      { 属性: 属性类型.内功会心等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 39247,
    uid: 39247,
    装备名称: '凝青箭囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.元气, 值: 696 },
      { 属性: 属性类型.内功基础攻击, 值: 1758 },
      { 属性: 属性类型.加速等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 39246,
    uid: 39246,
    装备名称: '晓菡箭囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.根骨, 值: 696 },
      { 属性: 属性类型.内功基础攻击, 值: 1758 },
      { 属性: 属性类型.加速等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 39143,
    uid: 39143,
    装备名称: '湘崖囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.元气, 值: 696 },
      { 属性: 属性类型.内功基础攻击, 值: 1758 },
      { 属性: 属性类型.全会心等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 39142,
    uid: 39142,
    装备名称: '湘沅囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 8531 },
      { 属性: 属性类型.根骨, 值: 696 },
      { 属性: 属性类型.内功基础攻击, 值: 1758 },
      { 属性: 属性类型.内功会心等级, 值: 5817 },
      { 属性: 属性类型.无双等级, 值: 4654 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 40830,
    uid: 40830,
    装备名称: '令仪囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.元气, 值: 605 },
      { 属性: 属性类型.内功基础攻击, 值: 1527 },
      { 属性: 属性类型.破招值, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 40829,
    uid: 40829,
    装备名称: '素溱囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.根骨, 值: 605 },
      { 属性: 属性类型.内功基础攻击, 值: 1527 },
      { 属性: 属性类型.破招值, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 40059,
    uid: 40059,
    装备名称: '沉龙荡波·沧远囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.元气, 值: 605 },
      { 属性: 属性类型.内功基础攻击, 值: 1527 },
      { 属性: 属性类型.全会心等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 40058,
    uid: 40058,
    装备名称: '沉龙荡波·漠天囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.根骨, 值: 605 },
      { 属性: 属性类型.内功基础攻击, 值: 1527 },
      { 属性: 属性类型.内功会心等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 39103,
    uid: 39103,
    装备名称: '啸影囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.元气, 值: 605 },
      { 属性: 属性类型.内功基础攻击, 值: 1527 },
      { 属性: 属性类型.全会心等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 39102,
    uid: 39102,
    装备名称: '末闻囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.根骨, 值: 605 },
      { 属性: 属性类型.内功基础攻击, 值: 1527 },
      { 属性: 属性类型.内功会心等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 39063,
    uid: 39063,
    装备名称: '青霍囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.元气, 值: 605 },
      { 属性: 属性类型.内功基础攻击, 值: 1527 },
      { 属性: 属性类型.加速等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 39062,
    uid: 39062,
    装备名称: '持溯囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 7412 },
      { 属性: 属性类型.根骨, 值: 605 },
      { 属性: 属性类型.内功基础攻击, 值: 1527 },
      { 属性: 属性类型.加速等级, 值: 5053 },
      { 属性: 属性类型.无双等级, 值: 4043 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 38961,
    uid: 38961,
    装备名称: '女曜囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6917 },
      { 属性: 属性类型.元气, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 1425 },
      { 属性: 属性类型.破招值, 值: 4716 },
      { 属性: 属性类型.无双等级, 值: 3773 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38960,
    uid: 38960,
    装备名称: '女希囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6917 },
      { 属性: 属性类型.根骨, 值: 564 },
      { 属性: 属性类型.内功基础攻击, 值: 1425 },
      { 属性: 属性类型.破招值, 值: 4716 },
      { 属性: 属性类型.无双等级, 值: 3773 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38841,
    uid: 38841,
    装备名称: '临甘囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.元气, 值: 556 },
      { 属性: 属性类型.内功基础攻击, 值: 1405 },
      { 属性: 属性类型.全会心等级, 值: 4649 },
      { 属性: 属性类型.无双等级, 值: 3719 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 38840,
    uid: 38840,
    装备名称: '临珏囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20700,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6819 },
      { 属性: 属性类型.根骨, 值: 556 },
      { 属性: 属性类型.内功基础攻击, 值: 1405 },
      { 属性: 属性类型.内功会心等级, 值: 4649 },
      { 属性: 属性类型.无双等级, 值: 3719 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 40577,
    uid: 40577,
    装备名称: '故芳囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.元气, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 40576,
    uid: 40576,
    装备名称: '剪桐囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.根骨, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38923,
    uid: 38923,
    装备名称: '故芳囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.元气, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.加速等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 38922,
    uid: 38922,
    装备名称: '剪桐囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.根骨, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.加速等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
  {
    id: 38885,
    uid: 38885,
    装备名称: '花霭囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.元气, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.内功破防等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 38884,
    uid: 38884,
    装备名称: '途南囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.根骨, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.内功破防等级, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功基础攻击 }],
  },
  {
    id: 38847,
    uid: 38847,
    装备名称: '庭澜囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.元气, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38846,
    uid: 38846,
    装备名称: '故云囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.根骨, 值: 551 },
      { 属性: 属性类型.内功基础攻击, 值: 1391 },
      { 属性: 属性类型.破招值, 值: 4604 },
      { 属性: 属性类型.无双等级, 值: 3683 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.无双等级 }],
  },
  {
    id: 38785,
    uid: 38785,
    装备名称: '若寓囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.元气, 值: 512 },
      { 属性: 属性类型.内功基础攻击, 值: 1113 },
      { 属性: 属性类型.全会心等级, 值: 4604 },
      { 属性: 属性类型.全能等级, 值: 1842 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 38784,
    uid: 38784,
    装备名称: '越风囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.根骨, 值: 512 },
      { 属性: 属性类型.内功基础攻击, 值: 1113 },
      { 属性: 属性类型.内功会心等级, 值: 4604 },
      { 属性: 属性类型.全能等级, 值: 1842 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 38747,
    uid: 38747,
    装备名称: '踏江囊',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.元气, 值: 512 },
      { 属性: 属性类型.内功基础攻击, 值: 1113 },
      { 属性: 属性类型.内功破防等级, 值: 4604 },
      { 属性: 属性类型.全能等级, 值: 1842 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }],
  },
  {
    id: 38746,
    uid: 38746,
    装备名称: '承康囊',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 6753 },
      { 属性: 属性类型.根骨, 值: 512 },
      { 属性: 属性类型.内功基础攻击, 值: 1113 },
      { 属性: 属性类型.内功破防等级, 值: 4604 },
      { 属性: 属性类型.全能等级, 值: 1842 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }],
  },
]

export default 暗器装备数据
