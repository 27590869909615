import { 属性类型 } from '@/@types/属性'

import { 装备属性信息模型, 装备特效枚举, 装备类型枚举 } from '@/@types/装备'

const 衣服装备数据: 装备属性信息模型[] = [
  {
    id: 101621,
    uid: 101621,
    装备名称: '暮盈衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 26600,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14603 },
      { 属性: 属性类型.元气, 值: 1191 },
      { 属性: 属性类型.内功基础攻击, 值: 3009 },
      { 属性: 属性类型.内功破防等级, 值: 9957 },
      { 属性: 属性类型.无双等级, 值: 7965 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101620,
    uid: 101620,
    装备名称: '暮漠衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 26600,
    装备特效: 装备特效枚举.切糕_英雄,
    装备类型: 装备类型枚举.切糕,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14603 },
      { 属性: 属性类型.根骨, 值: 1191 },
      { 属性: 属性类型.内功基础攻击, 值: 3009 },
      { 属性: 属性类型.内功破防等级, 值: 9957 },
      { 属性: 属性类型.无双等级, 值: 7965 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101555,
    uid: 101555,
    装备名称: '白萱衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101554,
    uid: 101554,
    装备名称: '思菱衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.破招值, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101519,
    uid: 101519,
    装备名称: '凝青衫',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.加速等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101518,
    uid: 101518,
    装备名称: '晓菡衫',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.加速等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101487,
    uid: 101487,
    装备名称: '揽江·玉玑衣',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101483,
    uid: 101483,
    装备名称: '揽江·柳颦衣',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101482,
    uid: 101482,
    装备名称: '揽江·玉玑衣',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101477,
    uid: 101477,
    装备名称: '揽江·觅韵衣',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.加速等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101473,
    uid: 101473,
    装备名称: '揽江·挽沙衣',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101469,
    uid: 101469,
    装备名称: '揽江·悠清衣',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.外功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101467,
    uid: 101467,
    装备名称: '揽江·凝眸衣',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101465,
    uid: 101465,
    装备名称: '揽江·霜枝衣',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.加速等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101463,
    uid: 101463,
    装备名称: '揽江·温羽衣',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101459,
    uid: 101459,
    装备名称: '揽江·落华衣',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101457,
    uid: 101457,
    装备名称: '揽江·忘语衣',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 25900,
    装备特效: 装备特效枚举.门派套装_四件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101268,
    uid: 101268,
    装备名称: '湘崖衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.元气, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.全会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.全会心效果等级 }],
  },
  {
    id: 101267,
    uid: 101267,
    装备名称: '湘沅衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 25900,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 14219 },
      { 属性: 属性类型.根骨, 值: 1160 },
      { 属性: 属性类型.内功基础攻击, 值: 2930 },
      { 属性: 属性类型.内功会心等级, 值: 9695 },
      { 属性: 属性类型.无双等级, 值: 7756 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 103517,
    uid: 103517,
    装备名称: '沉龙荡波·沧远衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 103516,
    uid: 103516,
    装备名称: '沉龙荡波·漠天衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101226,
    uid: 101226,
    装备名称: '啸影衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 101225,
    uid: 101225,
    装备名称: '末闻衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.破招值, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 101190,
    uid: 101190,
    装备名称: '青霍衫',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101189,
    uid: 101189,
    装备名称: '持溯衫',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101158,
    uid: 101158,
    装备名称: '展锋·晓河衣',
    所属门派: '段氏',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101154,
    uid: 101154,
    装备名称: '展锋·待芳衣',
    所属门派: '药宗',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101153,
    uid: 101153,
    装备名称: '展锋·晓河衣',
    所属门派: '衍天',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101148,
    uid: 101148,
    装备名称: '展锋·松吟衫',
    所属门派: '长歌',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101144,
    uid: 101144,
    装备名称: '展锋·夜云衣',
    所属门派: '明教',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101140,
    uid: 101140,
    装备名称: '展锋·寒泉衣',
    所属门派: '唐门',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.外功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.外功会心等级 }, { 镶嵌类型: 属性类型.外功会心效果等级 }],
  },
  {
    id: 101138,
    uid: 101138,
    装备名称: '展锋·松寒衣',
    所属门派: '五毒',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101136,
    uid: 101136,
    装备名称: '展锋·春断衣',
    所属门派: '七秀',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 101134,
    uid: 101134,
    装备名称: '展锋·圣化衣',
    所属门派: '纯阳',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101130,
    uid: 101130,
    装备名称: '展锋·看妆衣',
    所属门派: '万花',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 101128,
    uid: 101128,
    装备名称: '展锋·玄解衣',
    所属门派: '少林',
    装备主属性: '通用',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.内功会心等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.内功会心效果等级 }],
  },
  {
    id: 100999,
    uid: 100999,
    装备名称: '寻踪觅宝·莫南衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.元气, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100998,
    uid: 100998,
    装备名称: '寻踪觅宝·君溪衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 22500,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 12352 },
      { 属性: 属性类型.根骨, 值: 1008 },
      { 属性: 属性类型.内功基础攻击, 值: 2545 },
      { 属性: 属性类型.加速等级, 值: 8422 },
      { 属性: 属性类型.无双等级, 值: 6738 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100969,
    uid: 100969,
    装备名称: '寻踪觅宝·晚崖衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.元气, 值: 941 },
      { 属性: 属性类型.内功基础攻击, 值: 2375 },
      { 属性: 属性类型.加速等级, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100968,
    uid: 100968,
    装备名称: '寻踪觅宝·沉济衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备特效: 装备特效枚举.门派套装_两件套双会,
    装备类型: 装备类型枚举.门派套装,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.根骨, 值: 941 },
      { 属性: 属性类型.内功基础攻击, 值: 2375 },
      { 属性: 属性类型.加速等级, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100939,
    uid: 100939,
    装备名称: '女曜衫',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.元气, 值: 941 },
      { 属性: 属性类型.内功基础攻击, 值: 2375 },
      { 属性: 属性类型.破招值, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100938,
    uid: 100938,
    装备名称: '女希衫',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 21000,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11529 },
      { 属性: 属性类型.根骨, 值: 941 },
      { 属性: 属性类型.内功基础攻击, 值: 2375 },
      { 属性: 属性类型.破招值, 值: 7861 },
      { 属性: 属性类型.无双等级, 值: 6289 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 104142,
    uid: 104142,
    装备名称: '故芳衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 104141,
    uid: 104141,
    装备名称: '剪桐衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100909,
    uid: 100909,
    装备名称: '故芳衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.全会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100908,
    uid: 100908,
    装备名称: '剪桐衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功会心等级 }, { 镶嵌类型: 属性类型.破招值 }],
  },
  {
    id: 100873,
    uid: 100873,
    装备名称: '花霭衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100872,
    uid: 100872,
    装备名称: '途南衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.破招值, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.破招值 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100837,
    uid: 100837,
    装备名称: '庭澜衣',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.内功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.元气 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100836,
    uid: 100836,
    装备名称: '故云衣',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.普通,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 918 },
      { 属性: 属性类型.内功基础攻击, 值: 2319 },
      { 属性: 属性类型.内功破防等级, 值: 7674 },
      { 属性: 属性类型.无双等级, 值: 6139 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.根骨 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
  {
    id: 100705,
    uid: 100705,
    装备名称: '若寓衫',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 853 },
      { 属性: 属性类型.内功基础攻击, 值: 1855 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100704,
    uid: 100704,
    装备名称: '越风衫',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 853 },
      { 属性: 属性类型.内功基础攻击, 值: 1855 },
      { 属性: 属性类型.加速等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.内功破防等级 }, { 镶嵌类型: 属性类型.全能等级 }],
  },
  {
    id: 100669,
    uid: 100669,
    装备名称: '踏江衫',
    所属门派: '通用',
    装备主属性: '元气',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.元气, 值: 853 },
      { 属性: 属性类型.内功基础攻击, 值: 1855 },
      { 属性: 属性类型.全会心等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.全会心等级 }],
  },
  {
    id: 100668,
    uid: 100668,
    装备名称: '承康衫',
    所属门派: '通用',
    装备主属性: '根骨',
    装备品级: 20500,
    装备类型: 装备类型枚举.PVX,
    装备增益: [
      { 属性: 属性类型.体质, 值: 11255 },
      { 属性: 属性类型.根骨, 值: 853 },
      { 属性: 属性类型.内功基础攻击, 值: 1855 },
      { 属性: 属性类型.内功会心等级, 值: 7674 },
      { 属性: 属性类型.全能等级, 值: 3069 },
    ],
    镶嵌孔数组: [{ 镶嵌类型: 属性类型.体质 }, { 镶嵌类型: 属性类型.内功会心等级 }],
  },
]

export default 衣服装备数据
